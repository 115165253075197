import { GovtIdComponent } from './pages/dashboard/dashboard-verification/govt-id/govt-id.component';
import { PhoneComponent } from './pages/dashboard/dashboard-verification/phone/phone.component';
import { VerificationsComponent } from './pages/dashboard/dashboard-verification/verifications/verifications.component';
import { CalendarPageComponent } from './pages/calendar-page/calendar-page.component';
import { BookingsContainerComponent } from './pages/bookings/bookings-container/bookings-container.component';
import { EditBookingsComponent } from './pages/bookings/edit-bookings/edit-bookings.component';
import { InquiryMessageComponent } from './pages/messages/inquiry-message/inquiry-message.component';
import { WishlistComponent } from './pages/wishlist/wishlist.component';
import { ViewListingComponent } from './components/view-listing/view-listing.component';
import { ListingsComponent } from './pages/listings/listings/listings.component';
import { MyListingsContainerComponent } from './pages/listings/my-listings-container/my-listings-container.component';
import { ListingFormComponent } from './pages/listings/my-listings-container/listing-form/listing-form.component';
import { MyListingsComponent } from './pages/listings/my-listings-container/my-listings/my-listings.component';
import { CrewFormComponent } from './pages/crew-talent/crew-form/crew-form.component';
import { CrewsComponent } from './pages/crew-talent/crews/crews.component';
import { DashboardDeleteComponent } from './pages/dashboard/dashboard-delete/dashboard-delete.component';
import { DashboardRestrictionsComponent } from './pages/dashboard/dashboard-restrictions/dashboard-restrictions.component';
import { DashboardPayoutComponent } from './pages/dashboard/dashboard-payout/dashboard-payout.component';
import { DashboardVerificationComponent } from './pages/dashboard/dashboard-verification/dashboard-verification.component';
import { DashboardPasswordComponent } from './pages/dashboard/dashboard-password/dashboard-password.component';
import { DashboardProfileComponent } from './pages/dashboard/dashboard-profile/dashboard-profile.component';
import { AuthGuardService as AuthGuard } from './services/authGuard/auth-guard.service';
import { ModalRouteEntryComponent } from './pages/modal-route-entry/modal-route-entry.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LandingComponent } from './pages/landing/landing.component';
import { ViewCrewComponent } from './pages/crew-talent/view-crew/view-crew.component';
import { RoleGuardService as RoleGuard } from './services/roleGuard/role-guard.service';
import { InboxComponent } from './pages/messages/inbox/inbox.component';
import { ThreadComponent } from './pages/messages/thread/thread.component';
import { BookingsComponent } from './pages/bookings/bookings.component';
import { ViewBookingComponent } from './pages/bookings/view-booking/view-booking.component';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    children: [
      {
        path: 'login',
        component: ModalRouteEntryComponent,
      },
      {
        path: 'forgot-password',
        component: ModalRouteEntryComponent,
      },
      {
        path: 'resetPassword/:code',
        component: ModalRouteEntryComponent,
      },
      {
        path: 'signup',
        component: ModalRouteEntryComponent,
      },
      {
        path: 'signup-success',
        component: ModalRouteEntryComponent,
      },
      {
        path: 'verifyEmail/:code',
        component: ModalRouteEntryComponent,
      },
    ],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'profile',
        component: DashboardProfileComponent,
      },
      {
        path: 'password',
        component: DashboardPasswordComponent,
      },
      {
        path: 'verification',
        component: DashboardVerificationComponent,
        children: [
          {
            path: '',
            component: VerificationsComponent,
          },
          {
            path: 'phone-number',
            component: PhoneComponent,
          },
          {
            path: 'govt-id',
            component: GovtIdComponent,
          },
        ],
      },
      {
        path: 'payout',
        component: DashboardPayoutComponent,
      },
      {
        path: 'restrictions',
        component: DashboardRestrictionsComponent,
      },
      // {
      //   path: 'delete',
      //   component: DashboardDeleteComponent,
      // },
    ],
  },
  {
    path: 'crew',
    component: CrewsComponent,
  },
  {
    path: 'new-crew',
    component: CrewFormComponent,
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'my-crew',
    component: CrewFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'crew/:crewId',
    component: ViewCrewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'my-listings',
    component: MyListingsContainerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: MyListingsComponent,
        children: [
          {
            path: 'delete/:listingId',
            component: ModalRouteEntryComponent,
          },
        ],
      },
      {
        path: 'edit/:listingId',
        component: ListingFormComponent,
      },
      {
        path: 'preview/:listingId',
        component: ViewListingComponent,
      },
      {
        path: 'new-listing',
        component: ListingFormComponent,
      },
    ],
  },
  {
    path: 'listings',
    component: ListingsComponent,
  },
  {
    path: 'listings/view/:listingId',
    data: { isPreview: false },
    component: ViewListingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'wishlist',
    component: WishlistComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'inquiryMessage',
    component: InquiryMessageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'calendar',
    component: CalendarPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'inbox',
    component: InboxComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'inbox/:threadId',
    component: ThreadComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'booking-invite',
        component: ModalRouteEntryComponent,
      },
      {
        path: 'booking-invite-review',
        component: ModalRouteEntryComponent,
      },
      {
        path: 'booking-change-review',
        component: ModalRouteEntryComponent,
      },
    ],
  },
  {
    path: 'bookings',
    component: BookingsContainerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: BookingsComponent,
      },
      {
        path: 'edit/:bookingId',
        component: EditBookingsComponent,
      },
      {
        path: 'view/:bookingId',
        component: ViewBookingComponent,
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
