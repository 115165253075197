import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-statements',
  templateUrl: './statements.component.html',
  styleUrls: ['./statements.component.scss'],
})
export class StatementsComponent implements OnInit {
  @Input() show = false;
  @Output() showChange = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  close() {
    this.show = false;
    this.showChange.emit(this.show);
  }
}
