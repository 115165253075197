<div>
  <label class="h6">Addons</label>
  <ng-container *ngIf="!readonly">
    <p class="text-muted">Add up to 5 addons</p>
    <form [formGroup]="addonForm" (ngSubmit)="add()">
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="title">Addon</label>
          <input mdbInput type="text" class="form-control" id="title" formControlName="title">
        </div>
        <div class="form-group col-md-3">
          <label for="rate">Rate per rental</label>
          <div class="d-flex">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input type="number" class="form-control" id="rate" formControlName="rate">
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="replacementValue">Replacement Value</label>
          <div class="d-flex">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input type="number" class="form-control" id="replacementValue" formControlName="replacementValue">
          </div>
        </div>
      </div>
      <button class="mx-0 my-3" type="submit" mdbBtn color="deep-purple" size="sm"
        [disabled]="displayedAddons.length>4">
        <span class="text-capitalize">Add</span>
      </button>
    </form>
  </ng-container>
  <table class="m-2" *ngIf="displayedAddons.length > 0" mdbTable [small]="true" [responsive]="true">
    <thead>
      <tr>
        <th scope="col">Title</th>
        <th scope="col">Rate</th>
        <th scope="col">Replacement Value</th>
      </tr>
    </thead>
    <tbody>
      <tr mdbTableCol *ngFor="let addon of displayedAddons">
        <td>{{addon.title}}</td>
        <td>${{addon.rate}}</td>
        <td>${{addon.replacementValue}}</td>
        <td *ngIf="!readonly">
          <button type="button" class="close" aria-label="Close" (click)="remove(addon)">
            <span class="text-danger" aria-hidden="true">×</span>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>