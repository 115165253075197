import { NotificationService } from 'src/app/services/notification/notification.service';
import { AccountService } from 'src/app/services/account/account.service';
import { Review } from './../../utils/types';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { ListingsService } from 'src/app/services/listings/listings.service';
import { Listing } from 'src/app/utils/types';
import { Component, OnInit } from '@angular/core';
import { FavoritesService } from 'src/app/services/favorites/favorites.service';
import { getCancelPolicy } from 'src/app/utils/cancel-policies';

@Component({
  selector: 'app-view-listing',
  templateUrl: './view-listing.component.html',
  styleUrls: ['./view-listing.component.scss'],
})
export class ViewListingComponent implements OnInit {
  listing!: Listing | null;
  listingId!: string;
  activeReviews: Review[] = [];
  isPreview = false;
  isFavorite!: boolean;
  favoriteId?: string;
  userProfile?: {
    firstName: string;
    isEmailConfirmed: boolean;
    isGovtIDConfirmed: boolean;
    isPhoneConfirmed: boolean;
    lastName: string;
    profilePicture: string | null;
    username: string;
  };
  cancelPolicy?: string;
  constructor(
    private _listingService: ListingsService,
    private _route: ActivatedRoute,
    private _accountService: AccountService,
    private _favoriteService: FavoritesService,
    private _notificationService: NotificationService
  ) {
    this._route.params.subscribe((params) => {
      this.listingId = params.listingId;
    });
  }

  ngOnInit(): void {
    const userId = this._accountService.currentUser?.name;
    this._listingService.getListing(this.listingId).subscribe((res) => {
      this.listing = res.result;
      const cancellationPolicy = this.listing?.cancellation;
      if (cancellationPolicy) {
        this.cancelPolicy = getCancelPolicy(cancellationPolicy)?.value;
      }
      // Preview mode if the user owns the listing
      this.isPreview = res.result?.userId === userId ? true : false;
      this.userProfile = res.result?.user;
    });
    if (userId) {
      this._favoriteService.getFavorites(userId).subscribe((favRes) => {
        const favorites = favRes.result;
        const foundFavorite = favorites.find(
          (fav) => fav.listingId === this.listingId
        );
        this.favoriteId = foundFavorite?.id;
        this.isFavorite = foundFavorite ? true : false;
      });
    }
  }

  // Reviews Pagination controls
  onPageChange(evt: PageEvent) {
    const { pageIndex, pageSize } = evt;
    const i = pageIndex * pageSize;
    const j = (pageIndex + 1) * pageSize;
    this.activeReviews = this.listing?.reviews.slice(i, j) || [];
  }

  addToFavorites() {
    const userId = this._accountService.currentUser?.name;
    if (!userId) {
      return;
    }
    this._favoriteService
      .create({
        userId,
        favoritestype: 2, // listing
        listingId: this.listingId,
      })
      .subscribe((res) => {
        const { result } = res;
        if (result.isSuccess) {
          this.favoriteId = result.insertedId;
          this._notificationService.success(result.message);
          this.isFavorite = true;
        } else {
          this._notificationService.error(result.message);
        }
      });
  }

  removeFromFavorites() {
    if (this.favoriteId) {
      this._favoriteService.removeFavorite(this.favoriteId).subscribe((res) => {
        if (res.isSuccess) {
          this._notificationService.success(res.message);
          this.isFavorite = false;
          this.favoriteId = undefined;
        } else {
          this._notificationService.error(res.message);
        }
      });
    }
  }

  copy() {
    const path = window.location.href;
    navigator.clipboard.writeText(path);
    this._notificationService.success(
      'The link to this listing has been copied!'
    );
  }
}
