import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../services/notification/notification.service';

export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((res: HttpErrorResponse) => {
        if (res.error?.message) {
          this.notificationService.error(res.error.message, 3);
        }
        if (res.error?.errors) {
          const errors = res.error.errors;
          if (Array.isArray(errors)) {
            errors.forEach((error: string) => {
              this.notificationService.error(error, 3);
            });
          } else {
            for (const property in errors) {
              if (errors) {
                this.notificationService.error(errors[property][0], 3);
              }
            }
          }
        }
        return throwError(res.error);
      })
    );
  }
}
