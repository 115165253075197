import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { AccountService } from 'src/app/services/account/account.service';
import { FormControl, Validators } from '@angular/forms';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  email: FormControl = new FormControl(
    null,
    Validators.compose([Validators.email, Validators.required])
  );
  newPassword: FormControl = new FormControl(null, Validators.required);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { code: string },
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,
    private _accountService: AccountService,
    private _notificationService: NotificationService
  ) {}

  submit(): void {
    if (this.data?.code) {
      return this.resetPassword();
    }
    if (this.email.valid) {
      const email = this.email.value;
      this._accountService.resetPasswordLink({ email }).subscribe((res) => {
        if (res.isSuccess) {
          this.close();
          this._notificationService.success(res.message, 3);
        }
      });
    }
  }

  resetPassword(): void {
    if (this.email.valid && this.newPassword.valid) {
      const email = this.email.value;
      const newPassword = this.newPassword.value;
      const code = this.data.code;
      this._accountService
        .resetPassword({ email, newPassword, code })
        .subscribe((res) => {
          if (res.isSuccess) {
            this.close();
            this._notificationService.success(res.message, 3);
          }
        });
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
