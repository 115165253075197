<mdb-card>
  <mdb-card-header>Direct Deposit</mdb-card-header>
  <mdb-card-body *ngIf="!loading">
    <ng-container *ngIf="hasPayout && !isEdit">
      <mdb-card-title>
        <p>All payouts will be paid to the following account.</p>
      </mdb-card-title>
      <div class="d-flex align-content-center justify-content-between border border-elegant-color p-3">
        <div class="d-flex flex-column">
          <p class="text-uppercase">
            <mdb-icon fas icon="university" class="mr-1"></mdb-icon>
            <span>{{institutionName}}</span>
          </p>
          <p class="text-muted">Account ending in {{accountNumberSubstr}}</p>
        </div>
        <button mdbBtn type="button" color="light" [outline]="true" mdbWavesEffect class="py-0" (click)="update()">
          <span class="text-capitalize black-text">Update</span>
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="!hasPayout || isEdit">
      <mdb-card-title>
        <p>Add a bank account so you can get paid after completing a booking</p>
      </mdb-card-title>
      <div class="m-4" [formGroup]="bankAccountForm">
        <div class="form-group">
          <label class="h6" for="institutionNumber">Insitution Number</label>
          <input mdbInput type="text" class="form-control" id="institutionNumber" formControlName="institutionNumber"
            maxlength="3" (change)="findBank($event)">
          <mat-error
            *ngIf="(bankAccountForm.controls.institutionNumber.dirty || bankAccountForm.controls.institutionNumber.touched) && bankAccountForm.controls.institutionNumber.errors?.required">
            * Field Required
          </mat-error>
        </div>

        <div class="form-group">
          <label class="h6" for="bankName">Bank Name</label>
          <input mdbInput type="text" class="form-control" id="bankName" formControlName="bankName">
          <mat-error *ngIf="bankNameError">
            {{bankNameError}}
          </mat-error>
        </div>

        <div class="form-group">
          <label class="h6" for="transitNumber">Transit Number</label>
          <input mdbInput type="text" class="form-control" id="transitNumber" formControlName="transitNumber"
            maxlength="5">
          <mat-error
            *ngIf="(bankAccountForm.controls.transitNumber.dirty || bankAccountForm.controls.transitNumber.touched) && bankAccountForm.controls.transitNumber.errors?.required">
            * Field Required
          </mat-error>
        </div>

        <div class="form-group">
          <label class="h6" for="bankAccountNumber">Account Number</label>
          <input mdbInput type="text" class="form-control" id="bankAccountNumber" formControlName="bankAccountNumber"
            maxlength="7">
          <mat-error
            *ngIf="(bankAccountForm.controls.bankAccountNumber.dirty || bankAccountForm.controls.bankAccountNumber.touched) && bankAccountForm.controls.bankAccountNumber.errors?.required">
            * Field Required
          </mat-error>
        </div>

        <div class="form-group">
          <label class="h6" for="verifyAccountNumber">Re-enter Account Number</label>
          <input mdbInput type="text" class="form-control" id="verifyAccountNumber"
            formControlName="verifyAccountNumber" maxlength="7">
          <mat-error
            *ngIf="(bankAccountForm.controls.verifyAccountNumber.dirty || bankAccountForm.controls.verifyAccountNumber.touched) && bankAccountForm.errors?.shouldMatch">
            * Should match with Account Number
          </mat-error>
        </div>

        <div class="form-group">
          <label class="h6" for="accountHolderName">Account holder's name</label>
          <p class="text-muted">Enter this as it appears in your account</p>
          <input mdbInput type="text" class="form-control" id="accountHolderName" formControlName="accountHolderName">
          <mat-error
            *ngIf="(bankAccountForm.controls.accountHolderName.dirty || bankAccountForm.controls.accountHolderName.touched) && bankAccountForm.controls.accountHolderName.errors?.required">
            * Field Required
          </mat-error>
        </div>

        <div class="form-group">
          <label class="h6" for="address">Address</label>
          <p class="text-muted">This information is private and will not be displayed on profile</p>
          <input mdbInput type="text" class="form-control" id="address" formControlName="address">
          <mat-error
            *ngIf="(bankAccountForm.controls.address.dirty || bankAccountForm.controls.address.touched) && bankAccountForm.controls.address.errors?.required">
            * Field Required
          </mat-error>
        </div>

        <div class="form-group">
          <label class="h6" for="province">Province / Territory</label>
          <select formControlName="province" id="province" class="custom-select" (change)="onProvinceChange()">
            <option selected [ngValue]="null">Choose your province or territory</option>
            <option *ngFor="let option of provinceOptions" [value]="option.id">{{option.name}}</option>
          </select>
          <mat-error
            *ngIf="bankAccountForm.controls.province.touched && bankAccountForm.controls.province.errors?.required">
            * Field Required
          </mat-error>
        </div>

        <div *ngIf="showCity" class="form-group">
          <label class="h6" for="city">City</label>
          <input id="city" mdbInput type="text" class="form-control" formControlName="city" [matAutocomplete]="city">
          <p>
            <mat-error
              *ngIf="(bankAccountForm.controls.city.dirty || bankAccountForm.controls.city.touched) && bankAccountForm.controls.city.errors?.match">
              * Choose only cities in the list
            </mat-error>
          </p>
          <p>
            <mat-error
              *ngIf="(bankAccountForm.controls.city.touched || bankAccountForm.controls.city.dirty) && bankAccountForm.controls.city.errors?.required">
              * Field Required
            </mat-error>
          </p>
          <mat-autocomplete #city="matAutocomplete">
            <mat-option *ngFor="let option of filteredCities | async" [value]="option.name">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
        </div>

        <div *ngIf="showCity" class="form-group">
          <label class="h6" for="postalCode">Postal Code</label>
          <p class="text-muted">This information is private and will not be displayed on profile</p>
          <input mdbInput type="text" class="form-control" id="postalCode" formControlName="postalCode" maxlength="6">
          <p>
            <mat-error
              *ngIf="(bankAccountForm.controls.postalCode.touched || bankAccountForm.controls.postalCode.dirty) && bankAccountForm.controls.postalCode.errors?.required">
              * Field Required
            </mat-error>
          </p>
          <p>
            <mat-error
              *ngIf="(bankAccountForm.controls.postalCode.touched || bankAccountForm.controls.postalCode.dirty) && bankAccountForm.controls.postalCode.errors?.pattern">
              * Enter a valid postal code
            </mat-error>
          </p>
        </div>
        <div class="d-flex row justify-content-between">
          <button *ngIf="isEdit" class="mx-3" type="button" mdbBtn color="light" (click)="cancelEdit()">Cancel</button>
          <button class="mx-3 light-blue darken-3" type="button" mdbBtn color="light-blue"
            (click)="submit()">Submit</button>
        </div>
      </div>
    </ng-container>
  </mdb-card-body>
</mdb-card>