<div class="container">
  <div class="my-3" *ngIf="thread">
    <a *ngIf="isOwner && (isThreadInquiry || isThreadDeclined)" [routerLink]="['booking-invite']"
      [queryParams]="{threadId, ownerId, customerId, type: itemType, itemId: itemType === 'crew' ? thread.crewId : thread.listingId}">
      <button class="mx-0" type="button" mdbBtn color="blue" [outline]="true">
        <span class="text-capitalize">Send Booking Invite</span>
      </button>
    </a>
    <ng-container *ngIf="hasNewBooking && isThreadPending">
      <a *ngIf="!isOwner && !isAutoListingBooking" [routerLink]="['booking-invite-review']" [queryParams]="{bookingId}">
        <button class="mx-0" type="button" mdbBtn color="blue" [outline]="true">
          <mdb-icon fas icon="bell" class="mr-1 red-text"></mdb-icon>
          <span class="text-capitalize">Review Booking Invite</span>
        </button>
      </a>
      <a *ngIf="isOwner && isAutoListingBooking" [routerLink]="['booking-invite-review']"
        [queryParams]="{bookingId, ownerReview: true}">
        <button class="mx-0" type="button" mdbBtn color="blue" [outline]="true">
          <mdb-icon fas icon="bell" class="mr-1 red-text"></mdb-icon>
          <span class="text-capitalize">Review Booking Invite</span>
        </button>
      </a>
    </ng-container>
    <ng-container *ngIf="editBooking">
      <p class="h6">Some changes have been requested</p>
      <a [routerLink]="['booking-change-review']" [queryParams]="{'editBookingId': editBooking.id}">
        <button class="mx-0" type="button" mdbBtn color="blue" [outline]="true">
          <mdb-icon fas icon="bell" class="mr-1 red-text"></mdb-icon>
          <span class="text-capitalize">Review Request</span>
        </button>
      </a>
    </ng-container>
  </div>
  <div class="my-4 msg-container" id="msg-screen">
    <app-message-block *ngFor="let msg of messages" [message]="msg"></app-message-block>
  </div>
  <mat-divider class="my-3"></mat-divider>
  <form [formGroup]="msgForm">
    <div class="form-group">
      <textarea type="text" class="form-control md-textarea" rows="4" mdbInput formControlName="messageText"
        (focus)="onFocus($event)"></textarea>
      <p class="text-muted">Owners who respond within 1 hour get more bookings</p>
    </div>
    <button class="my-3 mx-0 light-blue darken-3" type="button" mdbBtn color="light-blue" (click)="send()">Send
      Message</button>
  </form>
  <p class="text-danger">
    <mdb-icon fas icon="exclamation-circle" class="mr-1"></mdb-icon>
    Do not pay for a booking or rental outside of the Camlyst website. Only bookings and rentals completed through
    the website will have access to secure payment, fraud protection, customer service support, changes and
    cancellation.
  </p>
</div>
<router-outlet></router-outlet>