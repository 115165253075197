import { AccountService } from 'src/app/services/account/account.service';
import { Component } from '@angular/core';
import { ThreadService } from 'src/app/services/thread/thread.service';
import { ThreadMessage, Threads } from 'src/app/utils/types';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
})
export class InboxComponent {
  threads!: {
    thread: Threads;
    lastReceivedMsg: ThreadMessage;
  }[];

  constructor(
    private _accountService: AccountService,
    private _threadService: ThreadService
  ) {
    this.getMessages();
  }

  getMessages() {
    this.threads = [];
    this._accountService
      .getProfile(true)
      .toPromise()
      .then(async (res) => {
        const threads = res.user.threads;
        const promises = threads.map((thr) => {
          return this._getThread(thr.id);
        });
        const resolutions = await Promise.all(promises);
        resolutions.forEach((resThread) => {
          const entry = resThread.result[0];
          const lastReceivedMsg = this._findLatestReceived(entry.messages);
          if (lastReceivedMsg) {
            this.threads.push({ thread: entry, lastReceivedMsg });
          }
        });
        this._sort();
      });
  }

  private _getThread(threadId: string) {
    return this._threadService.getThread(threadId).toPromise();
  }

  private _findLatestReceived(
    messages: ThreadMessage[]
  ): ThreadMessage | undefined {
    const userId = this._accountService.currentUser?.name;
    const length = messages.length;
    if (userId && length > 0) {
      for (let i = length - 1; i >= 0; i--) {
        if (messages[i].receiver.id === userId) {
          return messages[i];
        }
      }
    }
    return;
  }

  private _sort() {
    this.threads.sort((a, b) => {
      const aTimestamp = Number(a.lastReceivedMsg.timestamp);
      const bTimestamp = Number(b.lastReceivedMsg.timestamp);
      return bTimestamp - aTimestamp;
    });
  }
}
