import { Booking } from 'src/app/utils/types';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { StripeService, StripePaymentElementComponent } from 'ngx-stripe';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
  selector: 'app-payment-intent',
  templateUrl: './payment-intent.component.html',
  styleUrls: ['./payment-intent.component.scss'],
})
export class PaymentIntentComponent implements OnInit {
  @Input() amount!: number;
  @Input() booking!: Booking;
  @Output() paymentResult = new EventEmitter<{
    success: boolean;
    amount: number;
  }>();
  loading = false;

  @ViewChild(StripePaymentElementComponent)
  paymentElement!: StripePaymentElementComponent;

  paymentElementForm = this.fb.group({
    name: ['', [Validators.required]],
  });

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  constructor(
    private _paymentService: PaymentService,
    private _notificationService: NotificationService,
    private fb: FormBuilder,
    private stripeService: StripeService
  ) {}

  ngOnInit(): void {
    if (this.amount === 0) {
      this._notificationService.error('Total amount to be paid is CA$ 0');
    }
    if (this.booking.id) {
      this._paymentService
        .createPaymentIntent({
          amount: this.amount * 100,
          bookingId: this.booking.id,
          payerId: this.booking.customerId,
          payeeId: this.booking.ownerId,
        }) // Unit accepted is cents
        .subscribe((pi) => {
          this.elementsOptions.clientSecret = pi.client_secret || undefined;
        });
    }
  }

  pay(): void {
    this.paymentElementForm.markAllAsTouched();
    if (this.paymentElementForm.valid) {
      this.loading = true;
      this.stripeService
        .confirmPayment({
          elements: this.paymentElement.elements,
          confirmParams: {
            payment_method_data: {
              billing_details: {
                name: this.paymentElementForm.get('name')?.value,
              },
            },
          },
          redirect: 'if_required',
        })
        .subscribe((result) => {
          this.loading = false;
          if (result.error) {
            this.paymentResult.emit({
              success: false,
              amount: this.amount,
            });
            // Show error to your customer (e.g., insufficient funds)
            this._notificationService.error(
              result.error.message || 'Something went wrong'
            );
          } else {
            // The payment has been processed!
            if (
              result.paymentIntent &&
              result.paymentIntent.status === 'succeeded'
            ) {
              this.paymentResult.emit({
                success: true,
                amount: this.amount,
              });
              // Show a success message to your customer
              this._notificationService.success(
                `Your payment of CA$ ${this.amount} was made successfully.`
              );
            }
          }
        });
    }
  }
}
