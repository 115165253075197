<div class="d-flex justify-content-center" [class.flex-column]="!direction"
  [class.flex-column-reverse]="direction === 'reverse'">
  <p *ngIf="!type || type === 'new'" class="h6">Join directory of thousands of crew and talents</p>
  <div class="input-group mb-3">
    <a *ngIf="!type || type === 'new'" [routerLink]="['/new-crew']">
      <button class="w-100 m-0 light-blue darken-3" type="submit" mdbBtn color="light-blue">Join Crew /
        Talents</button>
    </a>
    <a *ngIf="type === 'edit'" [routerLink]="['/my-crew']">
      <button class="w-100 m-0 light-blue darken-3" type="submit" mdbBtn color="light-blue">Edit Crew /
        Talents</button>
    </a>
  </div>
</div>