import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BaseCrew,
  Crew,
  Crews,
  GenericResponse,
  Tags,
} from 'src/app/utils/types';
import { environment } from './../../../environments/environment';
import { shareReplay } from 'rxjs/operators';

const baseUrl = `${environment.apiUrl}/crew`;
const CACHE_SIZE = 1;

@Injectable({
  providedIn: 'root',
})
export class CrewService {
  private tagsCache$!: Observable<Tags>;
  constructor(public http: HttpClient) {}

  public getCrews({
    tag,
    city,
    PageNumber,
    PageSize,
  }: {
    tag?: string;
    city?: string;
    PageNumber?: number;
    PageSize?: number;
  }): Observable<Crews> {
    let params = new HttpParams();
    if (tag) {
      params = params.append('tag', tag);
    }
    if (city) {
      params = params.append('city', city);
    }
    if (PageNumber) {
      params = params.append('PageNumber', PageNumber);
    }
    if (PageSize) {
      params = params.append('PageSize', PageSize);
    }
    return this.http.get<Crews>(`${baseUrl}/crews`, {
      params,
    });
  }

  public getCrew(crewId: string): Observable<{ result: Crew }> {
    const params = new HttpParams().set('crewId', crewId);
    return this.http.get<{ result: Crew }>(`${baseUrl}/crew`, {
      params,
    });
  }

  public tags(): Observable<Tags> {
    if (!this.tagsCache$) {
      this.tagsCache$ = this.http
        .get<Tags>(`${baseUrl}/tags`)
        .pipe(shareReplay(CACHE_SIZE));
    }
    return this.tagsCache$;
  }

  public createCrew(data: BaseCrew) {
    return this.http.post<GenericResponse>(`${baseUrl}/create`, data);
  }

  public updateCrew(data: object) {
    return this.http.patch<GenericResponse>(`${baseUrl}/update`, data);
  }
}
