<div class="container inner-navbar">
  <ul class="nav justify-content-around py-3 grey lighten-3 z-depth-1">
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['dashboard', 'profile']" routerLinkActive="active">
        <h3 class=" h3-responsive">Account</h3>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['crew']" routerLinkActive="active">
        <h3 class="h3-responsive">Crew / Talent</h3>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['calendar']" routerLinkActive="active">
        <h3 class="h3-responsive">Calender</h3>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['my-listings']" routerLinkActive="active">
        <h3 class="h3-responsive">My Listings</h3>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['bookings']" routerLinkActive="active">
        <h3 class="h3-responsive">Bookings</h3>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['inbox']" routerLinkActive="active">
        <h3 class="h3-responsive">
          Inbox
          <mdb-icon *ngIf="hasNewMessage" fas icon="bell" class="ml-1 red-text notification-bell"></mdb-icon>
        </h3>
      </a>
    </li>
  </ul>
</div>