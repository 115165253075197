import {
  AccessToken,
  AuthResult,
  User,
  Signup,
  Login,
  UserProfile,
  ChangePasswordData,
  GenericResponse,
  UserProfileResponse,
} from './../../utils/types';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { environment } from './../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FacebookService, InitParams, LoginResponse } from 'ngx-facebook';
import { concatMap, map, shareReplay } from 'rxjs/operators';

const jwtHelper = new JwtHelperService();
const baseUrl = `${environment.apiUrl}/identity`;
const DEFAULT_USER_IMG = '../../../assets/default_user.png';
const CACHE_SIZE = 1;

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  profilePicSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    DEFAULT_USER_IMG
  );
  onProfilePicChange = this.profilePicSubject.asObservable();

  private userProfileCache$!: Observable<UserProfileResponse>;

  constructor(public http: HttpClient, private fb: FacebookService) {
    const initParams: InitParams = {
      appId: environment.facebookAppId,
      xfbml: true,
      version: 'v10.0',
    };

    fb.init(initParams);
  }

  public setProfilePic(imageUrl: string) {
    this.profilePicSubject.next(imageUrl);
  }

  public removeProfilePic() {
    this.profilePicSubject.next(DEFAULT_USER_IMG);
  }

  public logout() {
    // return this.http
    //   .post<object>(
    //     `${this.url}/logout?userId=${this.currentUser.userId}`,
    //     null
    //   )
    //   .pipe(
    //     map(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    //     return true;
    //   })
    // );
  }

  public signup(data: Signup) {
    // returns AuthResult but this is wrong
    return this.http.post<AuthResult>(`${baseUrl}/signup`, data);
  }

  public login(data: Login) {
    return this.http.post<AuthResult>(`${baseUrl}/login`, data).pipe(
      map((res: AuthResult) => {
        if (!res) {
          return false;
        }
        this.setSession(res);
        return res;
        // return this.currentUser;
      })
    );
  }

  public loginFacebook() {
    return this.facebookLogin().pipe(
      concatMap((accessToken) => this.facebookTokenAuth(accessToken))
    );
  }

  private facebookLogin() {
    return from(
      this.fb
        .login()
        .then((response: LoginResponse) => {
          const { accessToken } = response.authResponse;
          return { accessToken };
        })
        .catch((error: any) => error)
    );
  }

  private facebookTokenAuth(
    accessToken: AccessToken
  ): Observable<false | User | null> {
    return this.http
      .post<AuthResult>(`${baseUrl}/auth/facebook`, accessToken)
      .pipe(
        map((res: AuthResult) => {
          if (!res) {
            return false;
          }
          this.setSession(res);
          return this.currentUser;
        })
      );
  }

  private setSession(authResult: AuthResult): void {
    localStorage.setItem('token', authResult.token);
    localStorage.setItem('refreshToken', authResult.refreshToken);

    // const expirationDate = jwtHelper.getTokenExpirationDate(authResult.token);
    // console.log(expirationDate, 'expiry date...');
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    if (token) {
      return !jwtHelper.isTokenExpired(token);
    }
    return false;
  }

  get currentUser(): User | null {
    const token = localStorage.getItem('token');
    if (token) {
      return jwtHelper.decodeToken(token);
    }
    return null;
  }

  public getToken(): string | null {
    return localStorage.getItem('token');
  }

  public getProfile(
    refreshCache: boolean = false
  ): Observable<UserProfileResponse> {
    const userId = this.currentUser?.name;
    if (userId && (!this.userProfileCache$ || refreshCache)) {
      const params = new HttpParams().set('userId', userId);
      this.userProfileCache$ = this.http
        .get<UserProfileResponse>(`${baseUrl}/userProfile`, { params })
        .pipe(shareReplay(CACHE_SIZE));
    }
    return this.userProfileCache$;
  }

  public updateProfile(data: UserProfile) {
    // Update profile
    const updateProfile$ = this.http.patch<GenericResponse>(
      `${baseUrl}/userProfile`,
      data
    );
    // Refresh profile cache
    this.getProfile(true);
    // Return update observable
    return updateProfile$;
  }

  public changePassword(data: ChangePasswordData): Observable<GenericResponse> {
    return this.http.post<GenericResponse>(`${baseUrl}/changePassword`, data);
  }

  public verifyPhone(
    userId: string,
    phoneNumber: string
  ): Observable<GenericResponse> {
    const params = new HttpParams()
      .set('userId', userId)
      .set('phoneNumber', phoneNumber);
    return this.http.get<GenericResponse>(`${baseUrl}/sendSMSVerficationCode`, {
      params,
    });
  }

  public verifyPhoneCode(data: {
    code: string;
    phoneNumber: string;
    userId: string;
  }) {
    return this.http.post<any>(`${baseUrl}/verifyPhoneNumber`, data);
  }

  public confirmEmail(req: { code: string; email: string }) {
    const data = { confirmEmailDto: req };
    return this.http.post<GenericResponse>(`${baseUrl}/confirmEmail`, data);
  }

  public resetPasswordLink(data: { email: string }) {
    return this.http.post<GenericResponse>(
      `${baseUrl}/resetPasswordLink`,
      data
    );
  }

  public resetPassword(req: {
    email: string;
    code: string;
    newPassword: string;
  }) {
    const data = { passwordResetDto: req };
    return this.http.post<GenericResponse>(`${baseUrl}/resetPassword`, data);
  }
}
