<mdb-card>
  <img class="img-fluid" [src]="cardImageUrl" alt="Crew image" />
  <mdb-card-body>
    <mdb-card-title class="d-flex justify-content-between">
      <div>
        <p class="h5 text-truncate">{{listing.title}} - ${{listing.dailyRentalPrice}} / Day</p>
        <p class="h6 thin-text">
          <mdb-icon fas icon="map-marker-alt"></mdb-icon> {{listing.city.name}}, {{listing.province.name}}
        </p>
      </div>
    </mdb-card-title>
    <mdb-card-text class="text-truncate-container">
      <p>
        {{listing.description}}
      </p>
    </mdb-card-text>

    <div class="d-flex align-items-center justify-content-between">
      <a class="mt-2">
        <a mdbBtn [routerLink]="['/listings', 'view', listing.id]" role="button" color="light-blue"
          class="waves-light light-blue darken-3 my-2 mx-4" mdbWavesEffect>
          View Listing
        </a>
      </a>
      <ngb-rating *ngIf="listing.rating" [rate]="listing.rating" [readonly]="true" [max]=5>
        <ng-template let-fill="fill" let-index="index">
          <span class="star" [class.filled]="fill === 100">&#9733;</span>
        </ng-template>
      </ngb-rating>
    </div>
  </mdb-card-body>
</mdb-card>