import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Transaction } from 'src/app/utils/types';

const baseTransactionUrl = `${environment.nodeApiUrl}/account`;

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  constructor(public http: HttpClient) {}

  public getTransactionsByBookingId(id: string): Observable<Transaction[]> {
    let params = new HttpParams();
    params = params.append('bookingId', id);
    return this.http.get<Transaction[]>(baseTransactionUrl, {
      params,
    });
  }
}
