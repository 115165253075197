import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Place } from 'src/app/utils/types';
import { HttpClient, HttpParams } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';

const baseUrl = `${environment.apiUrl}/Utility`;
const CACHE_SIZE = 1;
@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private provincesCache$!: Observable<{ result: Place[] }>;
  constructor(private http: HttpClient) {}

  public provinces(): Observable<{ result: Place[] }> {
    if (!this.provincesCache$) {
      this.provincesCache$ = this.http
        .get<{ result: Place[] }>(`${baseUrl}/provinces`)
        .pipe(shareReplay(CACHE_SIZE));
    }
    return this.provincesCache$;
  }

  public cities(province?: string): Observable<{ result: Place[] }> {
    let params = new HttpParams();
    if (province) {
      params = params.append('Province', province);
    }
    return this.http.get<{ result: Place[] }>(`${baseUrl}/cities`, {
      params,
    });
  }
}
