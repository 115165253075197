import { shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  Category,
  GenericResponse,
  BaseListing,
  Listings,
  Listing,
} from './../../utils/types';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

const baseUrl = `${environment.apiUrl}/listing`;
const CACHE_SIZE = 1;

@Injectable({
  providedIn: 'root',
})
export class ListingsService {
  private categoriesCache$!: Observable<{
    result: {
      data: Category[];
    };
  }>;
  constructor(public http: HttpClient) {}

  getCategories(): Observable<{
    result: {
      data: Category[];
    };
  }> {
    if (!this.categoriesCache$) {
      this.categoriesCache$ = this.http
        .get<{
          result: {
            data: Category[];
          };
        }>(`${baseUrl}/category`)
        .pipe(shareReplay(CACHE_SIZE));
    }
    return this.categoriesCache$;
  }

  public getListings(listingParams: {
    category?: string;
    brand?: string;
    model?: string;
    city?: string;
    UserId?: string;
    PageNumber?: number;
    PageSize?: number;
  }): Observable<Listings> {
    const {
      category,
      brand,
      model,
      city,
      UserId,
      PageNumber,
      PageSize,
    } = listingParams;
    let params = new HttpParams();
    if (category) {
      params = params.append('Category', category);
    }
    if (brand) {
      params = params.append('Brand', brand);
    }
    if (model) {
      params = params.append('Model', model);
    }
    if (UserId) {
      params = params.append('UserId', UserId);
    }
    if (city) {
      params = params.append('city', city);
    }
    if (PageNumber) {
      params = params.append('PageNumber', PageNumber);
    }
    if (PageSize) {
      params = params.append('PageSize', PageSize);
    }
    return this.http.get<Listings>(`${baseUrl}/listings`, {
      params,
    });
  }

  public getListing(id: string): Observable<{ result: Listing | null }> {
    const params = new HttpParams().append('listingId', id);
    return this.http.get<{ result: Listing | null }>(`${baseUrl}/listing`, {
      params,
    });
  }

  public deleteListing(data: {
    listingId: string;
    reason?: string;
  }): Observable<GenericResponse> {
    return this.http.delete<GenericResponse>(`${baseUrl}/removeListing`, {
      body: data,
    });
  }

  public createListing(data: BaseListing) {
    return this.http.post<GenericResponse>(`${baseUrl}/createListing`, data);
  }

  public updateListing(data: object) {
    return this.http.patch<GenericResponse>(`${baseUrl}/updateListing`, data);
  }
}
