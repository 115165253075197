import { AbstractControl, ValidatorFn } from '@angular/forms';

export class AutocompleteValidators {
  static valueSelected(opts: any[] | (() => any[])): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const selectboxValue = c.value;
      let pickedOrNot = [];
      if (Array.isArray(opts)) {
        pickedOrNot = opts.filter((alias) => alias.name === selectboxValue);
      } else if (opts instanceof Function) {
        const arr = opts();
        if (arr) {
          pickedOrNot = arr.filter((alias) => alias.name === selectboxValue);
        }
      }
      if (pickedOrNot.length > 0) {
        // everything's fine. return no error. therefore it's null.
        return null;
      } else {
        // there's no matching selectboxvalue selected. so return match error.
        return { match: true };
      }
    };
  }
}
