import { Listing } from './../../utils/types';
import { Component, Input, OnInit } from '@angular/core';

const DUMMY_IMAGE = '../../../assets/no-image-available.png';
@Component({
  selector: 'app-my-listing-card',
  templateUrl: './my-listing-card.component.html',
})
export class MyListingCardComponent implements OnInit {
  @Input() listing!: Listing;
  displayImage!: string;
  constructor() {}

  ngOnInit(): void {
    this.displayImage =
      this.listing.imageUrl1 ||
      this.listing.imageUrl2 ||
      this.listing.imageUrl3 ||
      this.listing.imageUrl4 ||
      this.listing.imageUrl5 ||
      DUMMY_IMAGE;
  }
}
