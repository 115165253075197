<mdb-card>
  <mdb-card-header>Change Password</mdb-card-header>
  <mdb-card-body>
    <form [formGroup]="changePasswordForm">
      <div class="errors row m-3" *ngFor=" let error of errors">
        <mat-error>* {{error}}</mat-error>
      </div>
      <div class="form-group col-md-6">
        <label for="oldPassword">Current Password</label>
        <input mdbInput type="password" class="form-control" id="oldPassword" formControlName="oldPassword">
      </div>
      <div class="form-group col-md-6">
        <label for="password">New Password</label>
        <input mdbInput type="password" class="form-control" id="password" formControlName="password">
      </div>
      <div class="form-group col-md-6">
        <label for="confirmPassword">Confirm New Password</label>
        <input mdbInput type="password" class="form-control" id="confirmPassword" formControlName="confirmPassword">
      </div>
      <div class="form-group col-md-6">
        <button type="button" mdbBtn color="primary" (click)="submit()">Change Password</button>
      </div>
    </form>
  </mdb-card-body>
</mdb-card>