<div class="m-2 d-flex flex-column">
  <mdb-icon [style.visibility]="imagePath ? 'visible' : 'hidden'" fas icon="trash-alt" size="2x"
    class="deep-orange-text mb-1" (click)="remove()"></mdb-icon>

  <div class="file-upload-container" (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)"
    (dragover)="onDragOver($event)" (drop)="onDropSuccess($event)" (mouseenter)="onMouseEnter($event)"
    (mouseleave)="onMouseLeave($event)" (click)="file.click()">

    <div *ngIf="imagePath" class="image-container">
      <div class="view m-0 inner-container">
        <img [src]="imagePath" class="img-fluid" alt="">
        <div class="mask flex-center flex-column rgba-grey-strong" [style.display]="isDraggedOver ? 'flex' : 'none'">
          <p class="text-white">Drag and drop or click to replace</p>
          <p class="text-white">(Max 4mb per image)</p>
        </div>
      </div>
    </div>

    <div *ngIf="!imagePath" class="image-placeholder">
      <div *ngIf="!imagePath" class="view m-0 inner-container">
        <div class="mask flex-center flex-column" [class.rgba-white-slight]="!isDraggedOver"
          [class.rgba-grey-strong]="isDraggedOver">
          <mdb-icon fas icon="images" size="4x"></mdb-icon>
          <p>Drag images here or <u>choose file</u></p>
          <p class="text-muted">(Max 4mb per image)</p>
        </div>
      </div>
    </div>
    <input type="file" name="profilePicture" (change)="onImageSelectionUpload($event)" #file />
  </div>
</div>