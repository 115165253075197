import { ThreadService } from 'src/app/services/thread/thread.service';
import { AccountService } from 'src/app/services/account/account.service';
import { Component, NgZone, OnInit } from '@angular/core';

@Component({
  selector: 'app-inner-navbar',
  templateUrl: './inner-navbar.component.html',
  styleUrls: ['./inner-navbar.component.scss'],
})
export class InnerNavbarComponent implements OnInit {
  hasNewMessage = false;

  constructor(
    private _accountService: AccountService,
    private _threadService: ThreadService
  ) {
    this.subscribeToEvents();
  }

  ngOnInit(): void {}

  get userId(): string | null {
    if (this._accountService.isAuthenticated()) {
      const currentUser = this._accountService.currentUser;
      return currentUser?.name || null;
    }
    return null;
  }

  private _foundNewMessage(): boolean {
    const storage = Object.entries(localStorage);
    const regex = new RegExp('thread');
    return !!storage.find(([key]) => regex.test(key));
  }

  private subscribeToEvents(): void {
    this.hasNewMessage = this._foundNewMessage();
    this._threadService.watchMessageStorage().subscribe((data: string) => {
      if (data.startsWith('added')) {
        this.hasNewMessage = true;
      }
      if (data.startsWith('removed')) {
        this.hasNewMessage = this._foundNewMessage();
      }
    });
  }
}
