import {
  AccountEntryStatus,
  AccountEntryType,
  BookingStatus,
  EditBookingState,
  ThreadStatus,
} from './../../utils/enums';
import { BookingAccountEntry, GenericResponse } from './../../utils/types';
import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { BookingService } from 'src/app/services/booking/booking.service';
import { Booking, EditBooking } from 'src/app/utils/types';
import { BookingAccountService } from 'src/app/services/bookingAccount/booking-account.service';
import { AccountService } from 'src/app/services/account/account.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { Location } from '@angular/common';
import { ThreadService } from 'src/app/services/thread/thread.service';
import { calcTaxes, calcTotal } from 'src/app/utils/calculate';

@Component({
  selector: 'app-booking-change-review',
  templateUrl: './booking-change-review.component.html',
})
export class BookingChangeReviewComponent {
  editBookingId!: string;
  booking!: Booking;
  editBooking!: EditBooking;
  displayedPaymentRequested?: string;
  displayedRefundRequested?: string;
  taxes?: string;
  displayedTotal?: string;
  total!: number;
  paymentNeeded = false;
  userId?: string;
  constructor(
    private _route: ActivatedRoute,
    private _threadService: ThreadService,
    private _bookingService: BookingService,
    private _bookingAccountService: BookingAccountService,
    private _accountService: AccountService,
    private _notificationService: NotificationService,
    private _location: Location
  ) {
    this.userId = this._accountService.currentUser?.name;
    this._route.queryParams.subscribe((params) => {
      this.editBookingId = params.editBookingId;
      if (this.editBookingId) {
        this._getEditBookingInfo({ id: this.editBookingId })
          .then((res) => {
            this.editBooking = res.result[0];
            this.displayedPaymentRequested = this.editBooking.requestPayment?.toFixed(
              2
            );
            this.taxes = this.editBooking.requestPayment
              ? calcTaxes(this.editBooking.requestPayment).toFixed(2)
              : '0.00';

            if (this.editBooking.requestPayment) {
              this.total = calcTotal([
                this.editBooking.requestPayment,
                calcTaxes(this.editBooking.requestPayment),
              ]);
              this.displayedTotal = this.total.toFixed(2);
            }

            this.displayedRefundRequested = this.editBooking.requestRefund?.toFixed(
              2
            );
            return res.result[0].bookingId;
          })
          .then((bookingId) => {
            return this._getBooking(bookingId);
          })
          .then((bookingRes) => {
            this.booking = bookingRes[0];
            return bookingRes;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  }

  private _getEditBookingInfo({
    id,
    bookingId,
  }: {
    id?: string;
    bookingId?: string;
  }) {
    return this._bookingService.getEditBookings({ id, bookingId }).toPromise();
  }

  private _getBooking(id: string) {
    return this._bookingService.getById(id).toPromise();
  }

  decline() {
    if (this.editBooking.id) {
      this._updateEditBooking(this.editBooking.id, EditBookingState.Rejected)
        .then((editBookingRes) => {
          if (editBookingRes && editBookingRes.isSuccess) {
            const messageText =
              'AUTOMATED MESSAGE - Your booking changes have been declined';
            return this._sendAutoMsg(this.booking, messageText);
          }
          return;
        })
        .then((messageRes) => {
          if (messageRes && messageRes.isSuccess) {
            this._notificationService.success(
              'The booking edit request has been rejected'
            );
            this._location.back();
          } else {
            this._notificationService.error(
              'Something went wrong rejecting the booking'
            );
          }
        });
    }
  }

  accept() {
    if (this.editBooking.cancel) {
      // Handle Cancel
      this._updateBooking({
        id: this.booking.id,
        status: BookingStatus.Canceled,
        lastModified: Date.now().toString(),
      })
        .then((bookingUpdateRes) => {
          if (bookingUpdateRes && bookingUpdateRes.isSuccess) {
            return this._updateThreadStatus(
              this.booking.threadId,
              ThreadStatus.Canceled
            );
          }
          return;
        })
        .then((threadUpdateRes) => {
          if (
            threadUpdateRes &&
            threadUpdateRes.result.isSuccess &&
            this.editBooking.id
          ) {
            return this._updateEditBooking(
              this.editBooking.id,
              EditBookingState.Completed
            );
          }
          return;
        })
        .then((editBookingRes) => {
          if (editBookingRes && editBookingRes.isSuccess) {
            const messageText =
              'AUTOMATED MESSAGE - Your booking cancellation has been confirmed';
            return this._sendAutoMsg(this.booking, messageText);
          }
          return;
        })
        .then((messageRes) => {
          if (messageRes && messageRes.isSuccess) {
            this._notificationService.success(
              'Your booking has been updated successfully'
            );
            this._location.back();
          } else {
            this._notificationService.error(
              'Something went wrong updating the booking'
            );
          }
        });
    } else if (
      this.editBooking.requestPayment &&
      this.editBooking.requestPayment > 0
    ) {
      // Payment needed
      this.paymentNeeded = true;
    } else if (
      this.editBooking.requestRefund &&
      this.editBooking.requestRefund > 0
    ) {
      // Refund needed
      this._paymentRefund(this.editBooking.requestRefund);
    } else {
      // Simple Edit
      this._updateBooking({
        id: this.booking.id,
        ...(this.editBooking.startDate && {
          startDate: this.editBooking.startDate,
        }),
        ...(this.editBooking.endDate && {
          endDate: this.editBooking.endDate,
        }),
        lastModified: Date.now().toString(),
      })
        .then((bookingUpdateRes) => {
          if (
            bookingUpdateRes &&
            bookingUpdateRes.isSuccess &&
            this.editBooking.id
          ) {
            return this._updateEditBooking(
              this.editBooking.id,
              EditBookingState.Completed
            );
          }
          return;
        })
        .then((editBookingRes) => {
          if (editBookingRes && editBookingRes.isSuccess) {
            const messageText =
              'AUTOMATED MESSAGE - Your booking changes have been confirmed';
            return this._sendAutoMsg(this.booking, messageText);
          }
          return;
        })
        .then((messageRes) => {
          if (messageRes && messageRes.isSuccess) {
            this._notificationService.success(
              'Your booking has been updated successfully'
            );
            this._location.back();
          } else {
            this._notificationService.error(
              'Something went wrong updating the booking'
            );
          }
        });
    }
  }

  _paymentRefund(amount: number) {
    if (this.booking.id) {
      this._getBookingAccount(this.booking.id)
        .then((accounts) => {
          if (accounts.length > 0) {
            return accounts[0].id;
          }
          return;
        })
        .then((accountId) => {
          if (accountId && this.userId) {
            return this._createAccountEntry({
              accountId,
              amount,
              note: 'Booking was edited with a refund requested',
              type: AccountEntryType.Refund,
              initiatorId: this.userId,
              status: AccountEntryStatus.Pending,
              created: Date.now().toString(),
              lastModified: Date.now().toString(),
            });
          }
          return;
        })
        .then((accountEntry) => {
          if (accountEntry && accountEntry.isSuccess) {
            return this._updateBooking({
              id: this.booking.id,
              ...(this.editBooking.startDate && {
                startDate: this.editBooking.startDate,
              }),
              ...(this.editBooking.endDate && {
                endDate: this.editBooking.endDate,
              }),
              lastModified: Date.now().toString(),
            });
          }
          return;
        })
        .then((bookingUpdateRes) => {
          if (
            bookingUpdateRes &&
            bookingUpdateRes.isSuccess &&
            this.editBooking.id
          ) {
            return this._updateEditBooking(
              this.editBooking.id,
              EditBookingState.Accepted
            );
          }
          return;
        })
        .then((editBookingRes) => {
          if (editBookingRes && editBookingRes.isSuccess) {
            const messageText =
              'AUTOMATED MESSAGE - Your booking changes have been confirmed';
            return this._sendAutoMsg(this.booking, messageText);
          }
          return;
        })
        .then((messageRes) => {
          if (messageRes && messageRes.isSuccess) {
            this._notificationService.success(
              'Your booking has been updated successfully'
            );
            this._location.back();
          } else {
            this._notificationService.error(
              'Something went wrong updating the booking'
            );
          }
        });
    }
  }

  onPaymentResult(result: { success: boolean; amount: number }): void {
    if (result.success && this.booking.id) {
      this._getBookingAccount(this.booking.id)
        .then((accounts) => {
          if (accounts.length > 0) {
            return accounts[0].id;
          }
          return;
        })
        .then((accountId) => {
          if (accountId && this.userId) {
            return this._createAccountEntry({
              accountId,
              amount: result.amount,
              note: 'Booking was edited with an amount requested',
              type: AccountEntryType.Payment,
              initiatorId: this.userId,
              status: AccountEntryStatus.Complete,
              created: Date.now().toString(),
              lastModified: Date.now().toString(),
            });
          }
          return;
        })
        .then((accountEntry) => {
          if (accountEntry && accountEntry.isSuccess) {
            return this._updateBooking({
              id: this.booking.id,
              ...(this.editBooking.startDate && {
                startDate: this.editBooking.startDate,
              }),
              ...(this.editBooking.endDate && {
                endDate: this.editBooking.endDate,
              }),
              status: BookingStatus.Paid,
              lastModified: Date.now().toString(),
            });
          }
          return;
        })
        .then((bookingUpdateRes) => {
          if (
            bookingUpdateRes &&
            bookingUpdateRes.isSuccess &&
            this.editBooking.id
          ) {
            return this._updateEditBooking(
              this.editBooking.id,
              EditBookingState.Completed
            );
          }
          return;
        })
        .then((editBookingRes) => {
          if (editBookingRes && editBookingRes.isSuccess) {
            const messageText =
              'AUTOMATED MESSAGE - Your booking changes have been confirmed';
            return this._sendAutoMsg(this.booking, messageText);
          }
          return;
        })
        .then((messageRes) => {
          if (messageRes && messageRes.isSuccess) {
            this._notificationService.success(
              'Your booking has been updated successfully'
            );
            this._location.back();
          } else {
            this._notificationService.error(
              'Something went wrong updating the booking'
            );
          }
        });
    }
  }

  private _getBookingAccount(bookingId: string) {
    return this._bookingAccountService.getAccount(bookingId).toPromise();
  }

  private _createAccountEntry(data: BookingAccountEntry) {
    return this._bookingAccountService.createAccountEntry(data).toPromise();
  }

  private _updateBooking(data: object) {
    return this._bookingService.update(data).toPromise();
  }

  private _updateEditBooking(id: string, state: EditBookingState) {
    return this._bookingService
      .updateEditBooking(id, { state, lastModified: Date.now().toString() })
      .toPromise();
  }

  private _updateThreadStatus(id: string, status: ThreadStatus) {
    return this._threadService.update(id, { status }).toPromise();
  }

  private _sendAutoMsg(
    booking: Booking,
    messageText: string
  ): Promise<GenericResponse | null> {
    const senderId = this._accountService.currentUser?.name;
    if (!senderId) {
      return new Promise((resolve) => resolve(null));
    }
    const receiverId =
      booking.ownerId === senderId ? booking.customerId : booking.ownerId;
    const timestamp = Date.now().toString();
    const data = {
      senderId,
      receiverId,
      timestamp,
      messageText,
    };
    if (booking.threadId) {
      return this._threadService.createMessage(booking.threadId, data);
    }
    return new Promise((resolve) => resolve(null));
  }
}
