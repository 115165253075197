import { RentalCategory } from './../../utils/types';
import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class RentalCategoriesService {
  constructor() {}

  getAll(): RentalCategory[] {
    return [
      {
        id: uuidv4(),
        name: 'Cameras',
        image: '../../assets/rentalCategories/camera.png',
      },
      {
        id: uuidv4(),
        name: 'Camera Accessories',
        image: '../../assets/rentalCategories/camera_accessories.png',
      },
      {
        id: uuidv4(),
        name: 'Lenses',
        image: '../../assets/rentalCategories/lenses.png',
      },
      {
        id: uuidv4(),
        name: 'Lighting',
        image: '../../assets/rentalCategories/lighting.png',
      },
      {
        id: uuidv4(),
        name: 'Audio',
        image: '../../assets/rentalCategories/audio.png',
      },
      {
        id: uuidv4(),
        name: 'Camera Support',
        image: '../../assets/rentalCategories/camera_support.png',
      },
      {
        id: uuidv4(),
        name: 'Grip / Electric',
        image: '../../assets/rentalCategories/grip.png',
      },
      {
        id: uuidv4(),
        name: 'Art / Props',
        image: '../../assets/rentalCategories/props.png',
      },
      {
        id: uuidv4(),
        name: 'Others',
        image: '../../assets/rentalCategories/others.png',
      },
    ];
  }
}
