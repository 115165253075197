import { Router } from '@angular/router';
import { BookingService } from 'src/app/services/booking/booking.service';
import { Component, OnInit } from '@angular/core';
import { isSameDay, isSameMonth } from 'date-fns';
import { Subject } from 'rxjs';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { AccountService } from 'src/app/services/account/account.service';
import { Booking } from 'src/app/utils/types';
import { ListingsService } from 'src/app/services/listings/listings.service';
import { CrewService } from 'src/app/services/crew/crew.service';
import { BookingStatus } from 'src/app/utils/enums';

// Calendar color styles
const colors: any = {
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  green: {
    primary: '#519839',
    secondary: '#0098B7',
  },
};

@Component({
  selector: 'app-calendar-page',
  templateUrl: './calendar-page.component.html',
  styleUrls: ['./calendar-page.component.scss'],
})
export class CalendarPageComponent implements OnInit {
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  refresh = new Subject<void>();
  events!: CalendarEvent[];
  activeDayIsOpen = true;

  constructor(
    private _accountService: AccountService,
    private _bookingService: BookingService,
    private _crewService: CrewService,
    private _listingService: ListingsService,
    private _router: Router
  ) {}

  ngOnInit() {
    this.getEvents().then((res) => {
      this.events = res;
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  handleEvent(event: CalendarEvent): void {
    this._router.navigate(['bookings', 'view', event.id]);
  }

  async getEvents() {
    const userId = this._accountService.currentUser?.name;
    if (userId) {
      const outBoundBookings = await (
        await this._getOutboundBookings(userId)
      ).filter(
        (booking) =>
          booking.status !== BookingStatus.Canceled &&
          booking.status !== BookingStatus.Declined
      );
      const inBoundBookings = await (
        await this._getInboundBookings(userId)
      ).filter(
        (booking) =>
          booking.status !== BookingStatus.Canceled &&
          booking.status !== BookingStatus.Declined
      );
      const outBoundEvents = await this._mapToEvents(
        outBoundBookings,
        'outbound'
      );
      const inBoundEvents = await this._mapToEvents(inBoundBookings, 'inbound');
      return outBoundEvents.concat(inBoundEvents);
    }
    return [];
  }

  private _getOutboundBookings(userId: string): Promise<Booking[]> {
    return this._bookingService.getByCustomerId(userId).toPromise();
  }

  private _getInboundBookings(userId: string): Promise<Booking[]> {
    return this._bookingService.getByOwnerId(userId).toPromise();
  }

  private _getBookedItem(booking: Booking): Promise<{ result: any }> {
    if (booking.type === 'crew' && booking.crewId) {
      return this._crewService.getCrew(booking.crewId.toString()).toPromise();
    }
    if (booking.type === 'listing' && booking.listingId) {
      return this._listingService.getListing(booking.listingId).toPromise();
    }
    return new Promise((resolve) => resolve({ result: null }));
  }

  private async _mapToEvents(
    bookings: Booking[],
    bookingType: 'outbound' | 'inbound'
  ) {
    return await Promise.all(
      bookings.map(async (booking) => {
        const { result } = await this._getBookedItem(booking);
        return {
          start: new Date(booking.startDate),
          end: new Date(booking.endDate),
          title:
            booking.type === 'listing'
              ? `LISTING - ${result?.title}`
              : `CREW - ${result?.user.firstName} ${result?.user.lastName}`,
          id: booking.id,
          color: bookingType === 'outbound' ? colors.blue : colors.green,
          allDay: true,
        };
      })
    );
  }
}
