import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AccountService } from '../../services/account/account.service';
import { PasswordValidators } from 'src/app/utils/password.validators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Place, Signup } from 'src/app/utils/types';
import { LocationService } from 'src/app/services/location/location.service';
import { AutocompleteValidators } from 'src/app/utils/autocomplete.validators';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  agree = false;
  signupForm: FormGroup;
  errors: string[] = [];
  cities!: Place[];
  filteredCities!: Observable<Place[]>;
  showPrivacyPolicy = false;

  constructor(
    public dialogRef: MatDialogRef<SignupComponent>,
    public dialog: MatDialog,
    private accountService: AccountService,
    private router: Router,
    private _locationService: LocationService
  ) {
    this._locationService
      .cities()
      .subscribe((res) => (this.cities = res.result));
    this.signupForm = new FormGroup(
      {
        firstName: new FormControl(null, Validators.required),
        lastName: new FormControl(null, Validators.required),
        email: new FormControl(
          null,
          Validators.compose([Validators.email, Validators.required])
        ),
        city: new FormControl(
          null,
          Validators.compose([
            Validators.required,
            AutocompleteValidators.valueSelected(
              this.cityValidatorFn.bind(this)
            ),
          ])
        ),
        password: new FormControl(null, Validators.required),
        confirmPassword: new FormControl(null, Validators.required),
        username: new FormControl(null, Validators.required),
        phone: new FormControl(null, Validators.required),
      },
      {
        validators: [PasswordValidators.shouldMatch, PasswordValidators.strong],
      }
    );
  }

  ngOnInit() {
    this.filteredCities = this.signupForm.controls.city.valueChanges.pipe(
      startWith(''),
      map((value: string) =>
        value.length >= 1 ? this._filterCities(value) : []
      )
    );
  }

  private _filterCities(value: string): Place[] {
    const filterValue = value.toLowerCase();
    return this.cities.filter((option: Place) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  cityValidatorFn(): Place[] {
    return this.cities;
  }

  submit() {
    this.signupForm.markAllAsTouched();
    if (this.signupForm.valid) {
      const data: Signup = {
        registerDto: this.signupForm.value,
      };
      this.accountService.signup(data).subscribe((res) => {
        // On successful sign up
        this.onSuccessSignUp();
      });
    } else {
      this.errors = this.getErrorMessage();
    }
  }

  private getErrorMessage() {
    const errors = [];
    if (this.signupForm.hasError('shouldMatch')) {
      errors.push('Passwords do not match');
    }
    if (this.signupForm.hasError('strong')) {
      errors.push('Password is not strong enough');
    }
    return errors;
  }

  navigateToLogin(): void {
    this.close();
    this.router.navigate(['login']);
  }

  onSuccessSignUp(): void {
    this.close();
    this.router.navigate(['signup-success']);
  }

  loginWithFB(): void {
    this.accountService.loginFacebook().subscribe((res) => {
      console.log(res, 'response');
      this.close();
    });
  }

  close() {
    this.dialogRef.close();
  }

  togglePrivacyPolicy(evt: Event) {
    this.showPrivacyPolicy = !this.showPrivacyPolicy;
    evt.preventDefault();
  }

  onShowUserPolicy(evt: boolean) {
    this.showPrivacyPolicy = evt;
  }
}
