import { BlobStorageService } from './../../../services/blogStorage/blob-storage.service';
import { NotificationService } from './../../../services/notification/notification.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AccountService } from './../../../services/account/account.service';
import { Component } from '@angular/core';
import { UserProfile, UserProfileResponse } from 'src/app/utils/types';

const ALLOWED_FILETYPES = ['image/png', 'image/jpeg'];

@Component({
  selector: 'app-dashboard-profile',
  templateUrl: './dashboard-profile.component.html',
  styleUrls: ['./dashboard-profile.component.scss'],
})
export class DashboardProfileComponent {
  userId = '';
  userProfile!: UserProfile;
  profileForm: FormGroup;
  profileImageUrl?: string | null;

  constructor(
    private accountService: AccountService,
    private notificationService: NotificationService,
    private blobStorage: BlobStorageService
  ) {
    this.userId = this.accountService.currentUser?.name || '';
    this.profileForm = new FormGroup({
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      email: new FormControl({
        value: null,
        disabled: true,
      }),
      phone: new FormControl(null),
      company: new FormControl(null),
      website: new FormControl(null),
      aboutMe: new FormControl(null),
    });
    this.accountService.getProfile().subscribe((res: UserProfileResponse) => {
      if (res.isSuccess) {
        this.userProfile = res.user;
        this.profileImageUrl = res.user.profilePicture;
        this.profileForm.patchValue(res.user);
      }
    });
  }

  onImageUpload(event: any) {
    const files = event.target.files as FileList;
    const blobName = `${this.userId}-profile-picture`;
    if (files && ALLOWED_FILETYPES.includes(files[0].type)) {
      this.blobStorage.uploadFile(files[0], blobName).then((res) => {
        if (res.isSuccess) {
          this.profileImageUrl = res.imageUrl;
          this.updateProfileImageUrl();
          if (res.imageUrl) {
            this.accountService.setProfilePic(res.imageUrl);
          }
          this.notificationService.success(res.message);
        } else {
          // Upload Error
          this.notificationService.error(res.message);
        }
      });
    } else {
      // Error for wrong file type
      this.notificationService.error('File type not allowed');
    }
  }

  deleteImage() {
    const blobName = `${this.userId}-profile-picture`;
    this.blobStorage.deleteFile(blobName).then((res) => {
      if (res.isSuccess) {
        this.profileImageUrl = null;
        this.notificationService.success(res.message);
        this.accountService.removeProfilePic();
        this.updateProfileImageUrl();
      } else {
        // Delete Error
        this.notificationService.error(res.message);
      }
    });
  }

  updateProfileImageUrl() {
    this.accountService
      .updateProfile({
        ...this.profileForm.value,
        profilePicture: this.profileImageUrl,
      })
      .subscribe((res) => {
        if (!res.isSuccess) {
          this.notificationService.error('An error occurred');
        }
      });
  }

  updateProfile() {
    this.accountService
      .updateProfile({
        ...this.profileForm.value,
        profilePicture: this.profileImageUrl,
      })
      .subscribe((res) => {
        if (res.isSuccess) {
          this.notificationService.success(res.message);
        }
      });
  }
}
