<div class="main-container">
  <div class="container">
    <div class="d-flex justify-content-center">
      <img src="../../../assets/earn_money.png" />
      <div class="d-flex flex-column mx-3">
        <div class="m-4">
          <p class="h4">Share your gear with vetted filmmakers</p>
          <p class="details">List for free, set your price, and rest assured with our $2M liability
            insurance</p>
          <a [routerLink]="['my-listings', 'new-listing']">
            <button mat-stroked-button>List gear</button>
          </a>
        </div>
        <div class="m-4">
          <p class="h4">Join our crew directory for local gigs</p>
          <p class="details">Stay paid and protected when you complete all transactions on camlyst.</p>
          <a [routerLink]="['crew']">
            <button mat-stroked-button>Join crew / talents</button>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>