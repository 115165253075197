import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AccountService } from './../account/account.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleGuardService implements CanActivate {
  constructor(public _accountService: AccountService, public router: Router) {}

  canActivate(): Observable<boolean> {
    return this._accountService.getProfile(true).pipe(
      map((res) => {
        if (res.isSuccess) {
          return res.user.role === 'User';
        }
        return false;
      })
    );
  }
}
