<div class="container">
  <div *ngIf="bookings && bookings.length === 0" class="my-5 py-5 d-flex justify-content-center">
    <p class="mat-display-1 text-center">You have no sent booking.</p>
  </div>
  <div *ngIf="bookings && bookings.length > 0">
    <div class="row" *ngFor="let booking of bookings">
      <div class="my-2 w-100">
        <app-booking-card [booking]="booking"></app-booking-card>
      </div>
    </div>
  </div>
</div>