import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account/account.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import {
  Booking,
  TransformedBookingAccountEntry,
  BookingAccountEntry,
} from 'src/app/utils/types';
import { ListingsService } from 'src/app/services/listings/listings.service';
import { CrewService } from 'src/app/services/crew/crew.service';
import {
  BookingStatus,
  AccountEntryType,
  AccountEntryStatus,
} from 'src/app/utils/enums';
import { calcServiceFee } from 'src/app/utils/calculate';
import { TransactionsService } from 'src/app/services/transactions/transactions.service';
import * as moment from 'moment';

const NO_PROFILE_PIC = '../../../../assets/default_user.png';
const NO_LISTING_PIC = '../../../../assets/no-image-available.png';

enum BookingStatusColor {
  'blue darken-2' = 1,
  'indigo darken-2',
  'green darken-2',
  'orange darken-2',
  'red darken-2',
  'grey darken-2',
}

@Component({
  selector: 'app-view-booking',
  templateUrl: './view-booking.component.html',
  styleUrls: ['./view-booking.component.scss'],
})
export class ViewBookingComponent implements OnInit {
  booking!: Booking;
  accountEntries!: TransformedBookingAccountEntry[];
  userId?: string;
  profile!: {
    picture: string;
    name?: string;
  };
  status!: {
    text: string;
    color: string;
  };
  isCanceled = false;
  amount?: string;
  serviceFee?: string;
  payout?: string;
  finalPayout?: string;
  isOwner = false;

  constructor(
    private _bookingService: BookingService,
    private _accountService: AccountService,
    private _route: ActivatedRoute,
    private _crewService: CrewService,
    private _listingService: ListingsService,
    private _transactionService: TransactionsService
  ) {
    this._route.params.subscribe((params) => {
      const bookingId = params.bookingId;
      this._getBooking(bookingId);
    });
  }

  private _getTransactions(bookingId: string) {
    this._transactionService
      .getTransactionsByBookingId(bookingId)
      .subscribe((res) => {
        const account = res[0];
        let finalPayout =
          this.booking.totalAmountAgreed -
          calcServiceFee(this.booking.totalAmountAgreed);
        this.accountEntries = account.entries.map((entry) => {
          if (entry.type === AccountEntryType.Payment) {
            finalPayout += entry.amount - calcServiceFee(entry.amount);
          }

          if (entry.type === AccountEntryType.Refund) {
            finalPayout -= entry.amount - calcServiceFee(entry.amount);
          }

          return this._transformEntry(entry);
        });
        this.finalPayout = `$ ${finalPayout.toFixed(2)}`;
      });
  }

  private _transformEntry(
    entry: BookingAccountEntry
  ): TransformedBookingAccountEntry {
    const { amount, type, status, created } = entry;
    return {
      ...entry,
      amount: `$ ${amount.toFixed(2)}`,
      ...(this.isOwner && {
        serviceFee: `-$ ${calcServiceFee(amount).toFixed(2)}`,
        payoutAmount: `$ ${(amount - calcServiceFee(amount)).toFixed(2)}`,
      }),
      type: AccountEntryType[type],
      status: AccountEntryStatus[status],
      created: moment(Number(created)).format('MMM DD, YYYY'),
    };
  }

  private _getBooking(id: string) {
    this._bookingService.getById(id).subscribe((res) => {
      this.booking = res[0];
      this.amount = this.booking.totalAmountAgreed.toFixed(2);
      this.serviceFee = calcServiceFee(this.booking.totalAmountAgreed).toFixed(
        2
      );
      this.payout = (
        this.booking.totalAmountAgreed - Number(this.serviceFee)
      ).toFixed(2);

      this.status = this._getStatus(this.booking.status);
      this.isCanceled = this.booking.status === BookingStatus.Canceled;
      if (this.booking) {
        this._getProfile(this.booking);
      }
      this.userId = this._accountService.currentUser?.name;
      this.isOwner = this.booking.ownerId === this.userId;
      this._getTransactions(id);
    });
  }

  private _getStatus(statusNumber: number) {
    return {
      text: BookingStatus[statusNumber],
      color: BookingStatusColor[statusNumber],
    };
  }

  _getProfile(booking: Booking) {
    if (booking.type === 'crew' && booking.crewId) {
      this._crewService.getCrew(booking.crewId.toString()).subscribe((res) => {
        this.profile = {
          picture: res.result.user.profilePicture || NO_PROFILE_PIC,
          name: res.result.user.firstName,
        };
      });
    }
    if (booking.type === 'listing' && booking.listingId) {
      this._listingService.getListing(booking.listingId).subscribe((res) => {
        this.profile = {
          picture:
            res.result?.imageUrl1 ||
            res.result?.imageUrl2 ||
            res.result?.imageUrl3 ||
            res.result?.imageUrl4 ||
            res.result?.imageUrl5 ||
            NO_LISTING_PIC,
          name: res.result?.title,
        };
      });
    }
  }

  ngOnInit(): void {}
}
