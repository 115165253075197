import { ListingsService } from 'src/app/services/listings/listings.service';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Favorite, Listing } from 'src/app/utils/types';

@Component({
  selector: 'app-listing-wishlist',
  templateUrl: './listing-wishlist.component.html',
})
export class ListingWishlistComponent implements OnChanges {
  @Input() favorites!: Favorite[];
  listings: Listing[] = [];
  constructor(private _listingService: ListingsService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      Array.isArray(changes.favorites.currentValue) &&
      changes.favorites.currentValue.length > 0
    ) {
      changes.favorites.currentValue.forEach((fav) => {
        const listingId = fav.listingId;
        this._listingService.getListing(listingId).subscribe((res) => {
          if (res.result) {
            this.listings.push(res.result);
          }
        });
      });
    }
  }
}
