<mdb-card>
  <mdb-card-header>Verifications</mdb-card-header>
  <mdb-card-body>
    <div class="row m-3">
      <div class="col-md-6 mb-4">
        <p class="h5">Verifications</p>
        <p>We're building a community and want to ensure trust for both guests and hosts. Hosts are more likely
          to
          accept booking requests from guests with verifications.</p>
      </div>
      <div class="col-md-6">
        <div class="row ml-2 mb-4">
          <p class="h6">
            <mdb-icon fas icon="check-circle" [class.grey-text]="!user?.isEmailConfirmed"
              [class.blue-text]="user?.isEmailConfirmed"></mdb-icon>
            <span class="ml-2">Verify Email</span>
          </p>
          <p class="ml-4">We want to be able to reach you when needed. Your email won't be public.</p>
        </div>
        <div class="row ml-2 mb-4">
          <p class="h6">
            <mdb-icon fas icon="check-circle" [class.grey-text]="!user?.isPhoneConfirmed"
              [class.blue-text]="user?.isPhoneConfirmed"></mdb-icon>
            <span class="ml-2">Verify Phone Number</span>
          </p>
          <p class="ml-4">We want to be able to reach you when needed. Your phone number won't be public.</p>
          <p *ngIf="!user?.isPhoneConfirmed" class="h6 ml-4"><a [routerLink]="['phone-number']" class="blue-text">Verify
              my phone number</a></p>
        </div>
        <div class="row ml-2 mb-4">
          <p class="h6">
            <mdb-icon fas icon="check-circle" [class.grey-text]="!user?.isGovtIDConfirmed"
              [class.blue-text]="user?.isGovtIDConfirmed"></mdb-icon>
            <span class="ml-2">Government ID Verification</span>
          </p>
          <p class="ml-4">To safely rent, send a message or book, we require everyone in the community to
            verify their
            identity.</p>
          <p *ngIf="!user?.isGovtIDConfirmed" class="h6 ml-4"><a class="blue-text" [routerLink]="['govt-id']">Verify my
              identity</a></p>
        </div>
      </div>
    </div>
  </mdb-card-body>
</mdb-card>