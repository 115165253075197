<div class="list-group list-group-flush">
  <a [routerLink]="['profile']" routerLinkActive="active" class="list-group-item list-group-item-action waves-light"
    mdbWavesEffect>
    <mdb-icon fas icon="user" class="pr-2"></mdb-icon>
    <span>Profile</span>
  </a>
  <a [routerLink]="['password']" routerLinkActive="active" class="list-group-item list-group-item-action waves-light"
    mdbWavesEffect>
    <mdb-icon fas icon="unlock" class="pr-2"></mdb-icon>
    <span>Password</span>
  </a>
  <a [routerLink]="['verification']" routerLinkActive="active"
    class="list-group-item list-group-item-action waves-light" mdbWavesEffect>
    <mdb-icon fas icon="user-check" class="pr-2"></mdb-icon>
    <span>Verification</span>
  </a>
  <a [routerLink]="['payout']" routerLinkActive="active" class="list-group-item list-group-item-action waves-light"
    mdbWavesEffect>
    <mdb-icon fas icon="hand-holding-usd" class="pr-2"></mdb-icon>
    <span>Payout</span>
  </a>
  <a [routerLink]="['restrictions']" routerLinkActive="active"
    class="list-group-item list-group-item-action waves-light" mdbWavesEffect>
    <mdb-icon fas icon="minus-circle" class="pr-2"></mdb-icon>
    <span>Restrictions</span>
  </a>
  <!-- <a [routerLink]="['delete']" routerLinkActive="active" class="list-group-item list-group-item-action waves-light"
    mdbWavesEffect>
    <mdb-icon fas icon="trash" class="pr-2"></mdb-icon>
    <span>Delete Account</span>
  </a> -->
</div>