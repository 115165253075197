import { BookingService } from './../../services/booking/booking.service';
import { ThreadStatus, UserType, BookingStatus } from 'src/app/utils/enums';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { ListingsService } from 'src/app/services/listings/listings.service';
import { AccountService } from 'src/app/services/account/account.service';
import { ThreadService } from 'src/app/services/thread/thread.service';
import { GenericResponse } from 'src/app/utils/types';
import { NotificationService } from 'src/app/services/notification/notification.service';
import * as moment from 'moment';
import { calcFraction, calcTaxes, calcTotal } from 'src/app/utils/calculate';

@Component({
  selector: 'app-rent-form',
  templateUrl: './rent-form.component.html',
  styleUrls: ['./rent-form.component.scss'],
})
export class RentFormComponent implements OnInit {
  blockDateError = false;
  @Input() price!: number;
  @Input() listingId!: string;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  // Rental insurance
  // insurance = new FormControl(null, Validators.required);

  days = 0;
  rentalAmount = 0;
  tax = 0;
  securityDeposit = 0;
  total = 0;
  agree = false;
  showInvoice = false;
  today: Date = new Date();
  listingUserId?: string;
  listingName?: string;
  displayedRates: {
    rentalAmount: string;
    securityDeposit: string;
    tax: string;
    total: string;
  } = {
    rentalAmount: '',
    securityDeposit: '',
    tax: '',
    total: '',
  };
  blockStartDate?: string;
  blockEndDate?: string;
  rangeFilter = (date: Date | null): boolean => true;

  constructor(
    private _listingService: ListingsService,
    private _accountService: AccountService,
    private _threadService: ThreadService,
    private _bookingService: BookingService,
    private _notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    if (this.listingId) {
      this._listingService.getListing(this.listingId).subscribe((res) => {
        const listing = res.result;
        if (listing) {
          if (listing.blockStartDate && listing.blockEndDate) {
            this.blockStartDate = listing.blockStartDate;
            this.blockEndDate = listing.blockEndDate;
            this.rangeFilter = (date: Date | null): boolean => {
              const blockRange = [
                moment(new Date(listing.blockStartDate as string)),
                moment(new Date(listing.blockEndDate as string)),
              ];
              if (date) {
                const momentDate = moment(date);
                return !(
                  momentDate >= blockRange[0] && momentDate <= blockRange[1]
                );
              }
              return true;
            };
          }
          this.listingUserId = listing.userId;
          this.listingName = listing.title;
        }
      });
    }
  }

  onDateChange() {
    const startDate = this.range.value.start;
    const endDate = this.range.value.end;
    if (startDate && endDate && this.blockStartDate) {
      const blockStartDate = moment(new Date(this.blockStartDate));
      if (
        (startDate < blockStartDate && endDate < blockStartDate) ||
        startDate > blockStartDate
      ) {
        this.showInvoice = true;
        this.blockDateError = false;
        this._calcBill();
      } else {
        this.showInvoice = false;
        this.blockDateError = true;
        return;
      }
    }
    this.blockDateError = false;
    this.showInvoice = true;
    this._calcBill();
  }

  private _calcBill() {
    this._calcRentalDays();
    this._calcRentalAmount();
    this._calcTax();
    this._calcSecurityDeposit();
    this._calcTotal();
  }

  private _calcRentalDays() {
    if (this.range.value.start && this.range.value.end) {
      const startDate = this.range.value.start;
      const endDate = this.range.value.end;
      const days = endDate.diff(startDate, 'days');
      this.days = days;
    }
  }
  private _calcRentalAmount() {
    if (this.price) {
      const rentalAmount = this.days * this.price;
      this.rentalAmount = rentalAmount;
      this.displayedRates.rentalAmount = rentalAmount.toFixed(2);
    }
  }

  private _calcTax() {
    const tax = calcTaxes(this.rentalAmount);
    this.tax = tax;
    this.displayedRates.tax = tax.toFixed(2);
  }

  private _calcSecurityDeposit() {
    const securityDeposit = calcFraction({
      amount: this.rentalAmount,
      percentage: 20,
    });
    this.securityDeposit = securityDeposit;
    this.displayedRates.securityDeposit = securityDeposit.toFixed(2);
  }

  private _calcTotal() {
    const total = calcTotal([
      this.rentalAmount,
      this.tax,
      this.securityDeposit,
    ]);
    this.total = total;
    this.displayedRates.total = total.toFixed(2);
  }

  submit() {
    this._createAutoThread()
      .then((res) => {
        const threadId = res?.insertedId;
        return threadId;
      })
      .then((threadId) => {
        if (threadId) {
          return this._createBookingRequest(threadId);
        }
        return;
      })
      .then((bookingReqRes) => {
        if (bookingReqRes && bookingReqRes.isSuccess) {
          this.range.reset();
          this.showInvoice = false;
          this._notificationService.success(bookingReqRes.message);
        }
      })
      .catch((error) => {
        this._notificationService.error('Something went wrong');
        console.error(error);
      });
  }

  private _createAutoThread(): Promise<GenericResponse | null> {
    const userId = this._accountService.currentUser?.name;
    const timestamp = Date.now().toString();
    if (userId && this.listingUserId) {
      const data = {
        senderId: userId,
        receiverId: this.listingUserId,
        messageText: 'AUTOMATED MESSAGE - I have submitted a booking request',
        status: ThreadStatus.Pending,
        timestamp,
        type: UserType.Listing,
        listingId: this.listingId,
      };
      return this._threadService.create(data);
    }
    return new Promise((resolve) => resolve(null));
  }

  private _createBookingRequest(
    threadId: string
  ): Promise<GenericResponse | null> {
    this.range.markAllAsTouched();
    const userId = this._accountService.currentUser?.name;
    if (userId && this.range.valid && this.rentalAmount && this.listingUserId) {
      const data = {
        startDate: this.range.value.start.format('ddd, MMM DD, YYYY'),
        endDate: this.range.value.end.format('ddd, MMM DD, YYYY'),
        totalAmountAgreed: Number(this.rentalAmount),
        notes: 'AUTOMATED NOTE - Client submitted a booking request',
        type: 'listing' as 'listing' | 'crew',
        listingId: this.listingId,
        customerId: userId,
        ownerId: this.listingUserId,
        threadId,
        status: BookingStatus.New,
        created: Date.now().toString(),
        lastModified: Date.now().toString(),
      };
      return this._bookingService.create(data).toPromise();
    }
    return new Promise((resolve) => resolve(null));
  }
}
