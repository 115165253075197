<mdb-card>
  <mdb-card-header>
    Profile
  </mdb-card-header>
  <mdb-card-body [formGroup]="profileForm">
    <div class="row m-3">
      <div class="col-md-4 align-self-center">
        <p class="h5">Personal Information</p>
      </div>
      <div class="col-md-8">
        <div class="row mb-3">
          <div class="col-sm-6 align-self-center">
            <p class="h6">Profile Photo</p>
            <p class="h6">Use a photo that shows your face</p>
          </div>
          <div class="col-sm-6 image-upload-form">
            <div class="d-flex flex-column align-items-center">
              <mdb-icon *ngIf="!profileImageUrl" fas icon="user-circle" size="9x"></mdb-icon>
              <img *ngIf="profileImageUrl" [src]='profileImageUrl' alt="thumbnail"
                class="img-fluid rounded-circle z-depth-1">
              <label class="mt-3" for="file">Change Photo</label>
              <a *ngIf="profileImageUrl" class="delete-link" (click)="deleteImage()">Delete Photo</a>
              <input id="file" type="file" name="profilePicture" (change)="onImageUpload($event)" />
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="firstName">First Name</label>
            <input mdbInput type="text" class="form-control" id="firstName" formControlName="firstName">
          </div>
          <div class="form-group col-md-6">
            <label for="lastName">Last Name</label>
            <input mdbInput type="text" class="form-control" id="lastName" formControlName="lastName">
          </div>
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input mdbInput type="email" class="form-control" id="email" formControlName="email">
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="phone">Phone Number</label>
            <input mdbInput type="tel" class="form-control" id="phone" formControlName="phone">
          </div>
        </div>
      </div>
    </div>
    <mat-divider class="divider"></mat-divider>
    <div class="row m-3">
      <div class="col-md-4 align-self-center">
        <p class="h5">Tell is a bit about yourself</p>
      </div>
      <div class="col-md-8">
        <div class="form-group">
          <label for="company">Company</label>
          <input mdbInput type="text" class="form-control" id="company" formControlName="company">
        </div>
        <div class="form-group">
          <label for="website">Website</label>
          <input mdbInput type="url" class="form-control" id="website" formControlName="website">
        </div>
        <div class="form-group">
          <label for="aboutMe">About me</label>
          <textarea type="text" id="aboutMe" class="md-textarea form-control" rows="5" mdbInput
            formControlName="aboutMe"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <button class="mx-auto" type="button" mdbBtn mdbWavesEffect (click)="updateProfile()">
        Update Profile
      </button>
    </div>
  </mdb-card-body>
</mdb-card>