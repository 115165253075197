<mdb-card>
  <mdb-card-header class="d-flex justify-content-between">
    <span class="text-left">Phone Verification</span>
    <a [routerLink]="['/dashboard/verification']" class="text-right">Close</a>
  </mdb-card-header>
  <mdb-card-body class="px-1">
    <mat-vertical-stepper linear #stepper>
      <mat-step [stepControl]="phoneNumberForm" label="Enter your phone number">
        <form class="my-3" [formGroup]="phoneNumberForm">
          <p class="blue-text h6">Add your phone number. We will send you a text message with a
            verification code that
            you need to
            enter on the next screen</p>
          <div class="col-md-6">
            <label class="mt-3" for="phoneNumber">Phone number</label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">+1</span>
              </div>
              <input mdbInput type="tel" class="form-control" id="phoneNumber" formControlName="phoneNumber">
            </div>
            <div>
              <button class="m-0" type="button" mdbBtn color="primary" (click)='verifyPhone(stepper)'>Next</button>
            </div>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="verificationCodeForm" label="Enter verification code">
        <form class="my-3" [formGroup]="verificationCodeForm">
          <p class="blue-text h6 text-center">We just sent a verification code to the number you provided us with.
          </p>
          <div class="col-md-6">
            <label class="mt-3" for="verificationCode">Enter verification code</label>
            <div class="input-group mb-3">
              <input mdbInput type="text" class="form-control" id="verificationCode" formControlName="verificationCode">
            </div>
            <div class="d-flex flex-column align-items-start">
              <button class="m-0" type="button" mdbBtn color="primary" (click)='verifyCode(stepper)'>Submit</button>
              <button class="mx-0 px-0" type="button" mdbBtn color="link" (click)='resendCode()'>
                <span class="text-primary text-capitalize">Resend Code</span>
              </button>
              <button class="mx-0 px-0" type="button" mdbBtn color="link" matStepperPrevious>
                <span class="text-primary text-capitalize">Change Phone number</span>
              </button>
            </div>
          </div>
        </form>
      </mat-step>
      <mat-step label="Done">
        <div class="d-flex flex-column align-items-center ml-n3">
          <mdb-icon fas icon="check-circle" size="7x" class="blue-text p-2"></mdb-icon>
          <p class="blue-text h6 text-center">Your phone was successfully verified</p>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </mdb-card-body>
</mdb-card>