export enum BookingStatus {
  New = 1,
  Accepted,
  Paid,
  Declined,
  Canceled,
  Completed,
}

export enum ThreadStatus {
  Inquiry = 1,
  Accepted,
  Pending,
  Declined,
  Completed,
  Canceled,
}

export enum UserType {
  Crew = 1,
  Listing,
}

export enum AccountEntryType {
  Payment = 1,
  Refund,
}

export enum AccountEntryStatus {
  Pending = 1,
  Complete,
}

export enum EditBookingState {
  Initiated = 'initiated',
  Accepted = 'accepted',
  Completed = 'completed',
  Rejected = 'rejected',
}

export enum VerificationStatus {
  Pending = 1,
  Approved,
  Denied,
}
