import { Keyword } from './../../utils/types';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const baseUrl = `${environment.apiUrl}/Utility`;
const CACHE_SIZE = 1;
@Injectable({
  providedIn: 'root',
})
export class KeywordsService {
  private keywordsCache$!: Observable<{ result: Keyword[] }>;
  constructor(private http: HttpClient) {}

  public keywords(): Observable<{ result: Keyword[] }> {
    if (!this.keywordsCache$) {
      this.keywordsCache$ = this.http
        .get<{ result: Keyword[] }>(`${baseUrl}/keywords`)
        .pipe(shareReplay(CACHE_SIZE));
    }
    return this.keywordsCache$;
  }
}
