<div class="container">
  <div *ngIf="listing" class="my-4">
    <!-- listing images -->
    <mdb-carousel class="cx-carousel mx-auto" [animation]="'slide'" [interval]=0>
      <mdb-carousel-item *ngIf="listing.imageUrl1">
        <img class="d-block w-100" [src]="listing.imageUrl1">
      </mdb-carousel-item>
      <mdb-carousel-item *ngIf="listing.imageUrl2">
        <img class="d-block w-100" [src]="listing.imageUrl2">
      </mdb-carousel-item>
      <mdb-carousel-item *ngIf="listing.imageUrl3">
        <img class="d-block w-100" [src]="listing.imageUrl3">
      </mdb-carousel-item>
      <mdb-carousel-item *ngIf="listing.imageUrl4">
        <img class="d-block w-100" [src]="listing.imageUrl4">
      </mdb-carousel-item>
      <mdb-carousel-item *ngIf="listing.imageUrl5">
        <img class="d-block w-100" [src]="listing.imageUrl5">
      </mdb-carousel-item>
    </mdb-carousel>
    <div class="row">
      <div class="col my-2 listing-details">
        <div class="row mx-0 my-2">
          <div class="col">
            <p class="h4">{{listing.brand.name}} {{listing.model.name}}</p>
            <p class="thin-text">
              <mdb-icon fas icon="map-marker-alt"></mdb-icon> {{listing.city.name}}, {{listing.province.name}}
            </p>
            <div class="d-flex">
              <ngb-rating *ngIf="listing.rating" [rate]="listing.rating" [readonly]="true" [max]=5>
                <ng-template let-fill="fill" let-index="index">
                  <span class="star" [class.filled]="fill === 100">&#9733;</span>
                </ng-template>
              </ngb-rating>
              <p class="thin-text mx-4">
                {{listing.reviews.length}} Reviews
              </p>
            </div>
          </div>
        </div>
        <div class="mx-3 my-2">
          <p class="h6">Category: <span class="thin-text">{{listing.category.name}}</span></p>
          <p class="h6">Brand: <span class="thin-text">{{listing.brand.name}}</span></p>
          <p class="h6">Model: <span class="thin-text">{{listing.model.name}}</span></p>
          <div>
            <p class="h6">Description:</p>
            <p class="thin-text">{{listing.description}}</p>
          </div>
          <app-addon *ngIf="listing.addons && listing.addons.length > 0" [addons]="listing.addons" [readonly]="true">
          </app-addon>
          <div>
            <p class="h6">Cancellation Policy:</p>
            <p class="thin-text text-capitalize">{{listing.cancellation}} - {{cancelPolicy}}</p>
          </div>
        </div>
        <div *ngIf="listing.reviews.length > 0 && activeReviews.length > 0" class="mt-2">
          <p class="h6">Reviews</p>
          <div *ngFor="let review of activeReviews" class="my-3">
            <app-review [value]="review"></app-review>
          </div>
          <mat-paginator [hidePageSize]="true" [length]="listing.reviews.length" [pageSize]="2" showFirstLastButtons
            (page)="onPageChange($event)">
          </mat-paginator>
        </div>
      </div>
      <div *ngIf="userProfile" class="col">
        <div class="d-flex flex-column align-items-center my-3">
          <mdb-icon *ngIf="!userProfile.profilePicture" fas icon="user-circle" size="9x"></mdb-icon>
          <img *ngIf="userProfile.profilePicture" [src]='userProfile.profilePicture' alt="thumbnail"
            class="img-fluid rounded-circle z-depth-1 listing-profile">
          <p class="h6 mt-3">{{userProfile.firstName}} {{userProfile.lastName}}</p>
          <button *ngIf="isPreview" class="m-3" mdbBtn color="light" (click)="copy()">
            <mdb-icon fas icon="copy"></mdb-icon>
            <span class="mx-2 text-capitalize">Copy link</span>
          </button>
        </div>
      </div>
      <div *ngIf="!isPreview" class="col-md-5">
        <app-rent-form [listingId]="listingId" [price]="listing.dailyRentalPrice"></app-rent-form>
        <button *ngIf="!isFavorite" class="m-3" mdbBtn color="light" (click)="addToFavorites()">
          <mdb-icon far icon="heart"></mdb-icon>
          <span class="mx-2 text-capitalize">Save</span>
        </button>
        <button *ngIf="isFavorite" class="m-3" mdbBtn color="light" (click)="removeFromFavorites()">
          <mdb-icon fas icon="heart"></mdb-icon>
          <span class="mx-2 text-capitalize">Saved</span>
        </button>
        <button class="m-3" mdbBtn color="light" (click)="copy()">
          <mdb-icon fas icon="copy"></mdb-icon>
          <span class="mx-2 text-capitalize">Copy link</span>
        </button>
      </div>
    </div>
  </div>