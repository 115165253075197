<div class="container my-4">
  <div class="d-flex justify-content-between p-2">
    <div [formGroup]="crewForm" class="crew-form">
      <p class="h4 mb-3">Crew / Talent details</p>
      <div class="form-group">
        <label class="h6" for="companyName">Personal / Company name</label>
        <p class="text-muted">This name will be displayed on your profile</p>
        <input mdbInput type="text" class="form-control" id="companyName" formControlName="companyName">
        <mat-error
          *ngIf="(crewForm.controls.companyName.dirty || crewForm.controls.companyName.touched) && crewForm.controls.companyName.errors?.required">
          * Field Required
        </mat-error>
      </div>
      <div class="form-group">
        <label class="h6" for="tag">Crew / Talent position</label>
        <p class="text-muted">Maximum of 5 tags</p>
        <form (submit)="onAddTag()" class=" input-group mb-3">
          <input id="tag" mdbInput type="text" class="form-control" [formControl]="tag" [matAutocomplete]="item">
          <mat-autocomplete #item="matAutocomplete">
            <mat-option *ngFor="let option of filteredTags | async" [value]="option.description">
              {{option.description}}
            </mat-option>
          </mat-autocomplete>
          <div class="input-group-append">
            <button type="submit" mdbBtn color="default" size="md" class="m-0 ml-4 px-3 py-2 light-blue darken-2"
              mdbWavesEffect [disabled]="currentTags.length>4">Add Tag</button>
          </div>
        </form>
        <div class="w-50 mt-2">
          <div class="d-flex flex-wrap">
            <div class="mx-2" *ngFor="let tag of currentTags">
              <p class="h5">
                <mdb-badge [pill]="true" color="white" class="tag-close">
                  <span class="text-dark">{{tag}}</span>
                  <mdb-icon fas icon="times" class="red-text pl-2" (click)="removeTag(tag)"></mdb-icon>
                </mdb-badge>
              </p>
            </div>
          </div>
        </div>
        <p>
          <mat-error *ngIf="(tag.dirty) && !isTagValid">
            * Choose an available tag
          </mat-error>
        </p>
        <p>
          <mat-error *ngIf="(tag.dirty || tag.touched) && currentTags.length<1">
            * Add at least one tag
          </mat-error>
        </p>
      </div>
      <div class="form-group">
        <label class="h6" for="gender">Gender</label>
        <select formControlName="gender" id="gender" class="custom-select">
          <option selected [ngValue]="null">Choose your gender</option>
          <option *ngFor="let option of genderOptions" [value]="option.value || null">{{option.name}}</option>
        </select>
      </div>
      <div class="form-group">
        <label class="h6" for="age">Age bracket</label>
        <select formControlName="ageBracket" id="age" class="custom-select">
          <option selected [ngValue]="null">Choose your age bracket</option>
          <option *ngFor="let option of ageBracketOptions" [value]="option.value">{{option.name}}</option>
        </select>
      </div>
      <div class="form-group">
        <label class="h6" for="address">Address</label>
        <p class="text-muted">This information is private and will not be displayed on profile</p>
        <input mdbInput type="text" class="form-control" id="address" formControlName="address">
        <mat-error
          *ngIf="(crewForm.controls.address.dirty || crewForm.controls.address.touched) && crewForm.controls.address.errors?.required">
          * Field Required
        </mat-error>
      </div>
      <div class="form-group">
        <label class="h6" for="province">Province / Territory</label>
        <select formControlName="province" id="province" class="custom-select" (change)="onProvinceChange()">
          <option selected [ngValue]="null">Choose your province or territory</option>
          <option *ngFor="let option of provinceOptions" [value]="option.id">{{option.name}}</option>
        </select>
        <mat-error *ngIf="crewForm.controls.province.touched && crewForm.controls.province.errors?.required">
          * Field Required
        </mat-error>
      </div>
      <div *ngIf="showCity" class="form-group">
        <label class="h6" for="city">City</label>
        <input id="city" mdbInput type="text" class="form-control" formControlName="city" [matAutocomplete]="city">
        <p>
          <mat-error
            *ngIf="(crewForm.controls.city.dirty || crewForm.controls.city.touched) && crewForm.controls.city.errors?.match">
            * Choose only cities in the list
          </mat-error>
        </p>
        <p>
          <mat-error
            *ngIf="(crewForm.controls.city.touched || crewForm.controls.city.dirty) && crewForm.controls.city.errors?.required">
            * Field Required
          </mat-error>
        </p>
        <mat-autocomplete #city="matAutocomplete">
          <mat-option *ngFor="let option of filteredCities | async" [value]="option.name">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </div>
      <div *ngIf="showCity" class="form-group">
        <label class="h6" for="postalCode">Postal Code</label>
        <p class="text-muted">This information is private and will not be displayed on profile</p>
        <input mdbInput type="text" class="form-control" id="postalCode" formControlName="postalCode">
        <p>
          <mat-error
            *ngIf="(crewForm.controls.postalCode.touched || crewForm.controls.postalCode.dirty) && crewForm.controls.postalCode.errors?.required">
            * Field Required
          </mat-error>
        </p>
        <p>
          <mat-error
            *ngIf="(crewForm.controls.postalCode.touched || crewForm.controls.postalCode.dirty) && crewForm.controls.postalCode.errors?.pattern">
            * Enter a valid postal code
          </mat-error>
        </p>
      </div>
      <div class="form-group">
        <label class="h6" for="phoneNumber">Phone Number</label>
        <p class="text-muted">This information is private and will not be displayed on profile</p>
        <input mdbInput type="text" class="form-control" id="phoneNumber" formControlName="phoneNo">
        <mat-error
          *ngIf="(crewForm.controls.phoneNo.touched || crewForm.controls.phoneNo.dirty) && crewForm.controls.phoneNo.errors?.required">
          * Field Required
        </mat-error>
      </div>
      <div class="form-group">
        <label class="h6" for="email">Email Address</label>
        <p class="text-muted">This information is private and will not be displayed on profile</p>
        <input mdbInput type="email" class="form-control" id="email" formControlName="email">
        <p>
          <mat-error
            *ngIf="(crewForm.controls.email.touched || crewForm.controls.email.dirty) && crewForm.controls.email.errors?.required">
            * Field Required
          </mat-error>
        </p>
        <p>
          <mat-error
            *ngIf="(crewForm.controls.email.touched || crewForm.controls.email.dirty) && crewForm.controls.email.errors?.email">
            * Enter a valid email address
          </mat-error>
        </p>
      </div>
      <div class="form-group">
        <label class="h6" for="aboutMe">About me</label>
        <p class="text-muted">Maximum of 50 Characters</p>
        <textarea type="text" id="aboutMe" class="form-control md-textarea" rows="3" mdbInput
          formControlName="aboutMe"></textarea>
        <mat-error
          *ngIf="(crewForm.controls.aboutMe.touched || crewForm.controls.aboutMe.dirty) && crewForm.controls.aboutMe.errors?.required">
          * Field Required
        </mat-error>
      </div>
      <div class="form-group">
        <label class="h6" for="levelOfExperience">Level of Experience</label>
        <select formControlName="levelOfExperience" id="levelOfExperience" class="custom-select">
          <option selected [ngValue]="null">Choose your level of Expertise</option>
          <option *ngFor="let option of levelsOfExp" [value]="option.value">{{option.name}}</option>
        </select>
        <mat-error
          *ngIf="crewForm.controls.levelOfExperience.touched && crewForm.controls.levelOfExperience.errors?.required">
          * Field Required
        </mat-error>
      </div>
      <div class="form-group">
        <label class="h6">Display Photos</label>
        <p class="text-muted">You must upload at least one image.</p>
        <div class="row ml-1">
          <div class="col-xs">
            <app-file-drop [imagePath]="imagePath1" fileId="image1" (upload)="onImageUploadChange($event)">
            </app-file-drop>
          </div>
          <div class="col-xs">
            <app-file-drop [imagePath]="imagePath2" fileId="image2" (upload)="onImageUploadChange($event)">
            </app-file-drop>
          </div>
          <div class="col-xs">
            <app-file-drop [imagePath]="imagePath3" fileId="image3" (upload)="onImageUploadChange($event)">
            </app-file-drop>
          </div>
        </div>
        <p class="mt-s48 text-muted">Your first photo is what users will see when browsing.</p>
      </div>
      <div class="form-group">
        <label class="h6" for="linkToReel">Link to reel / portfolio</label>
        <p class="text-muted">Youtube or Vimeo only</p>
        <input mdbInput type="url" class="form-control" id="linkToReel" formControlName="linkToReel">
        <mat-error
          *ngIf="(crewForm.controls.linkToReel.touched || crewForm.controls.linkToReel.dirty) && crewForm.controls.linkToReel.errors?.pattern">
          * Enter a valid URL
        </mat-error>
      </div>
      <div class="form-group">
        <p class="h5">Cancellation</p>
        <p class="h6">All bookings are subject to 24 hours grace period.<br />
          This provides full refunds for cancellations within 24 hours of booking.</p>
        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="veryFlexible" value="veryFlexible" name="cancellation"
            mdbInput formControlName="cancellation">
          <label class="custom-control-label" for="veryFlexible">
            <p class="h6">Very Flexible</p>
            <p class="text-muted">100% refund for bookings cancelled 24hrs prior to pickup date
              Cancellations submitted less then 24hrs prior to pick up time are
              non refundable.</p>
          </label>
        </div>

        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="flexible" value="flexible" name="cancellation" mdbInput
            formControlName="cancellation">
          <label class="custom-control-label" for="flexible">
            <p class="h6">Flexible</p>
            <p class="text-muted">100% refund for bookings cancelled 3 days prior to pickup date
              Or 50% refund if cancelled 24hrs prior to pick up date.
              Cancellations submitted less then 24hrs prior to pick up time are
              non refundable.</p>
          </label>
        </div>

        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="moderate" value="moderate" name="cancellation" mdbInput
            formControlName="cancellation">
          <label class="custom-control-label" for="moderate">
            <p class="h6">Moderate</p>
            <p class="text-muted">100% refund for bookings cancelled 5days prior to pickup date
              Cancellations submitted less then 5 days prior to pick up date are
              non refundable.</p>
          </label>
        </div>

        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="strict" value="strict" name="cancellation" mdbInput
            formControlName="cancellation">
          <label class="custom-control-label" for="strict">
            <p class="h6">Strict</p>
            <p class="text-muted">100% refund for cancellations made within 2 days of booking,
              if the pick-up date is at least 12 days away.
              50% refund for cancellations made at least 7 days before pick-up date.
              No refunds for cancellations made 6 days prior to pick up date.</p>
          </label>
        </div>
        <mat-error *ngIf="crewForm.controls.cancellation.touched && crewForm.controls.cancellation.errors?.required">
          * Field Required
        </mat-error>
      </div>
      <div class="form-group">
        <p class="h6">Visibility</p>
        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="public" [value]="true" name="visibility" mdbInput
            formControlName="visibility">
          <label class="custom-control-label" for="public">
            Public
          </label>
        </div>
        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="private" [value]="false" name="visibility" mdbInput
            formControlName="visibility">
          <label class="custom-control-label" for="private">
            Private
          </label>
        </div>
        <mat-error *ngIf="crewForm.controls.visibility.touched && crewForm.controls.visibility.errors?.required">
          * Field Required
        </mat-error>
      </div>
      <div class="form-group">
        <label class="h6" for="howDidYouHearAboutUs">How did you hear about us?</label>
        <select formControlName="howDidYouHearAboutUs" id="howDidYouHearAboutUs" class="custom-select">
          <option selected [ngValue]="null">Choose one</option>
          <option *ngFor="let option of hearAbtUsOptions" [value]="option.value">{{option.name}}</option>
        </select>
      </div>
      <div *ngIf="!isEdit" class="form-group">
        <mat-checkbox [(ngModel)]="agree" [ngModelOptions]="{standalone: true}">
          <span>By signing up as a member, I agree to Camlyst's
            <a class="text-primary">Terms of services</a>,
            <a class="text-primary" (click)="togglePrivacyPolicy($event)">privacy policy</a> and
            <a class="text-primary">community rules</a></span>
        </mat-checkbox>
      </div>
      <!-- Terms, policy and rules -->
      <div class="my-5">
        <app-statements [show]="showPrivacyPolicy" (showChange)="onShowUserPolicy($event)">
          <app-privacy-statements></app-privacy-statements>
        </app-statements>
      </div>
      <button class="w-100 m-0 light-blue darken-3" type="button" mdbBtn color="light-blue" (click)="submit()"
        [disabled]="!agree && !isEdit">Submit</button>
    </div>
    <div class="ads w-50">
      <div class="my-3 d-flex flex-column align-items-center">
        <img style="width: 100px; height: 100px" src="../../../../assets/protected_dark.png" alt="">
        <p class="h6">Secured</p>
        <p class="text-muted">Free to join</p>
        <p class="text-muted">Get seen and be hired</p>
        <p class="text-muted">24 hrs Customer Support</p>
      </div>
      <div class="my-3 d-flex flex-column align-items-center">
        <img style="width: 100px; height: 100px" src="../../../../assets/moneyicon_dark.png" alt="">
        <p class="h6">Earn Money</p>
        <p class="text-muted">Join for free and start earning</p>
        <p class="text-muted">money from short and long term gigs.</p>
      </div>
      <div class="my-3 d-flex flex-column align-items-center">
        <img style="width: 100px; height: 100px" src="../../../../assets/connect_dark.png" alt="">
        <p class="h6">Build your Community</p>
        <p class="text-muted">Connect with creatives, </p>
        <p class="text-muted">share your gears and services</p>
        <p class="text-muted">with thousands of brands and people</p>
      </div>
    </div>
  </div>
</div>