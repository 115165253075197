import { Router, NavigationEnd, Event } from '@angular/router';
import { AccountService } from './../../services/account/account.service';
import { RentalCategory } from './../../utils/types';
import { RentalCategoriesService } from '../../services/rental/rental-categories.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NavbarComponent as MdbNavbarComponent } from 'angular-bootstrap-md';

const LANDING_URLS = ['/', '/login', '/signup', '/signup-success'];
const HAMBURGER_ICON = `data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E`;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @ViewChild('navbar', { static: true }) navbar!: MdbNavbarComponent;

  rentalCategories: RentalCategory[];
  navClass = 'navbar-landing';
  userImage = '';
  isDashboard = false;
  isWishlist = false;

  constructor(
    private _rentalCtgService: RentalCategoriesService,
    private router: Router,
    public accountService: AccountService
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.isDashboard = this.router.url.includes('/dashboard')
          ? true
          : false;
        this.isWishlist = this.router.url.includes('/wishlist') ? true : false;
        if (LANDING_URLS.includes(this.router.url)) {
          this.navClass = 'navbar-landing';
        } else {
          this.navClass = 'navbar-contrast';
        }
      }
    });
    this.rentalCategories = this._rentalCtgService.getAll();
    if (this.userId) {
      this.accountService.getProfile().subscribe((profile) => {
        if (profile.user.profilePicture) {
          this.accountService.setProfilePic(profile.user.profilePicture);
        }
      });
    }
    this.accountService.onProfilePicChange.subscribe((imageUrl) => {
      this.userImage = imageUrl;
    });
  }

  ngOnInit(): void {}

  logout(): void {
    this.closeMobileMenu();
    this.accountService.logout();
    this.router.navigate(['']);
  }

  get userId(): string | null {
    if (this.isLoggedIn) {
      const currentUser = this.accountService.currentUser;
      return currentUser?.name || null;
    }
    return null;
  }

  get isLoggedIn(): boolean {
    // tslint:disable-next-line:no-non-null-assertion
    const body = document.querySelector('body')!;
    if (body && this.accountService.isAuthenticated()) {
      body.style.cssText = `--kreative-nav-menu-icon: url(${this.userImage}); --kreative-nav-menu-border-radius: 100%`;
      return true;
    }
    body.style.cssText = `--kreative-nav-menu-icon: url(${HAMBURGER_ICON})`;
    return false;
  }

  closeMobileMenu(): void {
    this.navbar.hide();
  }
}
