const cancelPolicies = [
  {
    key: 'veryFlexible',
    value:
      '100% refund for bookings cancelled 24hrs prior to pickup date Cancellations submitted less then 24hrs prior to pick up time are non refundable.',
  },
  {
    key: 'flexible',
    value:
      '100% refund for bookings cancelled 3 days prior to pickup date Or 50% refund if cancelled 24hrs prior to pick up date. Cancellations submitted less then 24hrs prior to pick up time are non refundable.',
  },
  {
    key: 'moderate',
    value:
      '100% refund for bookings cancelled 5days prior to pickup date Cancellations submitted less then 5 days prior to pick up date are non refundable.',
  },
  {
    key: 'strict',
    value:
      '100% refund for cancellations made within 2 days of booking, if the pick-up date is at least 12 days away. 50% refund for cancellations made at least 7 days before pick-up date. No refunds for cancellations made 6 days prior to pick up date.',
  },
];

export const getCancelPolicy = (key: string) =>
  cancelPolicies.find((policy) => policy.key === key);
