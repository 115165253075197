import { NotificationService } from 'src/app/services/notification/notification.service';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ThreadService } from 'src/app/services/thread/thread.service';
import { AccountService } from 'src/app/services/account/account.service';
import * as moment from 'moment';
import { Location } from '@angular/common';
import { ThreadStatus, UserType } from 'src/app/utils/enums';
import { Thread } from 'src/app/utils/types';

@Component({
  selector: 'app-inquiry-message',
  templateUrl: './inquiry-message.component.html',
  styleUrls: ['./inquiry-message.component.scss'],
})
export class InquiryMessageComponent implements OnInit {
  messageText: FormControl = new FormControl();
  crewData?: {
    id: string;
    receiverId: string;
    name: string;
  };

  listingData?: {
    id: string;
    receiverId: string;
    name: string;
  };
  type!: 'crew' | 'listing';

  constructor(
    private _accountService: AccountService,
    private _route: ActivatedRoute,
    private _threadService: ThreadService,
    private _notificationService: NotificationService,
    private _location: Location
  ) {
    this._route.queryParams.subscribe((params) => {
      if (params.crewId) {
        this.type = 'crew';
        this.crewData = {
          id: params.crewId,
          receiverId: params.receiverId,
          name: params.crewName,
        };
      }
      if (params.listingId) {
        this.type = 'listing';
        this.listingData = {
          id: params.listingId,
          receiverId: params.receiverId,
          name: params.listingName,
        };
      }
    });
  }

  ngOnInit(): void {}

  sendMessage() {
    const timestamp = moment.now().toString();
    const userId = this._accountService.currentUser?.name;
    if (userId) {
      const data = {
        senderId: userId,
        messageText: this.messageText.value,
        status: ThreadStatus.Inquiry,
        timestamp,
      };
      if (this.crewData) {
        this._createThread({
          ...data,
          type: UserType.Crew,
          receiverId: this.crewData.receiverId,
          crewId: this.crewData.id,
        }).then((res) => {
          if (res.isSuccess) {
            this._notificationService.success('Your message has been sent');
            this._location.back();
          }
        });
      }
      if (this.listingData) {
        this._createThread({
          ...data,
          type: UserType.Listing,
          receiverId: this.listingData.receiverId,
          listingId: this.listingData.id,
        }).then((res) => {
          if (res.isSuccess) {
            this._notificationService.success('Your message has been sent');
            this._location.back();
          }
        });
      }
    }
  }

  private _createThread(data: Thread) {
    return this._threadService.create(data);
  }
}
