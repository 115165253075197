<div class="container my-4">
  <div class="d-flex justify-content-between p-2">
    <div [formGroup]="listingForm" class="listing-form">
      <p class="h4 mb-3">Listing Details</p>
      <div class="form-group">
        <label class="h6" for="title">Listing Title</label>
        <input mdbInput type="text" class="form-control" id="title" formControlName="title">
        <mat-error
          *ngIf="(listingForm.controls.title.dirty || listingForm.controls.title.touched) && listingForm.controls.title.errors?.required">
          * Field Required
        </mat-error>
      </div>
      <div class="form-group">
        <label class="h6" for="category">Category</label>
        <select formControlName="category" id="category" class="custom-select" (change)="onCategoryChange()">
          <option selected [ngValue]="null">Choose a category</option>
          <option *ngFor="let option of categories" [value]="option.name">{{option.name}}</option>
        </select>
        <mat-error *ngIf="listingForm.controls.category.touched && listingForm.controls.category.errors?.required">
          * Field Required
        </mat-error>
      </div>
      <div *ngIf="showCategory" class="form-group">
        <label class="h6" for="brand">Brand</label>
        <input id="brand" mdbInput type="text" class="form-control" formControlName="brand" [matAutocomplete]="brand">
        <p>
          <mat-error
            *ngIf="(listingForm.controls.brand.dirty || listingForm.controls.brand.touched) && listingForm.controls.brand.errors?.match">
            * Choose only brands in the list
          </mat-error>
        </p>
        <p>
          <mat-error
            *ngIf="(listingForm.controls.brand.touched || listingForm.controls.brand.dirty) && listingForm.controls.brand.errors?.required">
            * Field Required
          </mat-error>
        </p>
        <mat-autocomplete #brand="matAutocomplete">
          <mat-option *ngFor="let option of filteredBrands | async" [value]="option.name">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </div>
      <div class="form-group">
        <label class="h6" for="model">Model</label>
        <input mdbInput type="text" class="form-control" id="model" formControlName="model">
        <mat-error
          *ngIf="(listingForm.controls.model.dirty || listingForm.controls.model.touched) && listingForm.controls.model.errors?.required">
          * Field Required
        </mat-error>
      </div>
      <div class="form-group">
        <label class="h6" for="description">Listing Description</label>
        <p class="text-muted">Be as detailed as possible and include all brands and models.
          <br>This is required for rental claims
        </p>
        <textarea type="text" id="description" class="form-control md-textarea" rows="3" mdbInput
          formControlName="description"></textarea>
        <mat-error
          *ngIf="(listingForm.controls.description.touched || listingForm.controls.description.dirty) && listingForm.controls.description.errors?.required">
          * Field Required
        </mat-error>
      </div>
      <div class="form-group">
        <label class="h6" for="includedAccessories">Included Accessories</label>
        <p class="text-muted">Separate with comma.</p>
        <textarea type="text" id="includedAccessories" class="form-control md-textarea" rows="3" mdbInput
          formControlName="includedAccessories"></textarea>
        <mat-error
          *ngIf="(listingForm.controls.includedAccessories.touched || listingForm.controls.includedAccessories.dirty) && listingForm.controls.includedAccessories.errors?.required">
          * Field Required
        </mat-error>
      </div>
      <app-addon [(addons)]="addons"></app-addon>
      <div class="form-group">
        <label class="h6">Listing Photos</label>
        <p class="text-muted">You must upload at least one image.</p>
        <div class="row">
          <div class="col-xs">
            <app-file-drop [imagePath]="imagePath1" fileId="image1" (upload)="onImageUploadChange($event)">
            </app-file-drop>
          </div>
          <div class="col-xs">
            <app-file-drop [imagePath]="imagePath2" fileId="image2" (upload)="onImageUploadChange($event)">
            </app-file-drop>
          </div>
          <div class="col-xs">
            <app-file-drop [imagePath]="imagePath3" fileId="image3" (upload)="onImageUploadChange($event)">
            </app-file-drop>
          </div>
          <div class="col-xs">
            <app-file-drop [imagePath]="imagePath4" fileId="image4" (upload)="onImageUploadChange($event)">
            </app-file-drop>
          </div>
          <div class="col-xs">
            <app-file-drop [imagePath]="imagePath5" fileId="image5" (upload)="onImageUploadChange($event)">
            </app-file-drop>
          </div>
        </div>
        <p class="mt-s48 text-muted">Drag and drop your photos to change the order. Your first photo is what renters
          will see when browsing so make sure it represents your listing.</p>
      </div>
      <div class="form-group">
        <label class="h6" for="replacementValue">Replacement Value</label>
        <div class="d-flex">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input mdbInput type="number" class="form-control" id="replacementValue" formControlName="replacementValue">
        </div>
        <mat-error
          *ngIf="(listingForm.controls.replacementValue.dirty || listingForm.controls.replacementValue.touched) && listingForm.controls.replacementValue.errors?.required">
          * Field Required
        </mat-error>
      </div>
      <div class="form-group">
        <label class="h6" for="province">Province / Territory</label>
        <select formControlName="province" id="province" class="custom-select" (change)="onProvinceChange()">
          <option selected [ngValue]="null">Choose your province or territory</option>
          <option *ngFor="let option of provinceOptions" [value]="option.id">{{option.name}}</option>
        </select>
        <mat-error *ngIf="listingForm.controls.province.touched && listingForm.controls.province.errors?.required">
          * Field Required
        </mat-error>
      </div>
      <div *ngIf="showCity" class="form-row">
        <div class="form-group col-md-6">
          <label class="h6" for="city">City</label>
          <input id="city" mdbInput type="text" class="form-control" formControlName="city" [matAutocomplete]="city">
          <p>
            <mat-error
              *ngIf="(listingForm.controls.city.dirty || listingForm.controls.city.touched) && listingForm.controls.city.errors?.match">
              * Choose only cities in the list
            </mat-error>
          </p>
          <p>
            <mat-error
              *ngIf="(listingForm.controls.city.touched || listingForm.controls.city.dirty) && listingForm.controls.city.errors?.required">
              * Field Required
            </mat-error>
          </p>
          <mat-autocomplete #city="matAutocomplete">
            <mat-option *ngFor="let option of filteredCities | async" [value]="option.name">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="form-group col-md-6">
          <label class="h6" for="postalCode">Postal Code</label>
          <input mdbInput type="text" class="form-control" id="postalCode" formControlName="postalCode">
          <p>
            <mat-error
              *ngIf="(listingForm.controls.postalCode.touched || listingForm.controls.postalCode.dirty) && listingForm.controls.postalCode.errors?.required">
              * Field Required
            </mat-error>
          </p>
          <p>
            <mat-error
              *ngIf="(listingForm.controls.postalCode.touched || listingForm.controls.postalCode.dirty) && listingForm.controls.postalCode.errors?.pattern">
              * Enter a valid postal code
            </mat-error>
          </p>
        </div>
      </div>
      <div class="form-group">
        <label class="h6" for="dailyRentalPrice">Daily Rental Price</label>
        <div class="d-flex">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input mdbInput type="number" class="form-control" id="dailyRentalPrice" formControlName="dailyRentalPrice">
        </div>
        <mat-error
          *ngIf="(listingForm.controls.dailyRentalPrice.dirty || listingForm.controls.dailyRentalPrice.touched) && listingForm.controls.dailyRentalPrice.errors?.required">
          * Field Required
        </mat-error>
      </div>
      <div class="form-group">
        <p class="h5">Cancellation</p>
        <p class="h6">All rentals are subject to 24 hours grace period.<br />
          This provides full refunds for cancellations within 24 hours of rental.</p>
        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="veryFlexible" value="veryFlexible" name="cancellation"
            mdbInput formControlName="cancellation">
          <label class="custom-control-label" for="veryFlexible">
            <p class="h6">Very Flexible</p>
            <p class="text-muted">100% refund for rentals cancelled 24hrs prior to pickup date
              Cancellations submitted less then 24hrs prior to pick up time are
              non refundable.</p>
          </label>
        </div>

        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="flexible" value="flexible" name="cancellation" mdbInput
            formControlName="cancellation">
          <label class="custom-control-label" for="flexible">
            <p class="h6">Flexible</p>
            <p class="text-muted">100% refund for rentals cancelled 3 days prior to pickup date
              Or 50% refund if cancelled 24hrs prior to pick up date.
              Cancellations submitted less then 24hrs prior to pick up time are
              non refundable.</p>
          </label>
        </div>

        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="moderate" value="moderate" name="cancellation" mdbInput
            formControlName="cancellation">
          <label class="custom-control-label" for="moderate">
            <p class="h6">Moderate</p>
            <p class="text-muted">100% refund for rentals cancelled 5days prior to pickup date
              Cancellations submitted less then 5 days prior to pick up date are
              non refundable.</p>
          </label>
        </div>

        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="strict" value="strict" name="cancellation" mdbInput
            formControlName="cancellation">
          <label class="custom-control-label" for="strict">
            <p class="h6">Strict</p>
            <p class="text-muted">100% refund for cancellations made within 2 days of rental,
              if the pick-up date is at least 12 days away.
              50% refund for cancellations made at least 7 days before pick-up date.
              No refunds for cancellations made 6 days prior to pick up date.</p>
          </label>
        </div>
        <mat-error
          *ngIf="listingForm.controls.cancellation.touched && listingForm.controls.cancellation.errors?.required">
          * Field Required
        </mat-error>
      </div>
      <div class="form-group">
        <p class="h6">Visibility</p>
        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="public" [value]="true" name="visibility" mdbInput
            formControlName="visibility">
          <label class="custom-control-label" for="public">
            Public
          </label>
        </div>
        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="private" [value]="false" name="visibility" mdbInput
            formControlName="visibility">
          <label class="custom-control-label" for="private">
            Private
          </label>
          <p class="text-muted">This listing will be private, will not be displayed to the public</p>
        </div>
        <mat-error *ngIf="listingForm.controls.visibility.touched && listingForm.controls.visibility.errors?.required">
          * Field Required
        </mat-error>
      </div>
      <div class="form-group">
        <p class="h6">Blocked dates</p>
        <mat-form-field class="w-75" appearance="outline">
          <mat-label>Enter date range</mat-label>
          <mat-date-range-input [min]="today" [formGroup]="blockRange" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-error *ngIf="blockRange.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="blockRange.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="!isEdit" class="form-group">
        <mat-checkbox [(ngModel)]="agree" [ngModelOptions]="{standalone: true}">
          <span>By signing up as a member, I agree to Camlyst's
            <a class="text-primary">Terms of services</a>,
            <a class="text-primary" (click)="togglePrivacyPolicy($event)">privacy policy</a> and
            <a class="text-primary">community rules</a></span>
        </mat-checkbox>
      </div>
      <!-- Terms, policy and rules -->
      <div class="my-5">
        <app-statements [show]="showPrivacyPolicy" (showChange)="onShowUserPolicy($event)">
          <app-privacy-statements></app-privacy-statements>
        </app-statements>
      </div>
      <button class="w-100 m-0 light-blue darken-3" type="submit" mdbBtn color="light-blue" (click)="submit()"
        [disabled]="!agree && !isEdit">Submit</button>
    </div>
    <div class="ads w-50">
      <div class="my-3 d-flex flex-column align-items-center">
        <img style="width: 100px; height: 100px" src="../../../../assets/protected_dark.png" alt="">
        <p class="h6">Secured</p>
        <p class="text-muted">Free to list</p>
        <p class="text-muted">Get seen by thousands of verified renters</p>
        <p class="text-muted">$2m liability coverage</p>
        <p class="text-muted">Secure payment processing</p>
        <p class="text-muted">Built-in fraud protection</p>
        <p class="text-muted">24 hours customer support</p>
      </div>
      <div class="my-3 d-flex flex-column align-items-center">
        <img style="width: 100px; height: 100px" src="../../../../assets/moneyicon_dark.png" alt="">
        <p class="h6">Earn Money</p>
        <p class="text-muted">List your gear for free</p>
        <p class="text-muted">Start earning money from short-term rentals.</p>
      </div>
      <div class="my-3 d-flex flex-column align-items-center">
        <img style="width: 100px; height: 100px" src="../../../../assets/connect_dark.png" alt="">
        <p class="h6">Build your Community</p>
        <p class="text-muted">Connect with creatives, </p>
        <p class="text-muted">share your gears and services</p>
        <p class="text-muted">with thousands of brands and people</p>
      </div>
    </div>
  </div>
</div>