<div class="my-3">
  <p class="banner">Prices may be negotiable directly with the owner.</p>
  <mdb-card>
    <mdb-card-title class="light-blue darken-2 pt-3 px-3">
      <p class="h4 white-text">${{price}} per day</p>
      <p class="white-text">Week / Weekend Rate</p>
    </mdb-card-title>
    <mdb-card-body>
      <p *ngIf="blockStartDate && blockEndDate" class="h6 text-info">
        <mdb-icon fas icon="info-circle"></mdb-icon>
        This listing is not available between {{blockStartDate}} and {{blockEndDate}}
      </p>
      <div class="d-flex flex-column align-items-center w-100">
        <mat-form-field class="w-75" appearance="outline">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [dateFilter]="rangeFilter" [min]="today" [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date" (dateChange)="onDateChange()">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        <mat-error *ngIf="blockDateError">Dates are not available</mat-error>

      </div>
      <div *ngIf="showInvoice" class="m-2">
        <table class="w-100" mdbTable [small]="true">
          <tbody>
            <tr mdbTableCol>
              <td>{{days}} Day(s) Rental</td>
              <td>${{displayedRates.rentalAmount}}</td>
            </tr>
            <tr mdbTableCol>
              <td>Tax</td>
              <td>${{displayedRates.tax}}</td>
            </tr>
            <tr mdbTableCol>
              <td>
                <p>Refundable Security Deposit</p>
                <p class="info">Security Deposit covers minor damage and overtime. This money will be released back to
                  you
                  after
                  production</p>
              </td>
              <td>${{displayedRates.securityDeposit}}</td>
            </tr>
            <tr mdbTableCol>
              <td>
                <p class="h6">Total</p>
              </td>
              <td>
                <p class="h6">${{displayedRates.total}}</p>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Rental insurance -->
        <!-- <p class="h5">Rental Insurance</p>
        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" [formControl]="insurance" id="yes" value="yes"
            name="insurance" mdbInput>
          <label class="custom-control-label" for="yes">
            <p class="h6">Yes, I have production insurance</p>
            <p class="text-muted">Great News! Looks like you are covered for any accidental damages, we will reach out
              for details.</p>
          </label>
        </div>

        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" [formControl]="insurance" id="need" value="need"
            name="insurance" mdbInput>
          <label class="custom-control-label" for="need">
            <p class="h6">I want to purchase Camlyst's production insurance.</p>
            <p class="text-muted">A representative will be in touch.</p>
          </label>
        </div>

        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" [formControl]="insurance" id="no" value="no" name="insurance"
            mdbInput>
          <label class="custom-control-label" for="no">
            <p class="h6">No, I don't have a production insurance.</p>
            <p class="text-muted">A representative will be in touch.</p>
          </label>
        </div> -->

        <mat-checkbox class="my-2" [(ngModel)]="agree" [ngModelOptions]="{standalone: true}">
          <span>I agree to Camlyst's
            <a class="text-primary">Terms of services</a> and
            <a class="text-primary">user's policy</a></span>
        </mat-checkbox>
        <button class="w-100 m-0 light-blue darken-3" type="button" mdbBtn color="light-blue" [disabled]="!agree"
          (click)="submit()">
          Rent now
        </button>
      </div>
      <a [routerLink]="['/inquiryMessage']" [queryParams]="{listingId, receiverId: listingUserId, listingName}">
        <button class="w-100 mx-0 my-3" mdbBtn color="link">Message Owner</button>
      </a>
    </mdb-card-body>
  </mdb-card>
</div>