<ng-container *ngIf="latestMessage">
  <a [routerLink]="['/inbox', thread.thread.id]">
    <mdb-card class="my-2" [class.has-new-msg]="hasNewMessage">
      <mdb-card-body class="row">
        <div class="col-md-5 m-2">
          <div class="d-flex align-items-center justify-space-between">
            <img *ngIf="latestMessage.sender.profilePicture" class="img-fluid rounded-circle z-depth-1"
              [src]="latestMessage.sender.profilePicture" alt="Crew image" />
            <mdb-icon *ngIf="!latestMessage.sender.profilePicture" fas icon="user-circle" size="9x"></mdb-icon>
            <div class="mx-3 d-flex flex-column">
              <p class="h5">{{latestMessage.sender.firstName}}</p>
              <p class="h6 text-muted">{{latestMessageTime}}</p>
            </div>
          </div>
        </div>
        <div class="col-md-4 m-2 d-flex align-items-center">
          <div class="d-flex flex-column">
            <p class="h5">{{itemName}}</p>
            <p class="h6 text-muted text-truncate" style="max-width: 200px;">{{latestMessage.messageText}}</p>
          </div>
        </div>
        <div class=" col-md-2 m-2 d-flex align-items-center">
          <p class="h5" [class.text-success]="status === 'Accepted'" [class.text-warning]="status === 'Pending'">
            {{status}}
          </p>
        </div>
      </mdb-card-body>
    </mdb-card>
  </a>
</ng-container>