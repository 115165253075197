<div class="how-container">
  <div class="container">
    <div class="how-title">
      <p class="h1 text-center">Canada's fastest growing film equipment rental community</p>
    </div>
    <div class="row steps">
      <div class="col-lg-3 step">
        <img src="../../../../../assets/search.png" />
        <div class="details">
          <div class="details__title">Search</div>
          <div class="details__caption">Search for a gear or crew for your next film project.</div>
        </div>
      </div>
      <div class="col-lg-3 step">
        <img src="../../../../../assets/connect.png" />
        <div class="details">
          <div class="details__title">Connect</div>
          <div class="details__caption">Connect and discuss gear rentals and services.</div>
        </div>
      </div>
      <div class="col-lg-3 step">
        <img src="../../../../../assets/book.png" />
        <div class="details">
          <div class="details__title">Book</div>
          <div class="details__caption">Make and receive payments on Camlyst's trusted platform hassle free.</div>
        </div>
      </div>
      <div class="col-lg-3 step">
        <img src="../../../../../assets/pickupgear.png" />
        <div class="details">
          <div class="details__title">Picking up Gear</div>
          <div class="details__caption">Choose a mutually convenient spot to meet up for pick-up and drop off.</div>
        </div>
      </div>
    </div>
  </div>
</div>