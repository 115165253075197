<h2 class="m-3">Bookings</h2>
<div>
  <mat-tab-group mat-stretch-tabs class="my-4" animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        Bookings Received
      </ng-template>
      <ng-template matTabContent>
        <app-inbound-bookings></app-inbound-bookings>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        Bookings Sent
      </ng-template>
      <ng-template matTabContent>
        <app-outbound-bookings></app-outbound-bookings>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>