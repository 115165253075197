import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account/account.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { Booking } from 'src/app/utils/types';

@Component({
  selector: 'app-outbound-bookings',
  templateUrl: './outbound-bookings.component.html',
  styleUrls: ['./outbound-bookings.component.scss'],
})
export class OutboundBookingsComponent implements OnInit {
  bookings!: Booking[];
  constructor(
    private _accountService: AccountService,
    private _bookingService: BookingService
  ) {}

  ngOnInit(): void {
    const userId = this._accountService.currentUser?.name;
    if (userId) {
      this._bookingService.getByCustomerId(userId).subscribe((res) => {
        this.bookings = res.sort(
          (a, b) => Number(b.created) - Number(a.created)
        );
      });
    }
  }
}
