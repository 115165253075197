import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-crew-btn',
  templateUrl: './crew-btn.component.html',
})
export class CrewBtnComponent {
  @Input() direction: 'reverse' | undefined;
  @Input() type: 'new' | 'edit' | undefined;

  constructor() {}
}
