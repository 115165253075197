<mdb-navbar #navbar
  SideClass="{{navClass}} navbar navbar-1 fixed-top navbar-expand-lg navbar-dark scrolling-navbar intro-fixed-nav"
  [containerInside]="false">
  <mdb-navbar-brand>
    <a class="navbar-brand" mat-button routerLink="/" (click)="closeMobileMenu()">
      <img src='../../../assets/main_logo_white.png' />
    </a>
  </mdb-navbar-brand>
  <links>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item waves-light" mdbWavesEffect>
        <a class="nav-link h5-responsive" [routerLink]="['crew']">CREW/TALENTS</a>
      </li>
      <li class="nav-item dropdown" dropdown>
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light h5-responsive"
          mdbWavesEffect>
          RENT GEAR
          <span class="caret"></span>
        </a>
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
          <a class="dropdown-item waves-light" [routerLink]="['listings']" [queryParams]="{keyword: category.name}"
            mdbWavesEffect *ngFor='let category of rentalCategories'>
            {{category.name}}
          </a>
        </div>
      </li>
      <li class="nav-item waves-light" mdbWavesEffect>
        <a class="nav-link h5-responsive" [routerLink]="['my-listings', 'new-listing']">LIST GEAR</a>
      </li>
      <li *ngIf="!isLoggedIn" class="nav-item waves-light" mdbWavesEffect>
        <a class="nav-link h5-responsive" routerLink="/login" (click)="closeMobileMenu()">LOG IN</a>
      </li>
      <li *ngIf="!isLoggedIn" class="nav-item waves-light" mdbWavesEffect>
        <a class="nav-link h5-responsive" routerLink="/signup" (click)="closeMobileMenu()">SIGN UP</a>
      </li>
      <mat-divider *ngIf="isLoggedIn"></mat-divider>
      <li *ngIf="isLoggedIn" class="nav-item waves-light show-mobile dashboard-item" mdbWavesEffect>
        <a class="nav-link h5-responsive" [class.disabled]="isDashboard" [routerLink]="['dashboard']"
          (click)="closeMobileMenu()">DASHBOARD</a>
      </li>
      <li *ngIf="isLoggedIn" class="nav-item waves-light show-mobile dashboard-item" mdbWavesEffect>
        <a class="nav-link h5-responsive" [class.disabled]="isWishlist" [routerLink]="['wishlist']"
          (click)="closeMobileMenu()">WISHLIST</a>
      </li>
      <mat-divider *ngIf="isLoggedIn"></mat-divider>
      <li *ngIf=" isLoggedIn" class="nav-item waves-light show-mobile" mdbWavesEffect>
        <a class="nav-link h5-responsive" routerLink="/" (click)="logout()">LOGOUT</a>
      </li>
      <li *ngIf="isLoggedIn" class="nav-item avatar dropdown hide-mobile" dropdown>
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
          <img [src]="userImage" alt="profile" class="img-fluid rounded-circle z-depth-0">
        </a>
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">
          <a class="dropdown-item waves-light" [class.disabled]="isDashboard" mdbWavesEffect
            [routerLink]="['dashboard']">DASHBOARD</a>
          <a class="dropdown-item waves-light" [class.disabled]="isWishlist" mdbWavesEffect
            [routerLink]="['wishlist']">WISHLIST</a>
          <a class="dropdown-item waves-light" mdbWavesEffect routerLink="/" (click)="logout()">LOGOUT</a>
        </div>
      </li>
    </ul>
  </links>
</mdb-navbar>