import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signup-verification',
  templateUrl: './signup-verification.component.html',
  styleUrls: ['./signup-verification.component.scss'],
})
export class SignupVerificationComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<SignupVerificationComponent>) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {}

  tryAgain(): void {
    // Call api to try again
    this.close();
  }

  close(): void {
    this.dialogRef.close();
  }
}
