import { Observable, Subject } from 'rxjs';
import { Message, Threads } from './../../utils/types';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable, EventEmitter } from '@angular/core';
import { GenericResponse, Thread } from 'src/app/utils/types';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

const baseUrl = `${environment.apiUrl}/thread`;
const signalRBaseUrl = environment.apiUrl.replace('/api/v1', '');

@Injectable({
  providedIn: 'root',
})
export class ThreadService {
  private hubConnection!: HubConnection;
  messageReceived = new EventEmitter<GenericResponse>();
  private newMessage = new Subject<string>();
  connectionEstablished = new EventEmitter<boolean>();

  constructor(public http: HttpClient) {
    this.createConnection();
    this.registerOnServerEvents();
    this.startConnection();
  }

  public create(data: Thread): Promise<GenericResponse> {
    return this.hubConnection.invoke('CreateThreadMessage', data);
  }

  public update(threadId: string, data: { status: number }) {
    const params = new HttpParams().set('threadId', threadId);
    return this.http.patch<{ result: GenericResponse }>(
      `${baseUrl}/update`,
      data,
      {
        params,
      }
    );
  }

  public createMessage(
    threadId: string,
    data: Message
  ): Promise<GenericResponse> {
    return this.hubConnection.invoke('CreateMessage', threadId, data);
  }

  public getThread(threadId: string, skipSpinner = false) {
    const params = new HttpParams().set('id', threadId);
    return this.http.get<{ result: Threads[] }>(`${baseUrl}/getThread`, {
      params,
      ...(skipSpinner && {
        headers: {
          spinner: 'false',
        },
      }),
    });
  }

  private createConnection() {
    const tokenValue = localStorage.getItem('token');
    if (tokenValue) {
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(`${signalRBaseUrl}/hubs/chat`, {
          accessTokenFactory: () => tokenValue,
        })
        .build();
    }
  }

  private registerOnServerEvents(): void {
    this.hubConnection.on('MessageReceived', (data: GenericResponse) => {
      this.messageReceived.emit(data);
    });
    this.hubConnection.on('ShowNewMessage', (threadId: string) => {
      localStorage.setItem(`thread-${threadId}`, 'unread');
      this.newMessage.next(`added-${threadId}`);
    });
  }

  watchMessageStorage(): Observable<any> {
    return this.newMessage.asObservable();
  }

  removeItem(threadId: string) {
    const storage = Object.entries(localStorage);
    const regex = new RegExp(threadId);
    const found = storage.find(([key]) => regex.test(key));
    if (found) {
      localStorage.removeItem(found[0]);
      this.newMessage.next(`removed-${threadId}`);
    }
  }

  private startConnection(): void {
    this.hubConnection
      .start()
      .then(() => {
        this.connectionEstablished.emit(true);
      })
      .catch((err) => {
        setTimeout(() => {
          this.startConnection();
        }, 5000);
      });
  }
}
