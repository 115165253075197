<div>
  <div *ngIf="data.type === 'error'" class="d-flex align-items-center ml-2 pl-3 pr-3 border-left border-danger"
    style="border-width: 4px !important;">
    <mdb-icon fas icon="exclamation-circle" class="red-text mr-2" aria-hidden="true" size="2x"></mdb-icon>
    <div class="text-danger text-wrap w-75">{{data.message}}</div>
    <button type="button" class="close ml-auto" aria-label="Close" (click)="close()">
      <span class="text-light" aria-hidden="true">×</span>
    </button>
  </div>
  <div *ngIf="data.type === 'success'" class="d-flex align-items-center ml-2 pl-3 pr-3 border-left border-success"
    style="border-width: 4px !important;">
    <mdb-icon fas icon="check-circle" class="green-text mr-2" aria-hidden="true" size="2x"></mdb-icon>
    <div class="text-success text-wrap w-75">{{data.message}}</div>
    <button type="button" class="close ml-auto" aria-label="Close" (click)="close()">
      <span class="text-light" aria-hidden="true">×</span>
    </button>
  </div>
</div>