import { AccountService } from 'src/app/services/account/account.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoadingService } from '../services/loading/loading.service';

/**
 * This class is for intercepting http requests. When a request starts, we set the loadingSub property
 * in the LoadingService to true. Once the request completes and we have a response, set the loadingSub
 * property to false. If an error occurs while servicing the request, set the loadingSub property to false.
 */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(
    private _loading: LoadingService,
    private _accountService: AccountService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      this._accountService.getToken() &&
      !request.url.includes('https://camlystsa.blob.core.windows.net/images')
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this._accountService.getToken()}`,
        },
      });
    }
    if (request.headers.get('spinner') === 'false') {
      return next.handle(request);
    }
    this._loading.setLoading(true, request.url);
    return next.handle(request).pipe(
      tap((res) => {
        if (res instanceof HttpResponse) {
          this._decreaseRequests(request.url);
        }
      }),
      catchError((err) => {
        this._decreaseRequests(request.url);
        throw err;
      })
    );
  }

  private _decreaseRequests(requestUrl: string): void {
    this._loading.setLoading(false, requestUrl);
  }
}
