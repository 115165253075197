<h2 mat-dialog-title class="text-center">Verify your account</h2>
<mat-dialog-content class="mat-typography verify-form">
  <form (submit)="submit()">
    <div class="row">
      <mat-form-field class="mx-3" appearance="outline">
        <mat-label>Email</mat-label>
        <input type="email" matInput [formControl]="email" placeholder="Email">
      </mat-form-field>
    </div>
    <button class="w-100 m-0 light-blue darken-3" type="submit" mdbBtn color="light-blue">Submit</button>
  </form>
</mat-dialog-content>