<div class="mx-3 my-4">
  <div *ngIf="favorites && favorites.length === 0" class="my-5 py-5 d-flex justify-content-center">
    <h1 class="mat-display-1">You have no listing in your wishlist.</h1>
  </div>
  <div *ngIf="listings">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-12 my-3" *ngFor="let listing of listings">
        <app-listing-card [listing]="listing"></app-listing-card>
      </div>
    </div>
  </div>
</div>