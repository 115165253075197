<mdb-card>
  <img class="img-fluid" [src]="cardImageUrl" alt="Crew image" />
  <mdb-card-body>
    <mdb-card-title class="d-flex justify-content-between">
      <div>
        <p class="h6 text-truncate">{{crew.user.firstName}} {{crew.user.lastName}}</p>
        <p class="h5 text-truncate">{{crew.companyName}}</p>
      </div>
      <ngb-rating *ngIf="crew.rating" [rate]="crew.rating" [readonly]="true" [max]=5>
        <ng-template let-fill="fill" let-index="index">
          <span class="star" [class.filled]="fill === 100">&#9733;</span>
        </ng-template>
      </ngb-rating>
    </mdb-card-title>
    <mdb-card-text class="text-truncate-container">
      <p>
        {{crew.aboutMe}}
      </p>
    </mdb-card-text>
    <div class="tags">
      <h4 class="mb-0"><strong>Tags</strong></h4>
      <div class="d-flex flex-wrap tag-content">
        <div class="m-2" *ngFor="let tag of crew.crewTag">
          <mdb-badge [pill]="true" color="white"><span class="text-dark">{{tag.description}}</span></mdb-badge>
        </div>
      </div>
    </div>
    <div class="row">
      <a class="mt-1 mx-auto" [routerLink]="['/crew', crew.id]">
        <button class="light-blue darken-3" type="button" color="light-blue" mdbBtn mdbWavesEffect>
          View Profile
        </button>
      </a>
    </div>
  </mdb-card-body>
</mdb-card>