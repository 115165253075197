import { NotificationService } from 'src/app/services/notification/notification.service';
import { ListingsService } from 'src/app/services/listings/listings.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-delete-listing',
  templateUrl: './delete-listing.component.html',
  styleUrls: ['./delete-listing.component.scss'],
})
export class DeleteListingComponent implements OnInit {
  dialogClose!: object;
  reason: FormControl = new FormControl(null);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { listingId: string },
    private _location: Location,
    private _listingService: ListingsService,
    private _notificationService: NotificationService
  ) {
    this.dialogClose = {
      closeFn: this.closeFn.bind(this),
    };
  }

  submit() {
    const listingId = this.data.listingId;
    this.reason.markAsTouched();
    if (listingId) {
      const data = {
        listingId,
        reason: this.reason.value,
      };
      this._listingService.deleteListing(data).subscribe((res) => {
        if (res.isSuccess) {
          this.reason.reset();
          this._notificationService.success(`${res.message}`);
          this.closeFn();
        }
      });
    }
  }

  closeFn() {
    this._location.back();
  }

  ngOnInit(): void {}
}
