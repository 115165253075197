<div class="container">
  <div class="my-3">
    <button type="button" class="close" aria-label="Close" (click)="drawer.close()">
      <span class="text-danger" aria-hidden="true">×</span>
    </button>
    <div>
      <p class="h5">
        {{crewData.name}}
      </p>
      <p class="h6 text-muted">{{crewData.name}} has 24 hours to respond to your message.</p>
    </div>
  </div>
  <div class="mt-3">
    <mat-form-field appearance="outline">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [min]="today" [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date">
        <input matEndDate formControlName="end" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
      <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
      <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
    </mat-form-field>
    <div class="form-row">
      <div class="col-xs-3">
        <label for="rate" class="col-form-label">Agreed Day rate</label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input id="rate" type="text" class="form-control" aria-label="Amount (to the nearest dollar)">
        </div>
      </div>
      <div class="col-xs-3">
        <label for="total" class="col-form-label">Total Amount agreed</label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input id="total" type="text" class="form-control" aria-label="Amount (to the nearest dollar)">
        </div>
      </div>
    </div>
    <div class="form-group">
      <mat-checkbox [(ngModel)]="agreeDeposit" [ngModelOptions]="{standalone: true}">
        <span>Yes, I will pay a security deposit</span>
      </mat-checkbox>
      <p class="text-muted">Security deposit is 30% of the total aagreed amounnt,
        <br> this money will be refunded 48 hours after booking completion
        <br> or will be used to settle overtime or related claims.
      </p>
    </div>
    <div class="form-group">
      <mat-checkbox [(ngModel)]="agreeTerms" [ngModelOptions]="{standalone: true}">
        <span>I agree to Camlyst's
          <a class="text-primary">terms and conditions</a> and
          <a class="text-primary">user's policy</a></span>.
      </mat-checkbox>
    </div>
    <button class="w-100 m-0 light-blue darken-3" type="button" mdbBtn color="light-blue"
      [disabled]="!agreeDeposit || !agreeTerms">Submit</button>
  </div>
</div>