import { BookingInviteFormComponent } from './../../components/booking-invite-form/booking-invite-form.component';
import { DeleteListingComponent } from './../../components/delete-listing/delete-listing.component';
import { VerifyEmailFormComponent } from './../../components/verify-email-form/verify-email-form.component';
import { Router, ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SignupComponent } from 'src/app/components/signup/signup.component';
import { ComponentType } from '@angular/cdk/portal';
import { SignupVerificationComponent } from 'src/app/components/signup-verification/signup-verification.component';
import { LoginComponent } from 'src/app/components/login/login.component';
import { ForgotPasswordComponent } from 'src/app/components/forgot-password/forgot-password.component';
import { BookingInviteReviewComponent } from 'src/app/components/booking-invite-review/booking-invite-review.component';
import { BookingChangeReviewComponent } from 'src/app/components/booking-change-review/booking-change-review.component';

@Component({
  template: '',
})
export class ModalRouteEntryComponent {
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {
    switch (this.route.snapshot.url[0].path) {
      case 'login':
        this.openDialog(LoginComponent);
        break;
      case 'signup':
        this.openDialog(SignupComponent);
        break;
      case 'signup-success':
        this.openDialog(SignupVerificationComponent);
        break;
      case 'forgot-password':
        this.openDialog(ForgotPasswordComponent);
        break;
      case 'resetPassword':
        this.openDialog(ForgotPasswordComponent, {
          data: { code: this.route.snapshot.url[1].path },
        });
        break;
      case 'verifyEmail':
        this.openDialog(VerifyEmailFormComponent, {
          data: { code: this.route.snapshot.url[1].path },
        });
        break;
      case 'delete':
        this.openDialog(DeleteListingComponent, {
          data: { listingId: this.route.snapshot.url[1].path },
        });
        break;
      case 'booking-invite':
        this.openDialog(BookingInviteFormComponent);
        break;
      case 'booking-invite-review':
        this.openDialog(BookingInviteReviewComponent);
        break;
      case 'booking-change-review':
        this.openDialog(BookingChangeReviewComponent);
        break;
      default:
        // Future error page: Go to landing page for now
        this.router.navigate(['']);
    }
  }

  openDialog(component: ComponentType<unknown>, data?: object): void {
    const dialogRef = this.dialog.open(component, {
      maxHeight: '90vh',
      ...data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.closeFn) {
        result.closeFn();
      } else {
        this.router.navigate(['../'], { relativeTo: this.route });
      }
    });
  }
}
