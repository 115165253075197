<!-- Footer -->
<footer class="page-footer font-small pt-4">

  <!-- Footer Links -->
  <div class="container text-center text-md-left">
    <!-- Footer links -->
    <div class="row text-center text-md-left mt-3 pb-3">

      <hr class="w-100 clearfix d-md-none">

      <!-- Grid column -->
      <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
        <h3 class="text-uppercase mb-4 font-weight-bold">Company</h3>
        <p>
          <a href="#!">About us</a>
        </p>
        <p>
          <a href="#!">Privacy policy</a>
        </p>
        <p>
          <a href="#!">Terms of service</a>
        </p>
        <p>
          <a href="#!">Community rules</a>
        </p>
        <p>
          <a href="#!">Trust and safety</a>
        </p>
      </div>
      <!-- Grid column -->

      <hr class="w-100 clearfix d-md-none">

      <!-- Grid column -->
      <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
        <h3 class="text-uppercase mb-4 font-weight-bold">Useful Links</h3>
        <p>
          <a href="#!">Listing gear</a>
        </p>
        <p>
          <a href="#!">Renting gear</a>
        </p>
        <p>
          <a href="#!">Joining crew</a>
        </p>
        <p>
          <a href="#!">Insurance</a>
        </p>
        <p>
          <a href="#!">FAQ</a>
        </p>
      </div>

      <!-- Grid column -->
      <hr class="w-100 clearfix d-md-none">

      <!-- Grid column -->
      <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
        <h3 class="text-uppercase mb-4 font-weight-bold">Support</h3>
        <p>
          <a href="#!">Live chat</a>
        </p>
        <p>
          <a href="#!">Contact us</a>
        </p>
        <p>
          <a href="#!">Social media</a>
        </p>
      </div>
      <!-- Grid column -->

    </div>
    <!-- Footer links -->

    <hr>

    <!-- Grid row -->
    <div class="row justify-content-end">

      <!-- Grid column -->
      <div class="col-md-7 col-lg-8">

        <!--Copyright-->
        <p class="text-center text-md-right">&copy; 2020 Copyright:
          <a>
            <strong>Camlyst</strong>
          </a>
        </p>

      </div>
      <!-- Grid column -->
    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

</footer>
<!-- Footer -->