import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account/account.service';
import { LocationService } from 'src/app/services/location/location.service';
import { Place } from 'src/app/utils/types';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { BlobStorageService } from 'src/app/services/blogStorage/blob-storage.service';
import { AutocompleteValidators } from 'src/app/utils/autocomplete.validators';
import { startWith, map } from 'rxjs/operators';
import { Category, Brand, Addon } from './../../../../utils/types';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ListingsService } from 'src/app/services/listings/listings.service';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { PostalCodePattern } from 'src/app/utils/validator-patterns';
import * as moment from 'moment';

@Component({
  selector: 'app-listing-form',
  templateUrl: './listing-form.component.html',
  styleUrls: ['./listing-form.component.scss'],
})
export class ListingFormComponent implements OnInit {
  userId: string | undefined;
  listingId!: string;
  listingForm: FormGroup;
  categories!: Category[];
  showCategory = false;
  showCity = false;
  brands!: Brand[];
  filteredBrands!: Observable<Brand[]>;
  addons: Addon[] = [];
  cities!: Place[];
  filteredCities!: Observable<Place[]>;
  provinceOptions!: Place[];
  isEdit = false;
  agree = false;
  showPrivacyPolicy = false;

  image1!: File | null;
  image2!: File | null;
  image3!: File | null;
  image4!: File | null;
  image5!: File | null;

  imageURL1!: string | null;
  imageURL2!: string | null;
  imageURL3!: string | null;
  imageURL4!: string | null;
  imageURL5!: string | null;

  // Paths used for editing the form
  imagePath1!: SafeUrl;
  imagePath2!: SafeUrl;
  imagePath3!: SafeUrl;
  imagePath4!: SafeUrl;
  imagePath5!: SafeUrl;

  imageCountArray: Set<string> = new Set();
  today: Date = new Date();
  blockRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  constructor(
    private _listingService: ListingsService,
    private _blobStorage: BlobStorageService,
    private _notificationService: NotificationService,
    private _locationService: LocationService,
    private _accountService: AccountService,
    private router: Router,
    private _route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
    this.listingForm = this._generateForm();
    this._route.params.subscribe((params) => {
      this.listingId = params.listingId;
    });
  }

  ngOnInit(): void {
    this.userId = this._accountService?.currentUser?.name;
    this._listingService.getCategories().subscribe((res) => {
      this.categories = res?.result?.data;
      // Patch listing form if this is an edit
      if (this.listingId) {
        this._patchListing();
      }
    });

    this._locationService.provinces().subscribe((res) => {
      this.provinceOptions = res.result;
    });

    this.filteredCities = this.listingForm.controls.city.valueChanges.pipe(
      startWith(''),
      map((value: string) =>
        value.length >= 1 ? this._filterCities(value) : []
      )
    );

    this.filteredBrands = this.listingForm.controls.brand.valueChanges.pipe(
      startWith(''),
      map((value: string) =>
        value.length >= 1 ? this._filterBrands(value) : []
      )
    );
  }

  private _generateForm() {
    return new FormGroup({
      title: new FormControl(null, Validators.required),
      category: new FormControl(null, Validators.required),
      brand: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          AutocompleteValidators.valueSelected(
            this._brandValidatorFn.bind(this)
          ),
        ])
      ),
      model: new FormControl(null, Validators.required),
      description: new FormControl(null, Validators.required),
      includedAccessories: new FormControl(null),
      replacementValue: new FormControl(null, Validators.required),
      postalCode: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(PostalCodePattern),
        ])
      ),
      city: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          AutocompleteValidators.valueSelected(
            this._cityValidatorFn.bind(this)
          ),
        ])
      ),
      province: new FormControl(null, Validators.required),
      dailyRentalPrice: new FormControl(null, Validators.required),
      cancellation: new FormControl(null, Validators.required),
      visibility: new FormControl(true, Validators.required),
    });
  }

  private _patchListing(): void {
    this._listingService.getListing(this.listingId).subscribe((res) => {
      if (res.result) {
        this.isEdit = true;
        const {
          category,
          brand,
          model,
          addons,
          province,
          city,
          imageUrl1,
          imageUrl2,
          imageUrl3,
          imageUrl4,
          imageUrl5,
          blockStartDate,
          blockEndDate,
        } = res.result;
        this.addons = addons;
        const data = {
          ...res.result,
          category: category.name,
          brand: brand.name,
          model: model.name,
          province: province.id,
          city: city.name,
        };
        if (province && category) {
          this.showCity = true;
          this.showCategory = true;
          this._locationService
            .cities(province.id)
            .toPromise()
            .then((citiesRes) => {
              this.cities = citiesRes.result;
            })
            .then(() => {
              const foundCategory = this.categories.find(
                (cat) => cat.name === category.name
              );
              this.brands = foundCategory?.brands || [];
            })
            .then(() => {
              this.listingForm.patchValue(data);
            });
        } else {
          this.listingForm.patchValue(data);
        }
        if (blockStartDate && blockEndDate) {
          this.blockRange.patchValue({
            start: moment(new Date(blockStartDate)),
            end: moment(new Date(blockEndDate)),
          });
        }
        // pass the file to the file-drop component
        if (imageUrl1) {
          this.imageCountArray.add('image1');
          this.imageURL1 = imageUrl1;
          this._setImagePath(imageUrl1, 1);
        }
        if (imageUrl2) {
          this.imageCountArray.add('image2');
          this.imageURL2 = imageUrl2;
          this._setImagePath(imageUrl2, 2);
        }
        if (imageUrl3) {
          this.imageCountArray.add('image3');
          this.imageURL3 = imageUrl3;
          this._setImagePath(imageUrl3, 3);
        }
        if (imageUrl4) {
          this.imageCountArray.add('image4');
          this.imageURL4 = imageUrl4;
          this._setImagePath(imageUrl4, 4);
        }
        if (imageUrl5) {
          this.imageCountArray.add('image5');
          this.imageURL5 = imageUrl5;
          this._setImagePath(imageUrl5, 5);
        }
      }
    });
  }

  private _setImagePath(imageUrl: string, position: number): void {
    this._blobStorage.getFile(imageUrl).subscribe((fileBlob) => {
      const objectURL = URL.createObjectURL(fileBlob);
      if (position === 1) {
        this.imagePath1 = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      }
      if (position === 2) {
        this.imagePath2 = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      }
      if (position === 3) {
        this.imagePath3 = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      }
      if (position === 4) {
        this.imagePath4 = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      }
      if (position === 5) {
        this.imagePath5 = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      }
    });
  }

  private _filterBrands(value: string): Brand[] {
    const filterValue = value.toLowerCase();
    return this.brands.filter((option: Brand) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filterCities(value: string): Place[] {
    const filterValue = value.toLowerCase();
    return this.cities.filter((option: Place) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _brandValidatorFn(): Brand[] {
    return this.brands;
  }

  private _cityValidatorFn(): Place[] {
    return this.cities;
  }

  onCategoryChange(): void {
    this.listingForm.controls.brand.setValue('');
    const category = this.listingForm.controls.category.value;
    this.showCategory = category ? true : false;
    const foundCategory = this.categories.find((cat) => cat.name === category);
    this.brands = foundCategory?.brands || [];
  }

  onProvinceChange(): void {
    this.listingForm.controls.city.setValue('');
    const province = this.listingForm.controls.province.value;
    this.showCity = province ? true : false;
    this._locationService.cities(province).subscribe((res) => {
      this.cities = res.result;
    });
  }

  onImageUploadChange(fileObject: any): void {
    switch (fileObject.fileId) {
      case 'image1':
        if (fileObject.file === null) {
          this.imageCountArray.delete('image1');
        } else {
          this.imageCountArray.add('image1');
        }
        this.image1 = fileObject.file;
        break;
      case 'image2':
        if (fileObject.file === null) {
          this.imageCountArray.delete('image2');
        } else {
          this.imageCountArray.add('image2');
        }
        this.image2 = fileObject.file;
        break;
      case 'image3':
        if (fileObject.file === null) {
          this.imageCountArray.delete('image3');
        } else {
          this.imageCountArray.add('image3');
        }
        this.image3 = fileObject.file;
        break;
      case 'image4':
        if (fileObject.file === null) {
          this.imageCountArray.delete('image4');
        } else {
          this.imageCountArray.add('image4');
        }
        this.image4 = fileObject.file;
        break;
      case 'image5':
        if (fileObject.file === null) {
          this.imageCountArray.delete('image5');
        } else {
          this.imageCountArray.add('image5');
        }
        this.image5 = fileObject.file;
        break;
      default:
        break;
    }
  }

  async onImageUpload(
    file: File,
    imageNum: string,
    imageUrl?: string | null
  ): Promise<string | null> {
    const uid = uuidv4();
    const blobName = imageUrl
      ? imageUrl
          .split('https://kreativecitusa.blob.core.windows.net/images/')
          .pop()
          ?.split('?')
          .shift()
      : `${uid}-${this.userId}-${imageNum}-listing-picture`;
    if (file && blobName) {
      const res = await this._blobStorage.uploadFile(file, blobName);
      if (res.isSuccess) {
        return res.imageUrl;
      } else {
        // Upload Error
        this._notificationService.error(res.message);
        return null;
      }
    }
    return null;
  }

  async onImageDelete(imageUrl: string | null): Promise<string | null> {
    const blobName = imageUrl
      ? imageUrl
          .split('https://kreativecitusa.blob.core.windows.net/images/')
          .pop()
          ?.split('?')
          .shift()
      : null;
    if (blobName) {
      const res = await this._blobStorage.deleteFile(blobName);
      if (res.isSuccess) {
        return null;
      } else {
        // Delete Error
        this._notificationService.error(res.message);
        return imageUrl;
      }
    }
    return null;
  }
  async submit(): Promise<void> {
    this.listingForm.markAllAsTouched();
    if (this.listingForm.valid && this.userId) {
      if (this.imageCountArray.size === 0) {
        this._notificationService.error('You must upload at least one image');
        return;
      }
      if (typeof this.image1 !== 'undefined') {
        if (this.image1) {
          this.imageURL1 = await this.onImageUpload(
            this.image1,
            'image-01',
            this.imageURL1
          );
        } else {
          this.imageURL1 = await this.onImageDelete(this.imageURL1);
        }
      }
      if (typeof this.image2 !== 'undefined') {
        if (this.image2) {
          this.imageURL2 = await this.onImageUpload(
            this.image2,
            'image-02',
            this.imageURL2
          );
        } else {
          this.imageURL2 = await this.onImageDelete(this.imageURL2);
        }
      }
      if (typeof this.image3 !== 'undefined') {
        if (this.image3) {
          this.imageURL3 = await this.onImageUpload(
            this.image3,
            'image-03',
            this.imageURL3
          );
        } else {
          this.imageURL3 = await this.onImageDelete(this.imageURL3);
        }
      }
      if (typeof this.image4 !== 'undefined') {
        if (this.image4) {
          this.imageURL4 = await this.onImageUpload(
            this.image4,
            'image-04',
            this.imageURL4
          );
        } else {
          this.imageURL4 = await this.onImageDelete(this.imageURL4);
        }
      }
      if (typeof this.image5 !== 'undefined') {
        if (this.image5) {
          this.imageURL5 = await this.onImageUpload(
            this.image5,
            'image-05',
            this.imageURL5
          );
        } else {
          this.imageURL5 = await this.onImageDelete(this.imageURL5);
        }
      }
      const data = {
        ...this.listingForm.value,
        blockStartDate: this.blockRange.value.start
          ? this.blockRange.value.start.format('ddd, MMM DD, YYYY')
          : null,
        blockEndDate: this.blockRange.value.end
          ? this.blockRange.value.end?.format('ddd, MMM DD, YYYY')
          : null,
        imageUrl1: this.imageURL1,
        imageUrl2: this.imageURL2,
        imageUrl3: this.imageURL3,
        imageUrl4: this.imageURL4,
        imageUrl5: this.imageURL5,
        ...(this.isEdit && { id: this.listingId }),
        addons: this.addons,
        userId: this.userId,
      };
      if (this.isEdit) {
        this._listingService.updateListing(data).subscribe((res) => {
          if (res.isSuccess) {
            this._notificationService.success(res.message);
            this.router.navigate(['my-listings']);
          }
        });
      } else {
        this._listingService.createListing(data).subscribe((res) => {
          if (res.isSuccess) {
            this._notificationService.success(res.message);
            this.router.navigate(['my-listings']);
          }
        });
      }
    }
  }

  togglePrivacyPolicy(evt: Event) {
    this.showPrivacyPolicy = !this.showPrivacyPolicy;
    evt.preventDefault();
  }

  onShowUserPolicy(evt: boolean) {
    this.showPrivacyPolicy = evt;
  }
}
