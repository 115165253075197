<div class="container">
  <div class="my-3">
    <div class="d-flex justify-content-end">
      <button type="button" mdbBtn color="link" (click)="getMessages()">
        <mdb-icon fas icon="sync" class="mr-1"></mdb-icon>
        <span class="text-capitalize">Refresh</span>
      </button>
    </div>
    <div class="threads">
      <div *ngIf="threads.length === 0" class="my-5 py-5 d-flex justify-content-center no-item">
        <p class="mat-display-1 text-center">You have no messages</p>
      </div>
      <ng-container *ngIf="threads && threads.length > 0">
        <app-inbox-card *ngFor="let thread of threads" [thread]="thread">
        </app-inbox-card>
      </ng-container>
    </div>
  </div>
</div>