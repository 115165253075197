import { NotificationService } from 'src/app/services/notification/notification.service';
import { AccountService } from 'src/app/services/account/account.service';
import { PageEvent } from '@angular/material/paginator';
import { Crew, Review, UserProfile } from './../../../utils/types';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CrewService } from 'src/app/services/crew/crew.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import getVideoId from 'get-video-id';
import { FavoritesService } from 'src/app/services/favorites/favorites.service';

@Component({
  selector: 'app-view-crew',
  templateUrl: './view-crew.component.html',
  styleUrls: ['./view-crew.component.scss'],
})
export class ViewCrewComponent implements OnInit {
  userRole?: string;
  userId!: string;
  crewUserId!: string;
  crewId!: string;
  crew!: Crew;
  crewUser!: UserProfile;
  videoUrl: SafeUrl | undefined;
  activeReviews: Review[] = [];
  isFavorite!: boolean;
  favoriteId?: string;
  userIsOwner!: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _crewService: CrewService,
    private sanitizer: DomSanitizer,
    private _accountService: AccountService,
    private _favoriteService: FavoritesService,
    private _notificationService: NotificationService
  ) {
    this._accountService.getProfile(true).subscribe((res) => {
      if (res.isSuccess) {
        this.userRole = res.user.role;
        this.userId = res.user.id;
        this._favoriteService.getFavorites(this.userId).subscribe((favRes) => {
          const favorites = favRes.result;
          const foundFavorite = favorites.find(
            (fav) => fav.crewId === this.crewId
          );
          this.favoriteId = foundFavorite?.id;
          this.isFavorite = foundFavorite ? true : false;
        });
      }
    });
    this._route.params.subscribe((params) => {
      this.crewId = params.crewId;
    });
  }

  ngOnInit(): void {
    if (!this.crewId) {
      return;
    }
    this._crewService.getCrew(this.crewId.toString()).subscribe((res) => {
      this.crew = res.result;
      this.userIsOwner = this.crew.userId === this.userId;
      this.crewUserId = this.crew.userId;
      if (this.crew.linkToReel) {
        this.videoUrl = this._getYoutubeEmbedLink(this.crew.linkToReel);
      }
      if (this.crew.reviews) {
        // 2 reviews per page by default
        this.activeReviews = this.crew.reviews.slice(0, 2);
      }
    });
  }

  _getYoutubeEmbedLink(url: string): SafeUrl | undefined {
    const { id, service } = getVideoId(url);
    if (service === 'youtube') {
      const videoUrl = `https://www.youtube.com/embed/${id}`;
      return this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
    }
    if (service === 'vimeo') {
      const videoUrl = `https://player.vimeo.com/video/${id}`;
      return this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
    }
    return;
  }

  // Reviews Pagination controls
  onPageChange(evt: PageEvent) {
    const { pageIndex, pageSize } = evt;
    const i = pageIndex * pageSize;
    const j = (pageIndex + 1) * pageSize;
    this.activeReviews = this.crew.reviews.slice(i, j);
  }

  addToFavorites() {
    this._favoriteService
      .create({
        userId: this.userId,
        favoritestype: 1, // crew
        crewId: this.crewId,
      })
      .subscribe((res) => {
        const { result } = res;
        if (result.isSuccess) {
          this.favoriteId = result.insertedId;
          this._notificationService.success(result.message);
          this.isFavorite = true;
        } else {
          this._notificationService.error(result.message);
        }
      });
  }

  removeFromFavorites() {
    if (this.favoriteId) {
      this._favoriteService.removeFavorite(this.favoriteId).subscribe((res) => {
        if (res.isSuccess) {
          this._notificationService.success(res.message);
          this.isFavorite = false;
          this.favoriteId = undefined;
        } else {
          this._notificationService.error(res.message);
        }
      });
    }
  }

  copy() {
    const path = window.location.href;
    navigator.clipboard.writeText(path);
    this._notificationService.success('The link to this crew has been copied!');
  }
}
