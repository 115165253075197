import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { UserProfile } from 'src/app/utils/types';
import { AccountService } from 'src/app/services/account/account.service';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { BlobStorageService } from 'src/app/services/blogStorage/blob-storage.service';
import { VerificationService } from 'src/app/services/verification/verification.service';
import { VerificationStatus } from 'src/app/utils/enums';
import { GovtIdSubmitDialogComponent } from './govt-id-submit-dialog/govt-id-submit-dialog.component';

const ID_IMAGE_01 = '../../../assets/id_image_01.png';
const ID_IMAGE_02 = '../../../assets/id_image_02.png';
const ID_IMAGE_03 = '../../../assets/id_image_03.png';

const ALLOWED_FILETYPES = ['image/png', 'image/jpeg'];

@Component({
  selector: 'app-govt-id',
  templateUrl: './govt-id.component.html',
  styleUrls: ['./govt-id.component.scss'],
})
export class GovtIdComponent implements OnInit {
  idImage01: string = ID_IMAGE_01;
  idImage02: string = ID_IMAGE_02;
  idImage03: string = ID_IMAGE_03;
  userProfile!: UserProfile;
  idType = new FormControl(null, Validators.required);
  idImage01Uploaded = false;
  idImage02Uploaded = false;
  idImage03Uploaded = false;

  constructor(
    private _accountService: AccountService,
    private blobStorage: BlobStorageService,
    private notificationService: NotificationService,
    private _verificationService: VerificationService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this._accountService.getProfile().subscribe((res) => {
      if (res.isSuccess) {
        this.userProfile = res.user;
      }
    });
  }

  ngOnInit(): void {}

  onImageUpload(event: any) {
    const files = event.target.files as FileList;
    const targetName = event.target.name;
    const blobName = `${this.userProfile.id}-govt-id-picture-${targetName}`;
    if (files && ALLOWED_FILETYPES.includes(files[0].type)) {
      this.blobStorage.uploadFile(files[0], blobName).then((res) => {
        if (res.isSuccess) {
          switch (targetName) {
            case 'idImage01':
              this.idImage01 = res.imageUrl || ID_IMAGE_01;
              this.idImage01Uploaded = true;
              break;
            case 'idImage02':
              this.idImage02 = res.imageUrl || ID_IMAGE_02;
              this.idImage02Uploaded = true;
              break;
            case 'idImage03':
              this.idImage03 = res.imageUrl || ID_IMAGE_03;
              this.idImage03Uploaded = true;
              break;
            default:
              break;
          }
          this.notificationService.success(res.message);
        } else {
          // Upload Error
          this.notificationService.error(res.message);
        }
      });
    } else {
      // Error for wrong file type
      this.notificationService.error('File type not allowed');
    }
  }

  submit(): void {
    if (
      this.idType.valid &&
      this.idImage01Uploaded &&
      this.idImage02Uploaded &&
      this.idImage03Uploaded
    ) {
      const data = {
        userId: this.userProfile.id,
        email: this.userProfile.email,
        idType: this.idType.value,
        imageUrl1: this.idImage01,
        imageUrl2: this.idImage02,
        imageUrl3: this.idImage03,
        status: VerificationStatus.Pending,
      };
      this._verificationService.create(data).subscribe((res) => {
        if (res.status === 201) {
          this.notificationService.success(
            'You have successfully submitted your verification documents'
          );
          this.router.navigate(['/dashboard/verification']);
          setTimeout(() => {
            this.openDialog();
          });
        } else {
          this.notificationService.error('An error occurred');
        }
      });
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(GovtIdSubmitDialogComponent);
  }
}
