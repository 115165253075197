import { NotificationService } from './../../../services/notification/notification.service';
import { PasswordValidators } from 'src/app/utils/password.validators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account/account.service';

@Component({
  selector: 'app-dashboard-password',
  templateUrl: './dashboard-password.component.html',
})
export class DashboardPasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  errors: string[] = [];
  constructor(
    private _accountService: AccountService,
    private _notficationService: NotificationService
  ) {
    this.changePasswordForm = new FormGroup(
      {
        oldPassword: new FormControl(null, Validators.required),
        password: new FormControl(null, Validators.required),
        confirmPassword: new FormControl(null, Validators.required),
      },
      {
        validators: [PasswordValidators.shouldMatch, PasswordValidators.strong],
      }
    );
  }

  ngOnInit(): void {}

  submit() {
    this.changePasswordForm.markAllAsTouched();
    if (this.changePasswordForm.valid) {
      const currentUser = this._accountService.currentUser;
      // Submit to backend
      if (!currentUser) {
        return;
      }
      this._accountService
        .changePassword({
          oldPassword: this.changePasswordForm.value.oldPassword,
          newPassword: this.changePasswordForm.value.password,
        })
        .subscribe((res) => {
          if (res.isSuccess) {
            this._notficationService.success(res.message);
            this.changePasswordForm.reset();
            this.errors = [];
          }
        });
    } else {
      this.errors = this.getErrorMessage();
    }
  }

  private getErrorMessage() {
    const errors = [];
    if (this.changePasswordForm.hasError('shouldMatch')) {
      errors.push('Passwords do not match');
    }
    if (this.changePasswordForm.hasError('strong')) {
      errors.push('Password is not strong enough');
    }
    return errors;
  }
}
