<div class="container">
  <h2 class="m-3">Wishlist</h2>
  <div>
    <mat-tab-group class="my-4" animationDuration="0ms">
      <mat-tab label="Crews">
        <app-crew-wishlist [favorites]="favoriteCrews"></app-crew-wishlist>
      </mat-tab>
      <mat-tab label="Listings">
        <app-listing-wishlist [favorites]="favoriteListings"></app-listing-wishlist>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>