import { NotificationService } from 'src/app/services/notification/notification.service';
import { AccountService } from 'src/app/services/account/account.service';
import { FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-verify-email-form',
  templateUrl: './verify-email-form.component.html',
  styleUrls: ['./verify-email-form.component.scss'],
})
export class VerifyEmailFormComponent implements OnInit {
  email: FormControl = new FormControl(
    null,
    Validators.compose([Validators.email, Validators.required])
  );
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { code: string },
    public dialogRef: MatDialogRef<VerifyEmailFormComponent>,
    private _accountService: AccountService,
    private _notificationService: NotificationService
  ) {}

  ngOnInit(): void {}

  submit() {
    const code = this.data.code;
    this.email.markAsTouched();
    if (code && this.email.valid) {
      const data = {
        code,
        email: this.email.value,
      };
      this._accountService.confirmEmail(data).subscribe((res) => {
        if (res.isSuccess) {
          this.email.reset();
          this._notificationService.success(
            `${res.message}. You can now login`,
            3
          );
          this.close();
        }
      });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
