<h2 class="m-3">Edit Booking</h2>
<div class="m-4">
  <p>Note that changes and requests have to be accepted or declined by the corresponding user. We advise you
    communicate with them before placing a request. You can escalate a declined request by contacting the help center.
  </p>
  <div class="m-3 form">
    <div class="form-group">
      <mat-checkbox [(ngModel)]="cancel" [disabled]="changeDate" [ngModelOptions]="{standalone: true}"
        (change)="checkCancelValue(cancel)">
        <span>Cancel this booking</span>
      </mat-checkbox>
    </div>
    <div *ngIf="cancel" class="ml-4 form-row">
      <div class="col-xs-6 mb-3">
        <label for="cancelReason">Reason for Cancellation</label>
        <select [formControl]="reason" id="cancelReason" class="custom-select">
          <option selected [ngValue]="null">Choose a reason</option>
          <option *ngFor="let option of cancelReasons" [value]="option.value">{{option.value}}</option>
        </select>
      </div>
    </div>
    <div *ngIf="cancel" class="ml-4 form-row">
      <p class="h6 font-weight-bold text-danger">Cancellation policy</p>
      <p class="h6 font-weight-bold text-danger">{{cancelPolicy}}</p>
    </div>
    <div class=" form-group">
      <mat-checkbox [(ngModel)]="changeDate" [disabled]="cancel" [ngModelOptions]="{standalone: true}">
        <span>Change Date</span>
      </mat-checkbox>
    </div>
    <mat-form-field *ngIf="changeDate" class="ml-4" appearance="outline">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [min]="today" [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date">
        <input matEndDate formControlName="end" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
      <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
      <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
    </mat-form-field>

    <div *ngIf="!isCustomer" class="form-row">
      <div class="col-xs-6 mb-3">
        <label for="requestPayment">Request Payment</label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input mdbInput type="text" class="form-control" id="requestPayment" [formControl]="requestPayment">
        </div>
        <mat-error *ngIf="(requestPayment.dirty || requestPayment.touched) && requestPayment.errors?.required">
          * Field Required
        </mat-error>
      </div>
    </div>
    <div *ngIf="isCustomer" class="form-row">
      <div class="col-xs-6 mb-3">
        <label for="requestRefund">Request Refund</label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input mdbInput type="text" class="form-control" id="requestRefund" [formControl]="requestRefund">
        </div>
        <mat-error *ngIf="(requestRefund.dirty || requestRefund.touched) && requestRefund.errors?.required">
          * Field Required
        </mat-error>
      </div>
    </div>

    <div class="form-group">
      <label class="h6" for="notes">Add Notes</label>
      <p class="text-muted">Reason for change of date, payment request or refund</p>
      <textarea type="text" id="notes" class="form-control md-textarea" rows="3" mdbInput
        [formControl]="notes"></textarea>
    </div>

    <button class="m-0 light-blue darken-3" type="button" mdbBtn color="light-blue" (click)="submit()">Submit</button>
  </div>
</div>