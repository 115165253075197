<div class="container" [class.logged-out-container]="!isLoggedIn">
  <div class="my-4">
    <div class="search-form-container row">
      <app-crew-btn *ngIf="userRole" class="col-lg-4 col-md-6 mb-3"
        [type]="(userRole === 'Admin') || (userRole === 'Crew') ? 'edit' : 'new'"></app-crew-btn>
      <form (submit)="onSearch()" class="offset-lg-4 offset-sm-0 col-lg-4 col-md-6 mb-3">
        <p class="h6">Find Crew / Talent near you</p>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">
              <mdb-icon fas icon="search"></mdb-icon>
            </span>
          </div>
          <input mdbInput type="text" class="form-control" placeholder="What are you looking for?" [formControl]="tag"
            [matAutocomplete]="item">
          <mat-autocomplete #item="matAutocomplete">
            <mat-option *ngFor="let option of filteredTags | async" [value]="option.description">
              {{option.description}}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">
              <mdb-icon fas icon="map-marker-alt"></mdb-icon>
            </span>
          </div>
          <input mdbInput type="text" class="form-control" placeholder="Location" [formControl]="city"
            [matAutocomplete]="where">
          <mat-autocomplete #where="matAutocomplete">
            <mat-option *ngFor="let option of filteredCities | async" [value]="option.name">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="input-group mb-3">
          <button class="w-100 m-0 light-blue darken-3" type="submit" mdbBtn color="light-blue"
            (click)="onSearch()">Search</button>
        </div>
      </form>
    </div>
    <div *ngIf="crews && crews.length === 0" class="my-5 py-5 d-flex justify-content-center">
      <h1 class="mat-display-1">No crew was found with your search parameters.</h1>
    </div>
    <div *ngIf="crews">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-12 my-3" *ngFor="let crew of crews">
          <app-crew-card [crew]="crew"></app-crew-card>
        </div>
      </div>
      <div class="row justify-content-center">
        <mat-paginator [hidePageSize]="true" [length]="totalCount" [pageSize]="pageSize" showFirstLastButtons
          (page)="onPageChange($event)">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>