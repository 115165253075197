<mat-dialog-content class="mat-typography">
  <button type="button" class="close" aria-label="Close" mat-dialog-close>
    <span aria-hidden="true">×</span>
  </button>
  <img class="logo mx-auto d-block" src="../../../assets/main_logo.png" alt="kreative city logo">
  <div class="instructions">
    <p class="h3 text-center">Thank you for joining Camlyst !</p>
    <p class="lead text-center">
      One last step! Please complete the sign up by clicking on the verification link sent to your email.
    </p>
    <p class="lead text-center">
      Didn't receive the link? Check your junk folders or
      <a class="text-primary" (click)="tryAgain()"> try again</a>.
    </p>
  </div>
</mat-dialog-content>