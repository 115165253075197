<mdb-card class="m-3">
  <div class="row align-items-center">
    <div *ngIf="profile" class="py-2 col-lg-4 col-md-4 d-flex flex-column align-items-center">
      <img *ngIf="booking.type === 'crew'" class="img-fluid rounded-circle w-100" [src]="profile.picture"
        alt="user image" />
      <img *ngIf="booking.type === 'listing'" class="img-fluid w-100" [src]="profile.picture" alt="user image" />
      <p class="my-2 h5">{{profile.name}}</p>
    </div>
    <div class="col-lg-5 col-md-4 m-4 m-md-0">
      <p class="h5">{{booking.startDate}}</p>
      <p class="h5">{{booking.title}}</p>
      <p *ngIf="isCanceled" class="h5 red-text">Canceled</p>
    </div>
    <div class="col-lg-3 col-md-4 d-flex flex-column">
      <a mdbBtn [routerLink]="['/inbox', booking.threadId]" role="button" color="light-blue"
        class="waves-light light-blue darken-3 my-2 mx-4" mdbWavesEffect>Go to
        Inbox</a>
      <a mdbBtn [routerLink]="['view', booking.id]" role="button" color="light" color="light-blue"
        class="waves-light light-blue darken-3 my-2 mx-4" mdbWavesEffect>View Booking</a>
      <a *ngIf="!isCanceled" mdbBtn [routerLink]="['edit', booking.id]" role="button" color="light-blue"
        class="waves-light light-blue darken-3 my-2 mx-4" mdbWavesEffect>Edit
        Booking</a>
    </div>
  </div>
</mdb-card>