<div class="container">
  <h2 class="m-3">Calendar</h2>
  <ng-container *ngIf="events">
    <div class="m-4">
      <div class="row text-center">
        <div class="col-md-4">
          <div class="btn-group">
            <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay()">
              Previous
            </div>
            <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
              Today
            </div>
            <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay()">
              Next
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <p class="h6">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</p>
        </div>
        <div class="col-md-4">
          <div class="btn-group">
            <div class="btn btn-primary" (click)="setView(CalendarView.Month)"
              [class.active]="view === CalendarView.Month">
              Month
            </div>
            <div class="btn btn-primary" (click)="setView(CalendarView.Week)"
              [class.active]="view === CalendarView.Week">
              Week
            </div>
            <div class="btn btn-primary" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
              Day
            </div>
          </div>
        </div>
      </div>
      <br />
      <div [ngSwitch]="view">
        <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
          [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
          (eventClicked)="handleEvent($event.event)">
        </mwl-calendar-month-view>
        <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
          [refresh]="refresh" (eventClicked)="handleEvent($event.event)">
        </mwl-calendar-week-view>
        <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
          [refresh]="refresh" (eventClicked)="handleEvent($event.event)">
        </mwl-calendar-day-view>
      </div>
      <ul class="my-4 w-50 legend">
        <li class="p-2 outbound">Bookings Sent</li>
        <li class="p-2 inbound">Bookings Received</li>
      </ul>
    </div>
  </ng-container>
</div>