import { FormGroup, FormControl } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.scss'],
})
export class BookingFormComponent implements OnInit {
  @Input() crewData!: {
    id: string;
    name: string;
  };
  @Input() drawer!: MatDrawer;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  today: Date = new Date();
  agreeDeposit = false;
  agreeTerms = false;
  constructor() {}

  ngOnInit(): void {}

  // onDateChange() {}
}
