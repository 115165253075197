<div class="container" [class.logged-out-container]="!isLoggedIn">
  <div class="my-4">
    <div class="row">
      <form (submit)="onSearch()" class="col-lg-4 col-md-6 mb-3">
        <p class="h6">Find Listings near you</p>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">
              <mdb-icon fas icon="search"></mdb-icon>
            </span>
          </div>
          <input mdbInput type="text" class="form-control" placeholder="What are you looking for?"
            [formControl]="keyword" [matAutocomplete]="item">
          <mat-autocomplete #item="matAutocomplete">
            <mat-option *ngFor="let option of filteredKeywords | async" [value]="option.name">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">
              <mdb-icon fas icon="map-marker-alt"></mdb-icon>
            </span>
          </div>
          <input mdbInput type="text" class="form-control" placeholder="Location" [formControl]="city"
            [matAutocomplete]="where">
          <mat-autocomplete #where="matAutocomplete">
            <mat-option *ngFor="let option of filteredCities | async" [value]="option.name">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="input-group mb-3">
          <button class="w-100 m-0 light-blue darken-3" type="submit" mdbBtn color="light-blue">Search</button>
        </div>
      </form>
    </div>
    <div *ngIf="listings && listings.length === 0">
      <div class="d-flex flex-column">
        <p class="h6 text-center">No listings were found with your search parameters. These are some similar listings
        </p>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12 my-3" *ngFor="let similarListing of similarListings">
            <app-listing-card [listing]="similarListing"></app-listing-card>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="listings">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-12 my-3" *ngFor="let listing of listings">
          <app-listing-card [listing]="listing"></app-listing-card>
        </div>
      </div>
      <div class="row justify-content-center">
        <mat-paginator [hidePageSize]="true" [length]="totalCount" [pageSize]="pageSize" showFirstLastButtons
          (page)="onPageChange($event)">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>