const options: {
  [key: string]: { value?: string; name: string }[];
} = {
  gender: [
    {
      value: 'male',
      name: 'Male',
    },
    {
      value: 'female',
      name: 'Female',
    },
    {
      name: 'Prefer not to say',
    },
  ],
  ageBracket: [
    {
      value: '18 – 22',
      name: '18 – 22',
    },
    {
      value: '23 – 28',
      name: '23 – 28',
    },
    {
      value: '29 – 34',
      name: '29 – 34',
    },
    {
      value: '35 – 40',
      name: '35 – 40',
    },
    {
      value: '41 – 46',
      name: '41 – 46',
    },
    {
      value: '47 – 52',
      name: '47 – 52',
    },
    {
      value: '53 – 60',
      name: '53 – 60',
    },
    {
      value: '61 – 70',
      name: '61 – 70',
    },
    {
      value: '71 – 80',
      name: '71 – 80',
    },
  ],
  levelsOfExp: [
    {
      value: 'beginer',
      name: 'Beginer',
    },
    {
      value: 'intermediate',
      name: 'Intermediate',
    },
    {
      value: 'expert',
      name: 'Expert',
    },
  ],
  ads: [
    {
      value: 'Blog post',
      name: 'Blog post',
    },
    {
      value: 'Email',
      name: 'Email',
    },
    {
      value: 'Referral	',
      name: 'Referral	',
    },
    {
      value: 'Search Engine',
      name: 'Search Engine',
    },
    {
      value: 'Social Media',
      name: 'Social Media',
    },
    {
      value: 'Google Ad',
      name: 'Google Ad',
    },
    {
      value: 'Youtube',
      name: 'Youtube',
    },
    {
      value: 'Cold Call',
      name: 'Cold Call',
    },
    {
      value: 'Others',
      name: 'Others',
    },
  ],
};

export const getOptions = (
  group: string
): { value?: string; name: string }[] => {
  return options[group];
};
