import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Booking, EditBooking, GenericResponse } from 'src/app/utils/types';
import { Observable } from 'rxjs';
import { EditBookingState } from 'src/app/utils/enums';

const baseUrl = `${environment.apiUrl}/book`;
const editBookingUrl = `${environment.apiUrl}/EditBookings`;

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  constructor(public http: HttpClient) {}

  public getAll(): Observable<Booking[]> {
    return this.http.get<Booking[]>(`${baseUrl}`);
  }

  public getById(bookingId: string): Observable<Booking[]> {
    let params = new HttpParams();
    params = params.append('$filter', `id eq '${bookingId}'`);
    return this.http.get<Booking[]>(`${baseUrl}`, {
      params,
    });
  }

  public getByThreadId(threadId: string): Observable<Booking[]> {
    let params = new HttpParams();
    params = params.append('$filter', `threadId eq '${threadId}'`);
    return this.http.get<Booking[]>(`${baseUrl}`, {
      params,
    });
  }

  public getByOwnerId(ownerId: string): Observable<Booking[]> {
    let params = new HttpParams();
    params = params.append('$filter', `ownerId eq '${ownerId}'`);
    return this.http.get<Booking[]>(`${baseUrl}`, {
      params,
    });
  }

  public getByCustomerId(customerId: string): Observable<Booking[]> {
    let params = new HttpParams();
    params = params.append('$filter', `customerId eq '${customerId}'`);
    return this.http.get<Booking[]>(`${baseUrl}`, {
      params,
    });
  }

  public create(data: Booking) {
    return this.http.post<GenericResponse>(`${baseUrl}`, data);
  }

  public update(data: object) {
    return this.http.patch<GenericResponse>(`${baseUrl}`, data);
  }

  public createEditBooking(data: EditBooking) {
    return this.http.post<GenericResponse>(`${editBookingUrl}`, data);
  }

  public updateEditBooking(
    id: string,
    data: { state: EditBookingState; lastModified: string }
  ) {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.patch<GenericResponse>(`${editBookingUrl}`, data, {
      params,
    });
  }

  public getEditBookings({
    id,
    bookingId,
  }: {
    id?: string;
    bookingId?: string;
  }): Observable<{ result: EditBooking[] }> {
    let params = new HttpParams();
    if (id) {
      params = params.append('Id', id);
    }
    if (bookingId) {
      params = params.append('BookingId', bookingId);
    }
    return this.http.get<{ result: EditBooking[] }>(`${editBookingUrl}`, {
      params,
    });
  }
}
