<h2 mat-dialog-title class="text-center">Welcome to Camlyst</h2>
<h4 mat-dialog-title class="text-center">
  Already a member?
  <a class="text-primary" (click)="navigateToLogin()">Sign in</a>
</h4>
<mat-dialog-content class="mat-typography signup-form">
  <div class="row">
    <button *ngIf="false" mdbBtn color="primary" class="waves-light" mdbWavesEffect>
      <mdb-icon fab icon="google" class="mr-1"></mdb-icon>Log in with Google
    </button>
    <button mdbBtn color="indigo" class="waves-light" mdbWavesEffect (click)="loginWithFB()">
      <mdb-icon fab icon="facebook" class="mr-1"></mdb-icon>Log in with Facebook
    </button>
  </div>
  <div class=" row">
    <p class="text-center subform-text"></p>
    <p class="divider-word">
      <span class="subform-text">or</span>
    </p>
  </div>
  <form [formGroup]="signupForm">
    <div class="row" *ngFor="let error of errors" class="errors">
      <mat-error>* {{error}}</mat-error>
    </div>
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>First Name</mat-label>
        <input type="text" matInput formControlName="firstName" placeholder="First Name">
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Last Name</mat-label>
        <input type="text" matInput formControlName="lastName" placeholder="Last Name">
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Username</mat-label>
        <input type="text" matInput formControlName="username" placeholder="Username" autocomplete="username">
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Phone Number</mat-label>
        <input type="text" matInput formControlName="phone" placeholder="Phone Number">
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Email Address</mat-label>
        <input type="email" matInput formControlName="email" placeholder="Email Address" autocomplete="email">
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>City</mat-label>
        <input type="text" matInput formControlName="city" placeholder="City" [matAutocomplete]="city">
        <mat-error *ngIf="signupForm.controls.city.dirty && signupForm.controls.city.errors?.match">
          * Choose only cities in the list
        </mat-error>
        <mat-autocomplete #city="matAutocomplete">
          <mat-option *ngFor="let option of filteredCities | async" [value]="option.name">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="password">
        <mat-label>Password</mat-label>
        <input type="password" matInput formControlName="password" placeholder="Password" autocomplete="new-password">
        <mat-hint>Must be at least 8 characters long including at least one number</mat-hint>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Re-type Password</mat-label>
        <input type="password" matInput formControlName="confirmPassword" placeholder="Re-type Password"
          autocomplete="new-password">
      </mat-form-field>
    </div>
    <div class="row">
      <mat-checkbox [(ngModel)]="agree" [ngModelOptions]="{standalone: true}">
        <span>By signing up as a member, I agree to Camlyst's
          <a class="text-primary">Terms of services</a>,
          <a class="text-primary" (click)="togglePrivacyPolicy($event)">privacy policy</a> and
          <a class="text-primary">community rules</a></span>
      </mat-checkbox>
    </div>
    <!-- Terms, policy and rules -->
    <div class="my-5">
      <app-statements [show]="showPrivacyPolicy" (showChange)="onShowUserPolicy($event)">
        <app-privacy-statements></app-privacy-statements>
      </app-statements>
    </div>
    <div class="row">
      <button type="button" mdbBtn color="light-blue" class="mx-0 waves-light light-blue darken-3" mdbWavesEffect
        [disabled]="!agree" (click)="submit()">Submit</button>
    </div>
  </form>
</mat-dialog-content>