<h2 *ngIf='!data?.code' mat-dialog-title class="text-center">Forgot your password?</h2>
<h2 *ngIf='data?.code' mat-dialog-title class="text-center">Reset your password</h2>
<mat-dialog-content class="mat-typography forgot-password-form">
  <form (submit)="submit()">
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input type="email" matInput [formControl]="email" placeholder="Email">
      </mat-form-field>
    </div>
    <div *ngIf='data?.code' class="row">
      <mat-form-field appearance="outline">
        <mat-label>New Password</mat-label>
        <input type="password" matInput [formControl]="newPassword" placeholder="New Password">
      </mat-form-field>
    </div>
    <div class="row">
      <button mdbBtn type="submit" color="light-blue" class="w-100 mx-0 waves-light light-blue darken-3" mdbWavesEffect
        (click)="submit()">Submit</button>
    </div>
  </form>
</mat-dialog-content>