import { RentalCategoriesService } from './../../services/rental/rental-categories.service';
import { RentalCategory } from './../../utils/types';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rental-category-grid',
  templateUrl: './rental-category-grid.component.html',
  styleUrls: ['./rental-category-grid.component.scss'],
})
export class RentalCategoryGridComponent implements OnInit {
  rentalCategories: RentalCategory[];
  constructor(private _rentalCtgService: RentalCategoriesService) {
    this.rentalCategories = this._rentalCtgService.getAll();
  }

  ngOnInit(): void {}
}
