<div class="container">
  <div *ngIf="crew" class="my-4">
    <div class="row">
      <app-crew-btn class="col-lg-4 col-md-6 mb-3" direction="reverse"
        [type]="(userRole === 'Admin') || (userRole === 'Crew') ? 'edit' : 'new'"></app-crew-btn>
      <div class="offset-lg-6 offset-md-3 offset-sm-0 col-lg-2 col-md-3 mb-3 d-flex flex-column align-items-center">
        <mdb-icon *ngIf="!crew.user.profilePicture" fas icon="user-circle" size="9x"></mdb-icon>
        <img *ngIf="crew.user.profilePicture" [src]="crew.user.profilePicture" alt="thumbnail"
          class="user-profile img-fluid rounded-circle z-depth-1">
        <p class="h6 my-2">{{crew.user.firstName}} {{crew.user.lastName}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 my-3">
        <!-- crew images -->
        <mdb-carousel [animation]="'slide'" [interval]=0>
          <mdb-carousel-item *ngIf="crew.imageUrl1">
            <img class="d-block w-100" [src]="crew.imageUrl1">
          </mdb-carousel-item>
          <mdb-carousel-item *ngIf="crew.imageUrl2">
            <img class="d-block w-100" [src]="crew.imageUrl2">
          </mdb-carousel-item>
          <mdb-carousel-item *ngIf="crew.imageUrl3">
            <img class="d-block w-100" [src]="crew.imageUrl3">
          </mdb-carousel-item>
        </mdb-carousel>
      </div>
      <div class="col-md-4 my-3">
        <div *ngIf='videoUrl' class="video-container">
          <iframe width="100%" height="100%" [src]="videoUrl" frameborder="0" allowfullscreen></iframe>
        </div>
        <a *ngIf="crewUserId !== userId" [routerLink]="['/inquiryMessage']"
          [queryParams]="{crewId: crewId, receiverId: crewUserId, crewName: crew.user.firstName + ' ' + crew.user.lastName}">
          <button class="w-100 mx-0 my-3 light-blue darken-2" mdbBtn color="light-blue">
            <mdb-icon fas icon="envelope"></mdb-icon>
            <span class="mx-2 text-capitalize">Contact for pricing and booking details</span>
          </button>
        </a>
        <ng-container *ngIf="!userIsOwner">
          <button *ngIf="!isFavorite && crewUserId !== userId" class="m-3" mdbBtn color="light"
            (click)="addToFavorites()">
            <mdb-icon far icon="heart"></mdb-icon>
            <span class="mx-2 text-capitalize">Save</span>
          </button>
          <button *ngIf="isFavorite" class="m-3" mdbBtn color="light" (click)="removeFromFavorites()">
            <mdb-icon fas icon="heart"></mdb-icon>
            <span class="mx-2 text-capitalize">Saved</span>
          </button>
        </ng-container>
        <button class="m-3" mdbBtn color="light" (click)="copy()">
          <mdb-icon fas icon="copy"></mdb-icon>
          <span class="mx-2 text-capitalize">Copy link</span>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 my-3 crew-details">
        <p class="h4">{{crew.user.firstName}} {{crew.user.lastName}}</p>
        <p class="thin-text">
          <mdb-icon fas icon="map-marker-alt"></mdb-icon> {{crew.city.name}}, {{crew.province.name}}
        </p>
        <div class="d-flex">
          <ngb-rating *ngIf="crew.rating" [rate]="crew.rating" [readonly]="true" [max]=5>
            <ng-template let-fill="fill" let-index="index">
              <span class="star" [class.filled]="fill === 100">&#9733;</span>
            </ng-template>
          </ngb-rating>
          <p class="thin-text mx-4">
            {{crew.reviews.length}} Reviews
          </p>
        </div>
        <div class="w-50 mt-2">
          <p class="h6">Tags</p>
          <div class="d-flex flex-wrap">
            <div class="mx-2" *ngFor="let tag of crew.crewTag">
              <p class="h6">
                <mdb-badge [pill]="true" color="white"><span class="text-dark">{{tag.description}}</span></mdb-badge>
              </p>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <p class="h6">About</p>
          <p>{{crew.aboutMe}}</p>
        </div>
        <div class="mt-2">
          <p class="h6">Cancellation Policy</p>
          <p>All bookings are subject to 24 hours grace period.
            <br>This provides full refunds for cancellations within
            24 hrs of booking.
          </p>
        </div>
        <div class="mt-2">
          <p class="h6">Flexible</p>
          <p>100% refund for bookings cancelled 3 days prior to pickup date
            <br>50% refund if cancelled 24 hours prior to pick up date.
            <br>Cancellations submitted less than 24 hours prior to pick up time are non-refundable.
          </p>
        </div>
        <div *ngIf="crew.reviews.length > 0 && activeReviews.length > 0" class="mt-2">
          <p class="h6">Reviews</p>
          <div *ngFor="let review of activeReviews" class="my-3">
            <app-review [value]="review"></app-review>
          </div>
          <mat-paginator [hidePageSize]="true" [length]="crew.reviews.length" [pageSize]="2" showFirstLastButtons
            (page)="onPageChange($event)">
          </mat-paginator>
        </div>
      </div>
      <div class="col-md-4 my-3">
        <p class="h6">Verification</p>
        <div class="w-50 d-flex justify-content-between mb-1">
          <span>Email Address</span>
          <mdb-icon fas icon="check-circle" [class.grey-text]="!crew.user.isEmailConfirmed"
            [class.blue-text]="crew.user.isEmailConfirmed"></mdb-icon>
        </div>
        <div class="w-50 d-flex justify-content-between mb-1">
          <span>Phone Number</span>
          <mdb-icon fas icon="check-circle" [class.grey-text]="!crew.user.isPhoneConfirmed"
            [class.blue-text]="crew.user.isPhoneConfirmed"></mdb-icon>
        </div>
        <div class="w-50 d-flex justify-content-between mb-1">
          <span>Government ID</span>
          <mdb-icon fas icon="check-circle" [class.grey-text]="!crew.user.isGovtIDConfirmed"
            [class.blue-text]="crew.user.isGovtIDConfirmed"></mdb-icon>
        </div>
        <button class="w-100 mx-0 my-3" mdbBtn color="link">Report User</button>
      </div>
    </div>
  </div>
</div>