<div class="d-flex m-3" [class.justify-content-start]="!isSender" [class.justify-content-end]="isSender">
  <div *ngIf="!isSender" class="mx-2">
    <img *ngIf="message.sender.profilePicture" class="rounded-circle z-depth-1" [src]="message.sender.profilePicture"
      alt="Sender image" />
    <mdb-icon *ngIf="!message.sender.profilePicture" fas icon="user-circle" size="4x"></mdb-icon>
  </div>
  <div class="mt-1 mx-2">
    <p class="h6">{{messageText}}</p>
    <p class="text-muted">{{dateTime}}</p>
  </div>
  <div *ngIf="isSender" class="mx-2">
    <img *ngIf="message.sender.profilePicture" class="img-fluid rounded-circle z-depth-1"
      [src]="message.sender.profilePicture" alt="Sender image" />
    <mdb-icon *ngIf="!message.sender.profilePicture" fas icon="user-circle" size="4x"></mdb-icon>
  </div>
</div>