import { AccountService } from 'src/app/services/account/account.service';
import { PageEvent } from '@angular/material/paginator';
import { ListingsService } from 'src/app/services/listings/listings.service';
import { ActivatedRoute } from '@angular/router';
import { Listing } from './../../../utils/types';
import { startWith, map } from 'rxjs/operators';
import { KeywordsService } from 'src/app/services/keywords/keywords.service';
import { LocationService } from 'src/app/services/location/location.service';
import { FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Keyword, Place } from 'src/app/utils/types';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-listings',
  templateUrl: './listings.component.html',
  styleUrls: ['./listings.component.scss'],
})
export class ListingsComponent implements OnInit {
  isLoggedIn = false;
  listings!: Listing[];
  similarListings!: Listing[];
  keyword: FormControl = new FormControl();
  city: FormControl = new FormControl();
  keywords!: Keyword[];
  filteredKeywords!: Observable<Keyword[]>;
  cities!: Place[];
  filteredCities!: Observable<Place[]>;
  totalCount!: number;
  pageSize!: number;
  currentPage!: number;
  totalPages!: number;
  constructor(
    private _auth: AccountService,
    private _locationService: LocationService,
    private _keywordsService: KeywordsService,
    private _listingService: ListingsService,
    private _route: ActivatedRoute
  ) {
    this.isLoggedIn = this._auth.isAuthenticated();
    this._keywordsService
      .keywords()
      .toPromise()
      .then((res) => {
        this.keywords = res.result.filter((item) => item.type === 'listing');
      })
      .then(() => {
        this._locationService
          .cities()
          .toPromise()
          .then((res) => {
            this.cities = res.result;
          })
          .then(() => {
            this._route.queryParams.subscribe((params) => {
              const { keyword, city } = params;
              if (keyword) {
                this.keyword.patchValue(keyword);
              }
              if (city) {
                this.city.patchValue(city);
              }
              this.onSearch();
            });
          });
      });
  }

  ngOnInit(): void {
    this.filteredKeywords = this.keyword.valueChanges.pipe(
      startWith(''),
      map((value: string) =>
        value && value.length >= 1 ? this._filterKeywords(value) : []
      )
    );
    this.filteredCities = this.city.valueChanges.pipe(
      startWith(''),
      map((value: string) =>
        value && value.length >= 1 ? this._filterCities(value) : []
      )
    );
  }

  private _filterKeywords(value: string, exactMatch = false): Keyword[] {
    const filterValue = value.toLowerCase();
    return this.keywords.filter((option: Keyword) => {
      if (exactMatch) {
        return option.name.toLowerCase() === filterValue;
      }
      return option.name.toLowerCase().includes(filterValue);
    });
  }

  private _filterCities(value: string): Place[] {
    const filterValue = value.toLowerCase();
    return this.cities.filter((option: Place) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  onSearch(PageNumber?: number) {
    const keywordSearch = this._filterKeywords(this.keyword.value, true);
    const city = this.city.value;
    const param = {
      [keywordSearch[0]?.group]: keywordSearch[0]?.name,
      city,
      PageNumber,
    };
    this._listingService.getListings(param).subscribe((res) => {
      const listings = res.result.data;
      this.listings = listings;
      this.similarListings = res.result.similarListing;
      this.totalCount = res.result.totalCount;
      this.pageSize = res.result.pageSize;
      this.currentPage = res.result.currentPage;
      this.totalPages = res.result.totalPages;
    });
  }

  onPageChange(evt: PageEvent) {
    const { pageIndex } = evt;
    this.onSearch(pageIndex + 1);
  }
}
