<div class="container">
  <div class="my-3">
    <button type="button" class="close" aria-label="Close" mat-dialog-close>
      <span class="text-danger" aria-hidden="true">×</span>
    </button>
    <div>
      <p class="h5">
        <span *ngIf="type === 'crew'">Crew booking invite</span>
        <span *ngIf="type === 'listing'">Listing booking invite</span>
      </p>
    </div>
  </div>
  <div class="mt-3">
    <mat-form-field appearance="outline">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [min]="today" [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date">
        <input matEndDate formControlName="end" placeholder="End date" (dateChange)="onDateChange()">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
      <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
      <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
    </mat-form-field>
    <table *ngIf="showCosting" class="w-100" mdbTable [small]="true">
      <tbody>
        <tr mdbTableCol>
          <td>{{days}} Day(s) Rental</td>
          <td>${{rentalAmount}}</td>
        </tr>
      </tbody>
    </table>
    <div class="form-row">
      <div class="col-xs-6 mb-3">
        <label for="total" class="col-form-label">Total Amount agreed</label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input id="total" type="text" class="form-control" aria-label="Amount (to the nearest dollar)"
            [formControl]="totalAmountAgreed">
        </div>
        <mat-error *ngIf="(totalAmountAgreed.touched || totalAmountAgreed.dirty) && totalAmountAgreed.errors?.required">
          * Field Required
        </mat-error>
      </div>
    </div>
    <div class="form-group">
      <label class="h6" for="notes">Add Notes</label>
      <p class="text-muted">Maximum of 50 Characters</p>
      <textarea type="text" id="notes" class="form-control md-textarea" rows="3" mdbInput
        [formControl]="notes"></textarea>
    </div>
    <div class="form-group">
      <mat-checkbox [(ngModel)]="agreeTerms" [ngModelOptions]="{standalone: true}">
        <span>I agree to Camlyst's
          <a class="text-primary">terms and conditions</a> and
          <a class="text-primary">user's policy</a></span>.
      </mat-checkbox>
    </div>
    <button class="w-100 m-0 light-blue darken-3" type="button" mdbBtn color="light-blue" [disabled]="!agreeTerms"
      (click)="submit()">Submit</button>
  </div>
</div>