import { Router } from '@angular/router';
import { Keyword, Place } from './../../utils/types';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { LocationService } from 'src/app/services/location/location.service';
import { KeywordsService } from 'src/app/services/keywords/keywords.service';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
})
export class SearchFormComponent implements OnInit {
  keyword: FormControl = new FormControl(null, Validators.required);
  city: FormControl = new FormControl();
  keywords!: Keyword[];
  cities!: Place[];
  filteredKeywords!: Observable<Keyword[]>;
  filteredCities!: Observable<Place[]>;
  constructor(
    private _locationService: LocationService,
    private _keywordsService: KeywordsService,
    private _router: Router
  ) {
    this._keywordsService
      .keywords()
      .subscribe((res) => (this.keywords = res.result));
    this._locationService
      .cities()
      .subscribe((res) => (this.cities = res.result));
  }

  ngOnInit(): void {
    this.filteredKeywords = this.keyword.valueChanges.pipe(
      startWith(''),
      map((value: string) =>
        value.length >= 1 ? this._filterKeywords(value) : []
      )
    );

    this.filteredCities = this.city.valueChanges.pipe(
      startWith(''),
      map((value: string) =>
        value.length >= 1 ? this._filterCities(value) : []
      )
    );
  }

  private _filterKeywords(value: string, exactMatch = false): Keyword[] {
    const filterValue = value.toLowerCase();
    return this.keywords.filter((option: Keyword) => {
      if (exactMatch) {
        return option.name.toLowerCase() === filterValue;
      }
      return option.name.toLowerCase().includes(filterValue);
    });
  }

  private _filterCities(value: string): Place[] {
    const filterValue = value.toLowerCase();
    return this.cities.filter((option: Place) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  search(): Promise<boolean> | void {
    this.keyword.markAsTouched();
    if (this.keyword.valid) {
      const keywordSearch = this._filterKeywords(this.keyword.value, true);
      const citySearch = this.city.value;
      if (keywordSearch[0].type === 'crew') {
        return this._router.navigate(['crew'], {
          queryParams: { keyword: keywordSearch[0].name, city: citySearch },
        });
      }
      if (keywordSearch[0].type === 'listing') {
        return this._router.navigate(['listings'], {
          queryParams: { keyword: keywordSearch[0].name, city: citySearch },
        });
      }
    }
  }
}
