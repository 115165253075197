<div class="container">
  <div class="my-3">
    <button type="button" class="close" aria-label="Close" mat-dialog-close>
      <span class="red-text" aria-hidden="true">×</span>
    </button>
    <div>
      <p class="h5">
        Booking Invite
      </p>
    </div>
  </div>
  <div *ngIf="booking" class="d-flex flex-column">
    <p *ngIf="booking.notes" class="h6">Notes: <span class="text-muted">{{booking.notes}}</span></p>
    <div class="m-3">
      <p class="h6">Start Date</p>
      <p class="h6 text-muted">{{booking.startDate}}</p>
      <p class="h6">End Date</p>
      <p class="h6 text-muted">{{booking.endDate}}</p>
    </div>
    <table class="w-100 my-3" mdbTable [small]="true">
      <tbody>
        <tr mdbTableCol>
          <td>Rental</td>
          <td>$ {{displayedCost.rental}}</td>
        </tr>
        <tr mdbTableCol>
          <td>Taxes</td>
          <td>$ {{displayedCost.taxes}}</td>
        </tr>
        <tr mdbTableCol>
          <td>Sub-Total</td>
          <td>$ {{displayedCost.subTotal}}</td>
        </tr>
        <tr mdbTableCol>
          <td>Refundable Security Deposit</td>
          <td>$ {{displayedCost.securityDeposit}}</td>
        </tr>
        <tr mdbTableCol>
          <td>Total</td>
          <td>$ {{displayedCost.total}}</td>
        </tr>
      </tbody>
    </table>
    <ng-container *ngIf="!hasInitiatedPayment">
      <div class="m-2 d-flex justify-content-between">
        <button class="light-blue darken-3" type="button" color="light-blue" mdbBtn mdbWavesEffect (click)="accept()">
          Accept
        </button>
        <button class="amber darken-3" type="button" color="amber" mdbBtn mdbWavesEffect (click)="decline()">
          Decline
        </button>
      </div>
    </ng-container>
    <app-payment-intent *ngIf="cost && hasInitiatedPayment" [amount]='cost.total' [booking]='booking'
      (paymentResult)="onPaymentResult($event)"></app-payment-intent>
    <div class="mt-3">
      <p>By accepting, you are agreeing to Camlyst's
        <a class="text-primary">Terms and conditions</a> and
        <a class="text-primary" (click)="toggleUserPolicy($event)">user's policy</a>
      </p>
    </div>
    <!-- Add terms and conditions here -->
    <app-statements [show]="showUserPolicy" (showChange)="onShowUserPolicy($event)">
      <!-- Replace with actual user policy -->
      <app-privacy-statements></app-privacy-statements>
    </app-statements>
  </div>
</div>