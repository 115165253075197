<div class="container">
  <div class="my-4">
    <div class="row justify-content-end">
      <a class="col-12 col-sm-6 col-lg-4 col-xl-3" [routerLink]="['new-listing']">
        <button class="w-100 light-blue darken-3" type="submit" mdbBtn color="light-blue">+ Add New
          Listing</button>
      </a>
    </div>
    <div *ngIf="!listings || listings.length === 0" class="my-5 py-5 d-flex justify-content-center">
      <h1 class="mat-display-2">Your have no listing</h1>
    </div>
    <div *ngIf="listings && listings.length > 0">
      <div class="row" *ngFor="let listing of listings">
        <div class="my-2 w-100">
          <app-my-listing-card [listing]="listing"></app-my-listing-card>
        </div>
      </div>
      <div class="row justify-content-center">
        <mat-paginator [hidePageSize]="true" [length]="totalCount" [pageSize]="pageSize" showFirstLastButtons
          (page)="onPageChange($event)">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>