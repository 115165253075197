import { AbstractControl, ValidationErrors } from '@angular/forms';

export class BankAccountValidators {
  static shouldMatch(control: AbstractControl): ValidationErrors | null {
    const accountNumber = control.get('accountNumber');
    const verifyAccountNumber = control.get('verifyAccountNumber');
    if (!accountNumber || !verifyAccountNumber) {
      return null;
    }
    if (accountNumber.value !== verifyAccountNumber.value) {
      return { shouldMatch: true };
    }
    return null;
  }
}
