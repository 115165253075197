import { filter } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { Listing } from './../../../../utils/types';
import { ListingsService } from 'src/app/services/listings/listings.service';
import { AccountService } from 'src/app/services/account/account.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-listings',
  templateUrl: './my-listings.component.html',
  styleUrls: ['./my-listings.component.scss'],
})
export class MyListingsComponent implements OnInit {
  userId?: string;
  listings!: Listing[];
  totalCount!: number;
  pageSize!: number;
  currentPage!: number;
  totalPages!: number;

  constructor(
    private _accountService: AccountService,
    private _listingService: ListingsService,
    private _router: Router
  ) {
    this.userId = this._accountService.currentUser?.name;
    if (this.userId) {
      this.getListings(this.userId);
    }
  }

  getListings(UserId: string, PageNumber?: number) {
    this._listingService
      .getListings({ UserId, PageNumber })
      .subscribe((res) => {
        this.listings = res.result.data;
        this.totalCount = res.result.totalCount;
        this.pageSize = res.result.pageSize;
        this.currentPage = res.result.currentPage;
        this.totalPages = res.result.totalPages;
      });
  }

  ngOnInit(): void {
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.userId) {
          this.getListings(this.userId);
        }
      });
  }

  onPageChange(evt: PageEvent) {
    if (this.userId) {
      const { pageIndex } = evt;
      this.getListings(this.userId, pageIndex + 1);
    }
  }
}
