<!-- Loading spinner -->
<div *ngIf="loading" class="loading-container d-flex justify-content-center align-items-center">
  <mat-spinner color="accent" mode="indeterminate">
  </mat-spinner>
</div>

<!-- Universal Navbar -->
<app-navbar></app-navbar>

<!-- Inner Navbar -->
<ng-container *ngIf="isLoggedIn && !isLandingPage">
  <app-inner-navbar></app-inner-navbar>
</ng-container>

<router-outlet></router-outlet>

<!-- Footer -->
<app-footer></app-footer>