export const calcServiceFee = (amount: number): number =>
  roundNumber(amount * 0.1);

export const calcTaxes = (amount: number): number => roundNumber(amount * 0.13);

export const calcTotal = (items: number[]): number =>
  items.reduce((pSum, item) => pSum + item, 0);

export const calcFraction = ({
  amount,
  percentage,
}: {
  amount: number;
  percentage: number;
}): number => roundNumber(amount * (percentage / 100));

export const roundNumber = (num: number): number =>
  Math.round((num + Number.EPSILON) * 100) / 100;
