import { Component, Input, OnInit } from '@angular/core';
import { Listing } from 'src/app/utils/types';
const NO_IMAGE = '../../../../assets/no-image-available.png';
@Component({
  selector: 'app-listing-card',
  templateUrl: './listing-card.component.html',
  styleUrls: ['./listing-card.component.scss'],
})
export class ListingCardComponent implements OnInit {
  @Input() listing!: Listing;
  cardImageUrl!: string;
  constructor() {}

  ngOnInit(): void {
    this.cardImageUrl =
      this.listing.imageUrl1 ??
      this.listing.imageUrl2 ??
      this.listing.imageUrl3 ??
      this.listing.imageUrl4 ??
      this.listing.imageUrl5 ??
      NO_IMAGE;
  }
}
