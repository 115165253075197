import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams, HttpResponseBase } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { PaymentIntent } from '@stripe/stripe-js';
import { Bank, PaymentDetails, Payout } from 'src/app/utils/types';

const nodeAPIUrl = `${environment.nodeApiUrl}`;
const baseUrl = `${environment.apiUrl}/Payout`;

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(public http: HttpClient) {}

  public createPaymentIntent(data: PaymentDetails): Observable<PaymentIntent> {
    return this.http.post<PaymentIntent>(
      `${nodeAPIUrl}/create-payment-intent`,
      data
    );
  }

  public getBankName(institutionNumber: string) {
    return this.http.get<Bank>(`${nodeAPIUrl}/bank-names/${institutionNumber}`);
  }

  public getPayout(userId: string) {
    let params = new HttpParams();
    params = params.append('userId', userId);
    return this.http.get<Payout[]>(`${baseUrl}/Get`, { params });
  }

  public createPayout(data: Payout) {
    return this.http.post<Payout>(`${baseUrl}/Create`, data);
  }

  public updatePayout(data: Payout) {
    return this.http.patch<HttpResponseBase>(`${baseUrl}/Update`, data, {
      observe: 'response',
    });
  }
}
