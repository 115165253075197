import { EditBookingState } from './../../../utils/enums';
import { filter } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { AccountService } from 'src/app/services/account/account.service';
import { FormGroup, FormControl } from '@angular/forms';
import {
  Booking,
  EditBooking,
  GenericResponse,
  ThreadMessage,
  Threads,
} from 'src/app/utils/types';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Component, AfterViewChecked, OnInit, NgZone } from '@angular/core';
import { ThreadService } from 'src/app/services/thread/thread.service';
import * as moment from 'moment';
import { BookingService } from 'src/app/services/booking/booking.service';
import { BookingStatus, ThreadStatus } from 'src/app/utils/enums';

@Component({
  selector: 'app-thread',
  templateUrl: './thread.component.html',
  styleUrls: ['./thread.component.scss'],
})
export class ThreadComponent implements OnInit, AfterViewChecked {
  userId?: string;
  threadId!: string;
  bookingId?: string;
  itemType!: 'listing' | 'crew';
  // This is the owner of the item i.e. crew or listing owner. It is different from the thread owner (user that started the thread).
  ownerId!: string;
  customerId!: string;
  thread!: Threads;
  messages!: ThreadMessage[];
  editBooking?: EditBooking;
  msgForm: FormGroup;
  isOwner = false;
  hasNewBooking = false;
  isAutoListingBooking = false;
  isThreadInquiry = false;
  isThreadPending = false;
  isThreadDeclined = false;
  container!: HTMLElement | null;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _threadService: ThreadService,
    private _accountService: AccountService,
    private _bookingService: BookingService,
    private _notificationService: NotificationService,
    private ngZone: NgZone
  ) {
    this.subscribeToEvents();
    this.userId = this._accountService.currentUser?.name;
    this._route.params.subscribe((params) => {
      this.threadId = params.threadId;
      if (this.userId) {
        this.getMessages(this.threadId, this.userId);
      }
    });

    this.msgForm = new FormGroup({
      messageText: new FormControl(),
    });
  }

  ngOnInit() {
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.userId) {
          this.getMessages(this.threadId, this.userId);
        }
      });
  }

  ngAfterViewChecked() {
    this.container = document.getElementById('msg-screen');
    if (this.container) {
      this.container.scrollTop = this.container.scrollHeight;
    }
  }

  getMessages(threadId: string, userId: string) {
    this._threadService
      .getThread(threadId, true)
      .toPromise()
      .then((thread) => {
        this.thread = thread.result[0];
        this.getBooking();
        // Assuming the starter of the thread is the customer
        this.customerId = this.thread.ownerId;
        this.ownerId = this.thread.coOwnerId;
        this.isThreadInquiry = ThreadStatus.Inquiry === this.thread.status;
        this.isThreadPending = ThreadStatus.Pending === this.thread.status;
        this.isThreadDeclined = ThreadStatus.Declined === this.thread.status;
        this.itemType = this.thread.listingId ? 'listing' : 'crew';
        this.isOwner = this.ownerId === userId ? true : false;
        this.messages = this._sortMessages(this.thread.messages);
      });
  }

  private _getEditBookingInfo({
    id,
    bookingId,
  }: {
    id?: string;
    bookingId?: string;
  }) {
    return this._bookingService.getEditBookings({ id, bookingId }).toPromise();
  }

  private _sortMessages(messages: ThreadMessage[]) {
    return [...messages].sort((a, b) => {
      const aTimestamp = Number(a.timestamp);
      const bTimestamp = Number(b.timestamp);
      return aTimestamp - bTimestamp;
    });
  }

  getBooking() {
    if (this.thread.id) {
      this._bookingService.getByThreadId(this.thread.id).subscribe((res) => {
        const booking = res[0];
        if (!booking) {
          return;
        }
        this.bookingId = booking.id;
        this.hasNewBooking = BookingStatus.New === booking.status;
        this.isAutoListingBooking = this._isBookingAutomated(booking);
        if (booking.id) {
          this._getEditBookingInfo({ bookingId: booking.id }).then(
            (editBookingRes) => {
              const editBookings = editBookingRes.result.filter(
                (item) =>
                  item.initiatorId !== this.userId &&
                  item.state === EditBookingState.Initiated
              );
              const editBookingsLength = editBookings.length;
              if (editBookingsLength > 0) {
                this.editBooking = editBookings[editBookingsLength - 1];
              }
            }
          );
        }
      });
    }
  }

  send() {
    const messageText = this.msgForm.get('messageText')?.value;
    if (!messageText || messageText.length <= 0) {
      return;
    }
    const senderId = this._accountService.currentUser?.name;
    if (!senderId) {
      return;
    }
    const receiverId = this.isOwner ? this.customerId : this.ownerId;
    const timestamp = moment.now().toString();
    const data = {
      senderId,
      receiverId,
      timestamp,
      messageText,
    };
    const threadId = this.thread.id;
    if (threadId) {
      this._threadService.createMessage(threadId, data).then((res) => {
        if (res.isSuccess) {
          this.msgForm.reset();
          this._notificationService.success('Message sent', 3000);
        } else {
          this._notificationService.error(res.message);
        }
      });
    }
  }

  private subscribeToEvents(): void {
    this._threadService.messageReceived.subscribe((res: GenericResponse) => {
      this.ngZone.run(() => {
        if (res.isSuccess && this.userId) {
          this.getMessages(this.threadId, this.userId);
        }
      });
    });
  }

  private _isBookingAutomated(booking: Booking): boolean {
    const bookingNote = booking.notes;
    if (bookingNote) {
      return bookingNote.startsWith('AUTOMATED NOTE');
    }
    return false;
  }

  onFocus(event: any) {
    this._threadService.removeItem(this.threadId);
  }
}
