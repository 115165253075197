import { SafeUrl } from '@angular/platform-browser';
import { Component, Output, EventEmitter, Input } from '@angular/core';
const ALLOWED_FILETYPES = ['image/png', 'image/jpeg'];
@Component({
  selector: 'app-file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss'],
})
export class FileDropComponent {
  @Input() fileId!: string;
  @Input() imagePath?: string | null | SafeUrl;
  @Output() upload = new EventEmitter<{
    fileId: string;
    file: File | null;
  }>();
  isDraggedOver = false;

  constructor() {}

  private _imageUpload(file: File): void {
    const reader = new FileReader();
    if (file && ALLOWED_FILETYPES.includes(file.type)) {
      reader.onload = () => {
        this.imagePath = reader.result as string;
      };
      reader.readAsDataURL(file);
      this.upload.emit({
        fileId: this.fileId,
        file,
      });
    }
  }

  remove(): boolean {
    this.imagePath = null;
    this.upload.emit({
      fileId: this.fileId,
      file: null,
    });
    return false;
  }

  onImageSelectionUpload(event: any): void {
    if (event.target.files && event.target.files.length) {
      const files = event.target.files as FileList;
      this._imageUpload(files[0]);
    }
  }

  onDropSuccess(event: any): void {
    this.isDraggedOver = false;
    if (event.dataTransfer.files && event.dataTransfer.files.length) {
      const files = event.dataTransfer.files as FileList;
      this._imageUpload(files[0]);
      event.preventDefault();
    }
  }

  // Drag and Mouse Events
  onDragOver(event: any): void {
    event.preventDefault();
  }

  onDragEnter(event: any): void {
    this.isDraggedOver = true;
    event.preventDefault();
  }

  onDragLeave(event: any): void {
    this.isDraggedOver = false;
    event.preventDefault();
  }

  onMouseEnter(event: any): void {
    this.isDraggedOver = true;
    event.preventDefault();
  }

  onMouseLeave(event: any): void {
    this.isDraggedOver = false;
    event.preventDefault();
  }
}
