import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-statements',
  template: `<p>Camlyst Privacy Policy</p>
    <p>Effective Date: _____, 2021</p>
    <p><br /></p>
    <p>I. Introduction</p>
    <p>
      This Privacy Policy explains how Camlyst Inc. (&quot;Camlyst&quot; or
      &quot;we&quot;) collects, uses, and shares personal information. As used
      in this Privacy Policy, &quot;you&quot; refers to a user of Kreative CIty
      products or services, including using our website or app
      (&quot;Services&quot;). Please note, our Services are not intended for
      children under the age of majority in your jurisdiction of residence. If
      you are under the age of majority in your jurisdiction of residence, do
      not use or provide any personal information on or through the Services or
      about yourself to us. If you believe that we might have personal
      information from or about a child under the age of majority, please
      contact us.
    </p>
    <p><br /></p>
    <p>II. Collection of User Information</p>
    <p>
      We collect and process your personal information in order to provide you
      an optimal experience possible when you interact with us. The following
      describes the type of information we collect, the source of that
      information, our purposes for using the information, and the third parties
      with whom we may share the information if appropriate.
    </p>
    <p>
      User Data:&nbsp;Data we collect about the individuals who use our
      services. We associate this data with a unique ID (Host ID or Guest ID)
      depending on the type of user.&nbsp;
    </p>
    <p>
      Includes but not limited to: Name, email address, postal code, phone
      number (including country code), city, government ID, title, affiliated
      organizations such as professional or employment-related information,
      uploaded photos, social media handles, website URL&apos;s affiliated with
      the user, communications to and from users (including email and chat
      feature communications), identity verification information, which may
      include Social Security Numbers (SSNs) , or Social Insurance Numbers
      (SINs).
    </p>
    <p>Provided by you (Users) and third parties.&nbsp;</p>
    <p><br /></p>
    <p>
      Identity verification information:&nbsp;Information collected that can
      help us verify that users are who they claim to be.
    </p>
    <p>
      We use Stripe for identity document verification. Stripe collects identity
      document images, facial images, ID numbers and addresses as well as
      information about the devices that connect to its services. Stripe shares
      this information with us and also uses this information to operate and
      improve the services it provides, including for fraud detection. You may
      also choose to allow Stripe to use your data to improve Stripe&rsquo;s
      biometric verification technology. You can learn more about Stripe and
      read its privacy policy at https://stripe.com/privacy.
    </p>
    <p>Provided by you (Users) and third parties.</p>
    <p><br /></p>
    <p>
      Device and Location Data:&nbsp;Data we collect about the device you are
      using.
    </p>
    <p>
      Internet and associated network information, browser type, device type,
      method of sign-on, device ID information, IP address.
    </p>
    <p>Provided by Devices.</p>
    <p><br /></p>
    <p>
      Platform Activity Data:&nbsp;Information we collect about how you interact
      with the Camlyst Platform.
    </p>
    <p>
      Access date/time, pages viewed, searches made, messages sent, inquiries
      made, requests made, bookings made, reviews written, boards created,
      analytics data, error logs.
    </p>
    <p>Provided by Users and devices.</p>
    <p><br /></p>
    <p>
      Cookies and Similar Data:&nbsp;Camlyst&apos;s website uses cookies,
      pixels, and other technologies to make the website work properly and to
      provide the most relevant services to our site visitors.
    </p>
    <p>
      Cookies send information back to the originating website on each
      subsequent visit, or to another website that recognizes that cookie, to
      develop a record of the user&apos;s online activity.
    </p>
    <p>Provided by Camlyst and third parties.</p>
    <p><br /></p>
    <p>
      Information about Items:&nbsp;Information associated with the items listed
      on our website/app, which may be associated with a particular individual
      or business. We assign an Item ID to each item listed.
    </p>
    <p>
      Item (type, model, lister description of item, corresponding photo of
      item) associate lister, date added).
    </p>
    <p>Provided by Users and third parties.</p>
    <p><br /></p>
    <p>
      Rental Information:&nbsp;Information related to a particular Rental). We
      assign Rental IDs to each unique Rental.
    </p>
    <p>
      Start/End date, start/end time, Rental details (item descriptions, use
      description, prices, taxes, fees), communications regarding Rentals.
    </p>
    <p>Provided by Users.</p>
    <p>
      Insurance Information:&nbsp;Information we collect about confirmed
      insurance coverage for equipment/item rentals.
    </p>
    <p>
      Insurance (Name of provider, Insurance confirmation number,
      equipment/items involved and appraised cost.
    </p>
    <p>Provided by Users, third parties.</p>
    <p><br /></p>
    <p>
      Survey/Feedback Information:&nbsp;We collect information when you fill out
      a form, provide feedback, or otherwise communicate with us.
    </p>
    <p>Survey question responses, feedback, email correspondence.</p>
    <p>Provided by Users.</p>
    <p><br /></p>
    <p>
      Contract Information:&nbsp;We collect information related to the
      agreements signed between users.
    </p>
    <p>
      Contracts signed, signatures (including electronic signatures), affiliate
      information, contract terms and other information.&nbsp; &nbsp;&nbsp;
    </p>
    <p>Provided by Users, vendors, and third parties.</p>
    <p><br /></p>
    <p>
      Sensory Information:&nbsp;Audio data, such as a recording of your voice
      when you contact us via Support
    </p>
    <p>
      Your voice, recordings of support phone calls, calls connecting you with
      other users
    </p>
    <p>Provided by Users.</p>
    <p><br /></p>
    <p>
      Payment Data:&nbsp;We collect information about the means by which you
      submit or receive payment through our platform&nbsp; &nbsp;&nbsp;
    </p>
    <p>
      Credit card information, bank account information, PayPal information.
    </p>
    <p>Users and third parties.</p>
    <p><br /></p>
    <p>
      Other Data:&nbsp;We collect additional information from individuals
      depending on how they use our Services.
    </p>
    <p>
      Calendar information (if access is provided), miscellaneous communication
      information, information related to Equipment, items, Insurance
      information, Equipment Use (including allowed operators, expected return
      condition, use restrictions, or other information).
    </p>
    <p>Provided by Users and third parties.</p>
    <p><br /></p>
    <p>
      User Interaction/Dispute Resolution:&nbsp;We collect information related
      to the interactions between our users and other users or third parties.
      Sometimes this is to facilitate communications between individuals, to
      resolve disputes, or for other business purposes compatible with the
      reason for collection.
    </p>
    <p>Information provided in relation to our business purposes.</p>
    <p>Provided by Users, vendors, and third parties.</p>
    <p><br /></p>
    <p>
      Background Check Information:&nbsp;We may conduct background checks on
      various individuals as part of our efforts to ensure the safety and
      security of users on the site.
    </p>
    <p>Information related to background checks.</p>
    <p>Provided by Background check service providers.</p>
    <p><br /></p>
    <p>III. Our Operational and Commercial Purposes</p>
    <p>
      As part of Service offerings we undertake a multitude of operations which
      require the processing of personal information. For example, our business
      purposes include: (1) accepting, coordinating, managing, and processing
      Equipment rental and rental of items (2) facilitating purchase
      transactions for goods and services; (3) providing top-level customer
      support, accommodations, and personalized services and amenities; (4)
      engaging in quality control in order to improve our offerings to you; (5)
      facilitating communications between parties; (6) engage in content based
      or interest-based advertising, (7) ensuring the safety and security of our
      users, our staff, and all other persons visiting our platform or the
      properties listed on it, including verifying your identity; (8) prevent,
      detect, and investigate fraud, cyber incidents, or other illegal activity;
      (9) communicating with you and others in relation to your use of our
      services, including providing legal updates; (10) administering our
      record-keeping for legal, tax, and operational purposes; (11) exploring
      new and unique ways to provide products and services; and (12) doing our
      best to fulfill your requests. Our commercial purposes are to advance our
      commercial interests, including for advertising and marketing. To learn
      more about how your personal information is processed for marketing and
      advertising, please see Your Marketing and Ad Choices below.
    </p>
    <p><br /></p>
    <p>IV. Disclosures of Your Personal Information</p>
    <p>
      In order to provide you our service and to fulfill our business and
      commercial purposes, we may disclose your personal information outlined in
      Section II. Information disclosed to facilitate rentals and other
      interactions between customers, and to enable your use of the Kreative
      City platform may include demographic information; financial information;
      government identifiers; protected classification characteristics;
      transaction history; internet, application, and network activity; location
      data; sensory (audio data); professional or employment-related
      information; and inferences about you.
    </p>
    <p>
      1.&nbsp; &nbsp;&nbsp;Publicly Posted Information. Some user information is
      posted publicly on the platform and is visible by others. This may include
      profile information, information related to a Equipment or items, reviews,
      ratings and other feedback, reviews of Guests completing Rentals, and
      other information that a user submits to be posted. Precise location
      information is not publicly posted.
    </p>
    <p>
      2.&nbsp; &nbsp;&nbsp;Sharing Between Users. Our Services are designed to
      connect users and to facilitate interaction between them. We also will
      disclose your personal information to any agents or third parties to whom
      you authorize us to disclose it, including the operators, managers,
      agents, or other parties associated with equipment or item use or when you
      agree to a Rental. This sharing is necessary to ensure the performance of
      the contract between you and Camlyst, and/or you and a third party.
    </p>
    <p>
      3.&nbsp; &nbsp;&nbsp;Our Service Providers. We may share your information
      to our trusted third party service providers, including but not limited to
      our cloud service providers, web development, analytics, and security
      providers, communications providers, and internal and external advisors or
      auditors, payment processors, dispute resolution providers, advertising
      providers, governmental entities, social networks, background verification
      providers and other users. When appropriate or required by applicable law,
      we will put in place appropriate contracts containing standard data
      protection clauses to protect that information and the rights of
      individuals.
    </p>
    <p>
      4.&nbsp; &nbsp;&nbsp;Other Disclosures. In addition to the foregoing, we
      may disclose personal information in the following circumstances: (1) In
      order to comply with an applicable federal, state, provincial,
      territorial, or local law; (2) In order to comply with a civil, criminal,
      or regulatory inquiry, investigation, subpoena, or summons by federal,
      state, or local authorities; (3) To cooperate with law enforcement
      agencies concerning conduct or activity that we reasonably and in good
      faith believe may violate federal, state, provincial, territorial or local
      law; (4) To exercise and defend legal or payment related claims; (5) When
      such disclosure is pursuant necessary to protect the rights and freedoms
      of other individuals; (6) As part of a prospective or completed merger,
      acquisition, bankruptcy, or other transaction in which the third party
      assumes control of all or part of Camlyst, Inc., and (7) In any other
      manner permitted by law, including engaging in protected speech.
    </p>
    <p>
      5.&nbsp; &nbsp;&nbsp;Third Party Links. Links to web sites that are not
      operated by or for us (&quot;Third Party Sites&quot;) are provided solely
      as a convenience to you. If you use these links, you will leave our
      website. This Privacy Policy does not apply to Third Party Sites. We have
      not reviewed the Third Party Sites, do not control and are not responsible
      for any of their content or their privacy policies, if any. We do not
      endorse or make any representations about them, or any information,
      software or other products or materials found there, or any results that
      may be obtained from using them. If you decide to access any of the Third
      Party Sites listed on or linked to from our website, you should understand
      that you do so at your own risk.
    </p>
    <p><br /></p>
    <p>V. Security</p>
    <p>
      We are committed to protecting the security of any personal information
      you provide. We have implemented reasonable administrative, technical and
      physical measures in an effort to safeguard the personal information in
      our custody and control against theft, loss and unauthorized access, use,
      modification and disclosure. We restrict access to personal information on
      a need-to-know basis to employees and authorized service providers who
      require access to fulfil their job requirements.
    </p>
    <p>
      All financial information you provide, such as credit card data, will
      generally be transferred over a Secured Socket Layer (SSL) connection
      (provided that it is supported or enabled on your browser). In spite of
      these protections, any information submitted over the Internet could be
      intercepted or otherwise corrupted during transmission, and we cannot
      guarantee the complete security of any data submitted over the Internet.
      Sensitive personal information is stored on servers or locations protected
      by procedures and technology designed to block reasonably foreseeable
      intrusions by unauthorized third parties. The servers are under our
      control at all times but may be hosted, managed or otherwise maintained by
      third party service providers with whom we contract. These third party
      service providers have no right to use or access this data other than in
      performing their services for us.
    </p>
    <p>
      You may access and change certain information that you submitted during
      registration (if you have registered at the Site) at any time by providing
      your username and password. Please keep your password in a safe place and
      do not provide this information to anyone. We strongly encourage you to
      check the accuracy of your data regularly as we will use your e-mail,
      phone number, and home address to communicate with you regarding products
      or services that you have requested. We will not be liable for any
      problems or difficulties that arise as a result of your failure to keep
      your information up to date.
    </p>
    <p><br /></p>
    <p>VI. Your Marketing and Ad Choices</p>
    <p>
      Like most businesses, Camlyst advertises and markets its Services in
      various ways. We may use cookies and related technologies to recognize you
      and receive information about your activity on our websites. This helps
      customize content and show you more relevant ads. You may limit or disable
      third party personalized advertising in a number of ways.
    </p>
    <p>Opt-Out of Third-Party Personalized Advertising</p>
    <p>
      You may opt out of cross-site third party personalized advertising using
      several industry-provided frameworks that many advertisers and advertising
      networks, exchanges, platforms, and technology providers participate in.
    </p>
    <p>
      Please note that even if you opt-out of interest-based advertising by a
      third party, these tracking technologies may still collect data for other
      purposes including analytics and you will still see ads from us, but the
      ads will not be targeted based on behavioral information about you and may
      therefore be less relevant to you and your interests.
    </p>
    <p>Adjust Your Browser Setting to Block Third Party Cookies</p>
    <p>
      You can also use your web browser to directly block all cookies, or just
      third-party cookies, through your browser settings. Using your browser
      settings to block all cookies, including strictly necessary ones, may
      interfere with proper site operation. Guidance on how to control cookies
      in popular browsers is contained here:
    </p>
    <p>&bull;&nbsp; &nbsp;&nbsp;Microsoft Internet Explorer</p>
    <p>&bull;&nbsp; &nbsp;&nbsp;MacOS Safari</p>
    <p>&bull;&nbsp; &nbsp;&nbsp;Mozilla Firefox</p>
    <p>&bull;&nbsp; &nbsp;&nbsp;Google Chrome</p>
    <p>
      To successfully opt out, you must have cookies enabled in your web browser
      (see your browser&apos;s instructions for information on cookies and how
      to enable them). Your opt-out only applies to the web browser you use so
      you must opt-out of each web browser on each computer you use. Once you
      opt out, if you delete your browser&apos;s saved cookies, you will need to
      opt-out again.
    </p>
    <p>Set Do Not Track</p>
    <p>
      Your browser and other mechanisms may permit you to send do-not-track
      signals or other similar signals to express your preferences regarding
      online tracking. However, because there is not yet a defined response to
      do-not-track requests, our Site does not respond to your browser&apos;s
      do-not-track request. We cannot control third parties&apos; responses to
      do-not-track signals or other such mechanisms. Third parties&apos; use of
      data relating to you and responsiveness to do-not-track signals is
      governed by their respective privacy policies.
    </p>
    <p>Adjust Other Settings</p>
    <p>
      You can adjust the information that other third parties collect about you:
    </p>
    <p>
      &bull;&nbsp; &nbsp;&nbsp;Using Google&apos;s privacy controls to opt-out
      of ads and cookies set by Google.&nbsp;
    </p>
    <p>
      &bull;&nbsp; &nbsp;&nbsp;Using Facebook&apos;s privacy setting to restrict
      Facebook&apos;s use of cookies.&nbsp;
    </p>
    <p><br /></p>
    <p>VII. Children&apos;s Privacy</p>
    <p>
      We are committed to complying with the Children&apos;s Online Privacy
      Protection Act (COPPA). Our website and services are not directed to
      children under the age of 18. We do not knowingly collect personal
      information from children under the age of 18. If we receive personal
      information that we discover was provided by a child under the age of 18,
      we will promptly destroy such information. Parents are encouraged to
      supervise their children&apos;s online activities and consider the use of
      other means to provide a child-friendly online environment.
    </p>
    <p><br /></p>
    <p>VIII. Agreement and Changes</p>
    <p>
      By submitting your personal information through our website, you agree to
      the collection and use of your personal information on the basis described
      above. If you do not agree, please do not provide any personal information
      via our website. If we decide to update our Privacy Policy, we will
      indicate this next to the Privacy Policy link on the front page of our
      Site. If there are material changes to the Privacy Policy, we will
      prominently post and/or communicate such changes prior to implementing
      them. Information collected under previous versions of our Privacy Policy
      will then become subject to the terms of our new Privacy Policy.
    </p>
    <p><br /></p>
    <p>IX. Contact Information</p>
    <p>
      If you would like to update your information, modify your communication
      preferences, or if you do not want to receive marketing communications
      from us in the future, you can contact us by e-mail. Please note, we do
      not accept consumer rights requests via email.&nbsp;
    </p>
    <p>
      If you have any questions or comments regarding our privacy practices, or
      about the manner in which we or our service providers treat your personal
      information, please contact info@camlyst.ca
    </p>
    <p>&nbsp; &nbsp; &nbsp;</p>`,
})
export class PrivacyStatementsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
