import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Favorite, GenericResponse } from 'src/app/utils/types';

const baseUrl = `${environment.apiUrl}/favorites`;

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  constructor(public http: HttpClient) {}

  public create(data: Favorite) {
    return this.http.post<{ result: GenericResponse }>(
      `${baseUrl}/create`,
      data
    );
  }

  public getFavorites(userId: string) {
    const params = new HttpParams().set('UserId', userId);
    return this.http.get<{ result: Favorite[] }>(`${baseUrl}/favorites`, {
      params,
    });
  }

  public removeFavorite(favoriteId: string) {
    const body = { favoriteId };
    return this.http.delete<GenericResponse>(`${baseUrl}/remove`, { body });
  }

  public removeAllFavorites(userId: string) {
    const body = { userId };
    return this.http.delete<GenericResponse>(`${baseUrl}/removeAll`, { body });
  }
}
