import { ThreadService } from 'src/app/services/thread/thread.service';
import { ThreadMessage } from './../../utils/types';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Threads } from 'src/app/utils/types';
import * as moment from 'moment';

@Component({
  selector: 'app-inbox-card',
  templateUrl: './inbox-card.component.html',
  styleUrls: ['./inbox-card.component.scss'],
})
export class InboxCardComponent implements OnChanges {
  @Input() thread!: {
    thread: Threads;
    lastReceivedMsg: ThreadMessage;
  };
  latestMessage: ThreadMessage | undefined;
  latestMessageTime!: string;
  itemName!: string;
  status!: string;
  hasNewMessage = false;
  constructor(private _threadService: ThreadService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const thread: {
      thread: Threads;
      lastReceivedMsg: ThreadMessage;
    } = changes.thread.currentValue;
    if (!changes.thread.previousValue && changes.thread.currentValue) {
      this.subscribeToEvents();
    }
    this.latestMessage = this.thread.lastReceivedMsg;
    this.itemName = thread.thread.type === 1 ? 'Crew / Talents' : 'Listing';
    this.status = this._translateStatus(thread.thread.status);
    this.latestMessageTime = moment(
      Number(this.latestMessage.timestamp)
    ).format('MMM DD, YYYY');
  }

  private _translateStatus(key: number): string {
    const data = [
      'Inquiry',
      'Accepted',
      'Pending',
      'Declined',
      'Completed',
      'Canceled',
    ];
    return data[key - 1];
  }

  private subscribeToEvents(): void {
    const threadId = this.thread.thread.id;
    if (threadId) {
      this.hasNewMessage = this._foundNewMessage(threadId);
    }
    this._threadService.watchMessageStorage().subscribe((data: string) => {
      if (data === `added-${threadId}`) {
        this.hasNewMessage = true;
      }
      if (data === `removed-${threadId}`) {
        this.hasNewMessage = false;
      }
    });
  }

  private _foundNewMessage(threadId: string): boolean {
    const storage = Object.entries(localStorage);
    const regex = new RegExp(threadId);
    return !!storage.find(([key]) => regex.test(key));
  }
}
