<div class="container">
  <p class="h4 text-center m-4">Why Camlyst?</p>
  <div class="content-container">
    <img src="../../../assets/landing_02.jpeg" />
    <div class="text-container p-3">
      <div class="section">
        <p class="h5">Find high quality gear / crew at the best price.</p>
        <p class="text">Whether you need to rent a camera, lens and other film equipment in canada, or hire a
          cinematographer and
          other film
          crew members, we've got it all.</p>
      </div>
      <div class="section">
        <p class="h5">Direct.</p>
        <p class="text">Connect and communicate directly with trusted and verified users. There is no middleman.</p>
      </div>
      <div class="section">
        <p class="h5">Protection.</p>
        <p class="text">$2M libility coverage for all gear rentals. For this to work, all transactions and payments must
          be done
          through Camlyst.</p>
      </div>
      <div class="section">
        <p class="h5">Support.</p>
        <p class="text">24/7 support team available on live chat and phonecall.</p>
      </div>
    </div>
  </div>
</div>