import { FormControl, Validators, FormGroup } from '@angular/forms';
import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Addon } from 'src/app/utils/types';

@Component({
  selector: 'app-addon',
  templateUrl: './addon.component.html',
  styleUrls: ['./addon.component.scss'],
})
export class AddonComponent implements OnChanges {
  addonForm!: FormGroup;
  @Input() readonly?: boolean;
  @Input() addons!: Addon[];
  @Output() addonsChange = new EventEmitter<Addon[]>();
  displayedAddons: Addon[] = [];

  constructor() {
    this.addonForm = new FormGroup({
      title: new FormControl(null, Validators.required),
      rate: new FormControl(null, Validators.required),
      replacementValue: new FormControl(null, Validators.required),
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.readonly ||
      (Array.isArray(changes.addons.previousValue) &&
        !changes.addons.previousValue.length)
    ) {
      this._patchAddons();
    }
  }

  private _patchAddons(): void {
    if (Array.isArray(this.addons)) {
      this.addons.forEach((addon) => {
        this.displayedAddons.push(addon);
      });
    }
  }

  add() {
    this.addonForm.markAllAsTouched();
    if (this.addonForm.valid) {
      if (!Array.isArray(this.addons)) {
        this.addons = [];
      }
      this.addons.push(this.addonForm.value);
      this.addonsChange.emit(this.addons);
      this.displayedAddons = this.addons.filter((_addon) => _addon.title);
      if (this.displayedAddons.length > 4) {
        this.addonForm.disable();
      }
      this.addonForm.reset();
    }
  }

  remove(addon: Addon) {
    // Blocked until I know how to delete an addon
    this.addons = this.addons.map((item) => {
      if (
        (item.id && item.id === addon.id) ||
        (!item.id && item.title === addon.title)
      ) {
        delete item.title;
        delete item.rate;
        delete item.replacementValue;
      }
      return item;
    });
    this.displayedAddons = this.addons.filter((_addon) => _addon.title);
    this.addonsChange.emit(this.addons);
    if (this.displayedAddons.length < 5) {
      this.addonForm.enable();
    }
  }
}
