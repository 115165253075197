import { NotificationService } from 'src/app/services/notification/notification.service';
import { AccountService } from 'src/app/services/account/account.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
})
export class PhoneComponent implements OnInit {
  userId?: string;
  phoneNumberForm!: FormGroup;
  verificationCodeForm!: FormGroup;
  phoneNumber?: string;
  code?: string;

  constructor(
    private _formBuilder: FormBuilder,
    private _accountService: AccountService,
    private _notificationService: NotificationService
  ) {
    this.userId = this._accountService.currentUser?.name;
  }

  ngOnInit() {
    this.phoneNumberForm = this._formBuilder.group({
      phoneNumber: ['', Validators.required],
    });
    this.verificationCodeForm = this._formBuilder.group({
      verificationCode: ['', Validators.required],
    });
  }

  verifyPhone(stepper: MatStepper) {
    if (!this.phoneNumberForm.valid || !this.userId) {
      return;
    }
    this.phoneNumber = this.phoneNumberForm.value.phoneNumber;
    if (!this.phoneNumber) {
      return;
    }
    this._accountService
      .verifyPhone(this.userId, this.phoneNumber)
      .subscribe((res) => {
        if (res.isSuccess) {
          this._notificationService.success(
            'Check your phone for the verification code'
          );
          stepper.next();
        }
      });
  }

  verifyCode(stepper: MatStepper) {
    if (!this.verificationCodeForm.valid || !this.userId) {
      return;
    }
    this.code = this.verificationCodeForm.value.verificationCode;
    if (!this.code || !this.phoneNumber) {
      return;
    }
    const data = {
      phoneNumber: this.phoneNumber,
      code: this.code,
      userId: this.userId,
    };
    this._accountService.verifyPhoneCode(data).subscribe((res) => {
      if (res.isSuccess) {
        this._notificationService.success(
          'Your phone number has been verified'
        );
        stepper.next();
        this.phoneNumberForm.reset();
        this.verificationCodeForm.reset();
        this.phoneNumber = undefined;
        this.code = undefined;
      }
    });
  }

  resendCode() {
    if (!this.phoneNumber || !this.userId) {
      return;
    }
    this._accountService
      .verifyPhone(this.userId, this.phoneNumber)
      .subscribe((res) => {
        if (res.isSuccess) {
          this._notificationService.success(
            'Check your phone for the new verification code'
          );
        }
      });
  }
}
