import { environment } from 'src/environments/environment';
import { AccountService } from 'src/app/services/account/account.service';
import { NotificationService } from './services/notification/notification.service';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { LoadingService } from './services/loading/loading.service';
import { HttpRequestInterceptor } from './interceptors/http-request.interceptor';
import { DashboardProfileComponent } from './pages/dashboard/dashboard-profile/dashboard-profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppMaterialModule } from './app-material.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AppRoutingModule } from './app-routing.module';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { GetToken, CUSTOM_DATE_FORMATS } from './utils';
import {
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { AppComponent } from './app.component';
import { LandingComponent } from './pages/landing/landing.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HowComponent } from './components/how/how.component';
import { WhyKreativeComponent } from './components/why-kreative/why-kreative.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { FacebookModule } from 'ngx-facebook';
import { SearchFormComponent } from './components/search-form/search-form.component';
import { ListItemBannerComponent } from './components/list-item-banner/list-item-banner.component';
import { RentalCategoryGridComponent } from './components/rental-category-grid/rental-category-grid.component';
import { SignupVerificationComponent } from './components/signup-verification/signup-verification.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ModalRouteEntryComponent } from './pages/modal-route-entry/modal-route-entry.component';
import { DashboardPasswordComponent } from './pages/dashboard/dashboard-password/dashboard-password.component';
import { DashboardVerificationComponent } from './pages/dashboard/dashboard-verification/dashboard-verification.component';
import { DashboardPayoutComponent } from './pages/dashboard/dashboard-payout/dashboard-payout.component';
import { DashboardRestrictionsComponent } from './pages/dashboard/dashboard-restrictions/dashboard-restrictions.component';
import { DashboardDeleteComponent } from './pages/dashboard/dashboard-delete/dashboard-delete.component';
import { DashboardControlsComponent } from './components/dashboard-controls/dashboard-controls.component';
import { NotificationComponent } from './components/notification/notification.component';
import { InnerNavbarComponent } from './components/inner-navbar/inner-navbar.component';
import { PhoneComponent } from './pages/dashboard/dashboard-verification/phone/phone.component';
import { GovtIdComponent } from './pages/dashboard/dashboard-verification/govt-id/govt-id.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { CrewsComponent } from './pages/crew-talent/crews/crews.component';
import { ViewCrewComponent } from './pages/crew-talent/view-crew/view-crew.component';
import { CrewFormComponent } from './pages/crew-talent/crew-form/crew-form.component';
import { CrewCardComponent } from './components/crew-card/crew-card.component';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { CrewBtnComponent } from './components/crew-btn/crew-btn.component';
import { FileDropComponent } from './components/file-drop/file-drop.component';
import { ReviewComponent } from './components/review/review.component';
import { VerifyEmailFormComponent } from './components/verify-email-form/verify-email-form.component';
import { MyListingsComponent } from './pages/listings/my-listings-container/my-listings/my-listings.component';
import { ListingFormComponent } from './pages/listings/my-listings-container/listing-form/listing-form.component';
import { MyListingsContainerComponent } from './pages/listings/my-listings-container/my-listings-container.component';
import { AddonComponent } from './components/addon/addon.component';
import { MyListingCardComponent } from './components/my-listing-card/my-listing-card.component';
import { DeleteListingComponent } from './components/delete-listing/delete-listing.component';
import { ListingsComponent } from './pages/listings/listings/listings.component';
import { ListingCardComponent } from './components/listing-card/listing-card.component';
import { ViewListingComponent } from './components/view-listing/view-listing.component';
import { RentFormComponent } from './components/rent-form/rent-form.component';
import { WishlistComponent } from './pages/wishlist/wishlist.component';
import { CrewWishlistComponent } from './pages/wishlist/crew-wishlist/crew-wishlist.component';
import { ListingWishlistComponent } from './pages/wishlist/listing-wishlist/listing-wishlist.component';
import { InquiryMessageComponent } from './pages/messages/inquiry-message/inquiry-message.component';
import { InboxComponent } from './pages/messages/inbox/inbox.component';
import { InboxCardComponent } from './components/inbox-card/inbox-card.component';
import { BookingFormComponent } from './components/booking-form/booking-form.component';
import { ThreadComponent } from './pages/messages/thread/thread.component';
import { MessageBlockComponent } from './components/message-block/message-block.component';
import { BookingInviteFormComponent } from './components/booking-invite-form/booking-invite-form.component';
import { BookingInviteReviewComponent } from './components/booking-invite-review/booking-invite-review.component';
import { BookingsComponent } from './pages/bookings/bookings.component';
import { InboundBookingsComponent } from './pages/bookings/inbound-bookings/inbound-bookings.component';
import { OutboundBookingsComponent } from './pages/bookings/outbound-bookings/outbound-bookings.component';
import { BookingCardComponent } from './components/booking-card/booking-card.component';
import { PaymentIntentComponent } from './components/payment-intent/payment-intent.component';
import { NgxStripeModule } from 'ngx-stripe';
import { EditBookingsComponent } from './pages/bookings/edit-bookings/edit-bookings.component';
import { BookingsContainerComponent } from './pages/bookings/bookings-container/bookings-container.component';
import { BookingChangeReviewComponent } from './components/booking-change-review/booking-change-review.component';
import { ViewBookingComponent } from './pages/bookings/view-booking/view-booking.component';
import { CalendarPageComponent } from './pages/calendar-page/calendar-page.component';
import {
  CalendarModule,
  DateAdapter as CalDateAdapter,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { StatementsComponent } from './components/statements/statements.component';
import { PrivacyStatementsComponent } from './components/privacy-statements/privacy-statements.component';
import { VerificationsComponent } from './pages/dashboard/dashboard-verification/verifications/verifications.component';
import { GovtIdSubmitDialogComponent } from './pages/dashboard/dashboard-verification/govt-id/govt-id-submit-dialog/govt-id-submit-dialog.component';

const STRIPE_PUBLISHABLE_KEY = environment.STRIPE_PUBLISHABLE_KEY;

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    NavbarComponent,
    SearchFormComponent,
    HowComponent,
    RentalCategoryGridComponent,
    WhyKreativeComponent,
    ListItemBannerComponent,
    FooterComponent,
    LoginComponent,
    SignupComponent,
    SignupVerificationComponent,
    DashboardComponent,
    ModalRouteEntryComponent,
    DashboardProfileComponent,
    DashboardPasswordComponent,
    DashboardVerificationComponent,
    DashboardPayoutComponent,
    DashboardRestrictionsComponent,
    DashboardDeleteComponent,
    DashboardControlsComponent,
    NotificationComponent,
    InnerNavbarComponent,
    PhoneComponent,
    GovtIdComponent,
    ForgotPasswordComponent,
    CrewsComponent,
    ViewCrewComponent,
    CrewFormComponent,
    CrewCardComponent,
    CrewBtnComponent,
    FileDropComponent,
    ReviewComponent,
    VerifyEmailFormComponent,
    MyListingsComponent,
    ListingFormComponent,
    MyListingsContainerComponent,
    AddonComponent,
    MyListingCardComponent,
    DeleteListingComponent,
    ListingsComponent,
    ListingCardComponent,
    ViewListingComponent,
    RentFormComponent,
    WishlistComponent,
    CrewWishlistComponent,
    ListingWishlistComponent,
    InquiryMessageComponent,
    InboxComponent,
    InboxCardComponent,
    BookingFormComponent,
    ThreadComponent,
    MessageBlockComponent,
    BookingInviteFormComponent,
    BookingInviteReviewComponent,
    BookingsComponent,
    InboundBookingsComponent,
    OutboundBookingsComponent,
    BookingCardComponent,
    PaymentIntentComponent,
    EditBookingsComponent,
    BookingsContainerComponent,
    BookingChangeReviewComponent,
    ViewBookingComponent,
    CalendarPageComponent,
    StatementsComponent,
    PrivacyStatementsComponent,
    VerificationsComponent,
    GovtIdSubmitDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    HttpClientModule,
    MatMomentDateModule,
    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModule.forRoot(),
    FacebookModule.forRoot(),
    NgbRatingModule,
    NgxStripeModule.forRoot(STRIPE_PUBLISHABLE_KEY),
    CalendarModule.forRoot({
      provide: CalDateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      deps: [LoadingService, AccountService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [NotificationService],
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
