import { Component, Input, OnInit } from '@angular/core';
import { CrewService } from 'src/app/services/crew/crew.service';
import { ListingsService } from 'src/app/services/listings/listings.service';
import { BookingStatus } from 'src/app/utils/enums';
import { Booking } from 'src/app/utils/types';

const NO_PROFILE_PIC = '../../../../assets/default_user.png';
const NO_LISTING_PIC = '../../../../assets/no-image-available.png';

@Component({
  selector: 'app-booking-card',
  templateUrl: './booking-card.component.html',
  styleUrls: ['./booking-card.component.scss'],
})
export class BookingCardComponent implements OnInit {
  @Input() booking!: Booking;
  profile!: {
    picture: string;
    name?: string;
  };
  isCanceled = false;
  constructor(
    private _crewService: CrewService,
    private _listingService: ListingsService
  ) {}

  ngOnInit(): void {
    if (this.booking) {
      this.isCanceled = this.booking.status === BookingStatus.Canceled;
      this._getProfile(this.booking);
    }
  }

  _getProfile(booking: Booking) {
    if (booking.type === 'crew' && booking.crewId) {
      this._crewService.getCrew(booking.crewId.toString()).subscribe((res) => {
        this.profile = {
          picture: res.result.user.profilePicture || NO_PROFILE_PIC,
          name: res.result.user.firstName,
        };
      });
    }
    if (booking.type === 'listing' && booking.listingId) {
      this._listingService.getListing(booking.listingId).subscribe((res) => {
        this.profile = {
          picture:
            res.result?.imageUrl1 ||
            res.result?.imageUrl2 ||
            res.result?.imageUrl3 ||
            res.result?.imageUrl4 ||
            res.result?.imageUrl5 ||
            NO_LISTING_PIC,
          name: res.result?.title,
        };
      });
    }
  }
}
