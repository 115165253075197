import {
  PostalCodePattern,
  UrlPatterns,
} from './../../../utils/validator-patterns';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account/account.service';
import { AutocompleteValidators } from './../../../utils/autocomplete.validators';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { getOptions } from './../../../utils/select-options';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Place, Tag } from 'src/app/utils/types';
import { CrewService } from 'src/app/services/crew/crew.service';
import { BlobStorageService } from 'src/app/services/blogStorage/blob-storage.service';
import { LocationService } from 'src/app/services/location/location.service';

@Component({
  selector: 'app-crew-form',
  templateUrl: './crew-form.component.html',
  styleUrls: ['./crew-form.component.scss'],
})
export class CrewFormComponent implements OnInit {
  crewForm!: FormGroup;
  tag: FormControl = new FormControl();
  tags!: Tag[];
  filteredTags!: Observable<Tag[]>;
  currentTags: string[] = [];
  editableTags: { id?: string; description: string | null }[] = [];
  cities!: Place[];
  filteredCities!: Observable<Place[]>;
  provinceOptions!: Place[];

  image1!: File | null;
  image2!: File | null;
  image3!: File | null;

  imageURL1!: string | null;
  imageURL2!: string | null;
  imageURL3!: string | null;

  // Paths used for editing the form
  imagePath1!: SafeUrl;
  imagePath2!: SafeUrl;
  imagePath3!: SafeUrl;

  imageCountArray: Set<string> = new Set();

  isEdit = false;
  agree = false;
  showCity = false;
  isTagValid = false;
  genderOptions = getOptions('gender');
  ageBracketOptions = getOptions('ageBracket');
  levelsOfExp = getOptions('levelsOfExp');
  hearAbtUsOptions = getOptions('ads');

  showPrivacyPolicy = false;

  constructor(
    private _crewService: CrewService,
    private _blobStorage: BlobStorageService,
    private _notificationService: NotificationService,
    private _locationService: LocationService,
    private _accountService: AccountService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {
    this.crewForm = this._generateForm();
  }

  ngOnInit(): void {
    this._crewService
      .tags()
      .subscribe((res) => (this.tags = res?.result?.data));

    this._locationService.provinces().subscribe((res) => {
      this.provinceOptions = res.result;
    });

    this.filteredTags = this.tag.valueChanges.pipe(
      startWith(''),
      map((value: string) =>
        value && value.length >= 1 ? this._filterTags(value) : []
      )
    );

    this.filteredCities = this.crewForm.controls.city.valueChanges.pipe(
      startWith(''),
      map((value: string) =>
        value.length >= 1 ? this._filterCities(value) : []
      )
    );

    // Get user crew if it exists
    this._getUserCrew();
  }

  private _generateForm(): FormGroup {
    return new FormGroup({
      companyName: new FormControl(null, Validators.required),
      gender: new FormControl(),
      ageBracket: new FormControl(),
      address: new FormControl(null, Validators.required),
      postalCode: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(PostalCodePattern),
        ])
      ),
      city: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          AutocompleteValidators.valueSelected(
            this._cityValidatorFn.bind(this)
          ),
        ])
      ),
      province: new FormControl(null, Validators.required),
      phoneNo: new FormControl(null, Validators.required),
      email: new FormControl(
        null,
        Validators.compose([Validators.required, Validators.email])
      ),
      aboutMe: new FormControl(null, Validators.required),
      levelOfExperience: new FormControl(null, Validators.required),
      howDidYouHearAboutUs: new FormControl(null),
      linkToReel: new FormControl(
        null,
        Validators.pattern(UrlPatterns.youtubeOrVimeo)
      ),
      cancellation: new FormControl(null, Validators.required),
      visibility: new FormControl(true, Validators.required),
    });
  }

  private _getUserCrew(): void {
    this._accountService.getProfile().subscribe((res) => {
      if (res.isSuccess && res.user.crew) {
        this.isEdit = true;
        const {
          city,
          crewTag,
          imageUrl1,
          imageUrl2,
          imageUrl3,
          province,
        } = res.user.crew;
        const data = {
          ...res.user.crew,
          city: city.name,
          province: province.id,
        };
        if (province) {
          this.showCity = true;
          this._locationService.cities(province.id).subscribe((citiesRes) => {
            this.cities = citiesRes.result;
            this.crewForm.patchValue(data);
          });
        } else {
          this.crewForm.patchValue(data);
        }
        this._patchTags(crewTag);
        // pass the file to the file-drop component
        if (imageUrl1) {
          this.imageURL1 = imageUrl1;
          this.imageCountArray.add('image1');
          this._setImagePath(imageUrl1, 1);
        }
        if (imageUrl2) {
          this.imageURL2 = imageUrl2;
          this.imageCountArray.add('image2');
          this._setImagePath(imageUrl2, 2);
        }
        if (imageUrl3) {
          this.imageURL3 = imageUrl3;
          this.imageCountArray.add('image3');
          this._setImagePath(imageUrl3, 3);
        }
      }
    });
  }

  private _setImagePath(imageUrl: string, position: number): void {
    this._blobStorage.getFile(imageUrl).subscribe((fileBlob) => {
      const objectURL = URL.createObjectURL(fileBlob);
      if (position === 1) {
        this.imagePath1 = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      }
      if (position === 2) {
        this.imagePath2 = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      }
      if (position === 3) {
        this.imagePath3 = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      }
    });
  }
  private _filterTags(value: string): Tag[] {
    const filterValue = value.toLowerCase();
    return this.tags.filter((option: Tag) =>
      option.description.toLowerCase().includes(filterValue)
    );
  }

  private _filterCities(value: string): Place[] {
    const filterValue = value.toLowerCase();
    return this.cities.filter((option: Place) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _cityValidatorFn(): Place[] {
    return this.cities;
  }

  private _patchTags(tags: { id?: string; description: string }[]): void {
    if (Array.isArray(tags)) {
      tags.forEach((tag) => {
        this.currentTags.push(tag.description);
        this.editableTags.push(tag);
      });
      if (this.currentTags.length > 4) {
        this.tag.disable();
      }
    }
  }

  onProvinceChange(): void {
    this.crewForm.controls.city.setValue('');
    const province = this.crewForm.controls.province.value;
    this.showCity = province ? true : false;
    this._locationService.cities(province).subscribe((res) => {
      this.cities = res.result;
    });
  }

  onAddTag(): void {
    this.isTagValid = this.tags.some(
      (tag) => tag.description === this.tag.value
    );
    if (this.isTagValid && !this.currentTags.includes(this.tag.value)) {
      this.currentTags.push(this.tag.value);
      this.editableTags.push({ description: this.tag.value });
      if (this.currentTags.length > 4) {
        this.tag.disable();
      }
    }
  }

  removeTag(tag: string): void {
    this.currentTags = this.currentTags.filter((item) => item !== tag);
    this.editableTags = this.editableTags.map((item) => {
      if (item.description === tag) {
        item.description = null;
      }
      return item;
    });
    if (this.currentTags.length < 5) {
      this.tag.enable();
    }
  }

  onImageUploadChange(fileObject: any): void {
    switch (fileObject.fileId) {
      case 'image1':
        if (fileObject.file === null) {
          this.imageCountArray.delete('image1');
        } else {
          this.imageCountArray.add('image1');
        }
        this.image1 = fileObject.file;
        break;
      case 'image2':
        if (fileObject.file === null) {
          this.imageCountArray.delete('image2');
        } else {
          this.imageCountArray.add('image2');
        }
        this.image2 = fileObject.file;
        break;
      case 'image3':
        if (fileObject.file === null) {
          this.imageCountArray.delete('image3');
        } else {
          this.imageCountArray.add('image3');
        }
        this.image3 = fileObject.file;
        break;
      default:
        break;
    }
  }

  async onImageUpload(
    file: File,
    uid: string,
    imageNum: string
  ): Promise<string | null> {
    const blobName = `${uid}-${imageNum}-crew-picture`;
    if (file) {
      const res = await this._blobStorage.uploadFile(file, blobName);
      if (res.isSuccess) {
        return res.imageUrl;
      } else {
        // Upload Error
        this._notificationService.error(res.message);
        return null;
      }
    }
    return null;
  }

  async onImageDelete(imageUrl: string | null): Promise<string | null> {
    const blobName = imageUrl
      ? imageUrl
          .split('https://kreativecitusa.blob.core.windows.net/images/')
          .pop()
          ?.split('?')
          .shift()
      : null;
    if (blobName) {
      const res = await this._blobStorage.deleteFile(blobName);
      if (res.isSuccess) {
        return null;
      } else {
        // Delete Error
        this._notificationService.error(res.message);
        return imageUrl;
      }
    }
    return null;
  }

  async submit(): Promise<void> {
    this.crewForm.markAllAsTouched();
    this.tag.markAsTouched();
    if (this.crewForm.valid && this.currentTags.length > 0) {
      const uid = this._accountService?.currentUser?.name;

      if (typeof this.image1 !== 'undefined') {
        if (this.imageCountArray.size === 0) {
          this._notificationService.error('You must upload at least one image');
          return;
        }
        if (this.image1 && uid) {
          this.imageURL1 = await this.onImageUpload(
            this.image1,
            uid,
            'image-01'
          );
        } else {
          this.imageURL1 = await this.onImageDelete(this.imageURL1);
        }
      }
      if (typeof this.image2 !== 'undefined') {
        if (this.image2 && uid) {
          this.imageURL2 = await this.onImageUpload(
            this.image2,
            uid,
            'image-02'
          );
        } else {
          this.imageURL2 = await this.onImageDelete(this.imageURL2);
        }
      }
      if (typeof this.image3 !== 'undefined') {
        if (this.image3 && uid) {
          this.imageURL3 = await this.onImageUpload(
            this.image3,
            uid,
            'image-03'
          );
        } else {
          this.imageURL3 = await this.onImageDelete(this.imageURL3);
        }
      }
      const data = {
        ...this.crewForm.value,
        ...(this.isEdit
          ? { tags: this.editableTags }
          : { tags: this.currentTags }),
        ...(!this.isEdit && { userId: uid }),
        imageUrl1: this.imageURL1,
        imageUrl2: this.imageURL2,
        imageUrl3: this.imageURL3,
      };
      if (this.isEdit) {
        this._crewService.updateCrew(data).subscribe((res) => {
          if (res.isSuccess) {
            this._notificationService.success(res.message);
            this.router.navigate(['crew']);
          }
        });
      } else {
        this._crewService.createCrew(data).subscribe((res) => {
          if (res.isSuccess) {
            this._notificationService.success(res.message);
            this.router.navigate(['crew']);
          }
        });
      }
    }
  }

  togglePrivacyPolicy(evt: Event) {
    this.showPrivacyPolicy = !this.showPrivacyPolicy;
    evt.preventDefault();
  }

  onShowUserPolicy(evt: boolean) {
    this.showPrivacyPolicy = evt;
  }
}
