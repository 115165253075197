import { Component, OnInit, Input } from '@angular/core';
import { Crew } from 'src/app/utils/types';

const DUMMY_IMAGE = '../../../assets/no-image-available.png';
@Component({
  selector: 'app-crew-card',
  templateUrl: './crew-card.component.html',
  styleUrls: ['./crew-card.component.scss'],
})
export class CrewCardComponent implements OnInit {
  @Input() crew!: Crew;
  cardImageUrl!: string;
  constructor() {}

  ngOnInit(): void {
    this.cardImageUrl =
      this.crew.imageUrl1 ??
      this.crew.imageUrl2 ??
      this.crew.imageUrl3 ??
      DUMMY_IMAGE;
  }
}
