import { Review } from './../../utils/types';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent implements OnInit {
  @Input() value!: Review;
  constructor() {}

  ngOnInit(): void {}
}
