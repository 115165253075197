<mat-dialog-content class="mat-typography">
  <button type="button" class="close" aria-label="Close" mat-dialog-close>
    <span aria-hidden="true">×</span>
  </button>
  <div style="
    max-width: 480px;
    word-wrap: break-word;
    margin: 32px;">
    <p class="h3 text-center">Thanks!</p>
    <p class="lead">We'll email you when your document is verified.</p>
    <p class="lead">
      Once verified, you'll be able to receive and make bookings / rentals, contact other users in the community
      and add payout / payment methods.
    </p>
    <p class="lead">
      If you receive an email saying your verification was declined, you can click
      the link inside the email to try again or contact us for help.
    </p>
  </div>
</mat-dialog-content>