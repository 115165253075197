import {
  AccountEntryType,
  AccountEntryStatus,
  EditBookingState,
} from './../../utils/enums';
import { BookingAccountService } from './../../services/bookingAccount/booking-account.service';
import { Location } from '@angular/common';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { BookingService } from 'src/app/services/booking/booking.service';
import { ThreadService } from 'src/app/services/thread/thread.service';
import {
  Booking,
  BookingAccount,
  BookingAccountEntry,
  GenericResponse,
  Threads,
} from 'src/app/utils/types';
import { BookingStatus, ThreadStatus } from 'src/app/utils/enums';
import { AccountService } from 'src/app/services/account/account.service';
import { calcFraction, calcTaxes, calcTotal } from 'src/app/utils/calculate';

@Component({
  selector: 'app-booking-invite-review',
  templateUrl: './booking-invite-review.component.html',
})
export class BookingInviteReviewComponent {
  bookingId!: string;
  booking!: Booking;
  thread!: Threads;
  cost!: {
    rental: number;
    taxes: number;
    subTotal: number;
    securityDeposit: number;
    total: number;
  };
  displayedCost!: {
    rental: string;
    taxes: string;
    subTotal: string;
    securityDeposit: string;
    total: string;
  };
  hasInitiatedPayment = false;
  isOwnerReview = false;
  showUserPolicy = false;

  constructor(
    private _route: ActivatedRoute,
    private _bookingService: BookingService,
    private _threadService: ThreadService,
    private _notificationService: NotificationService,
    private _location: Location,
    private _bookingAccountService: BookingAccountService,
    private _accountService: AccountService
  ) {
    this._route.queryParams.subscribe((params) => {
      this.bookingId = params.bookingId;
      this.isOwnerReview = Boolean(params.ownerReview);
      if (this.bookingId) {
        this._getBooking(this.bookingId);
      }
    });
  }

  private _getBooking(id: string) {
    this._bookingService.getById(id).subscribe((res) => {
      this.booking = res[0];
      const { taxes, subTotal } = this._calcSubTotal(
        this.booking.totalAmountAgreed
      );
      const { securityDeposit, total } = this._calcTotal(
        this.booking.totalAmountAgreed,
        subTotal
      );
      this.cost = {
        rental: this.booking.totalAmountAgreed,
        taxes,
        subTotal,
        securityDeposit,
        total,
      };
      this.displayedCost = {
        rental: this.booking.totalAmountAgreed.toFixed(2),
        taxes: taxes.toFixed(2),
        subTotal: subTotal.toFixed(2),
        securityDeposit: securityDeposit.toFixed(2),
        total: total.toFixed(2),
      };
    });
  }

  toggleUserPolicy(evt: Event) {
    this.showUserPolicy = !this.showUserPolicy;
    evt.preventDefault();
  }

  onShowUserPolicy(evt: boolean) {
    this.showUserPolicy = evt;
  }

  private _calcSubTotal(rental: number): { taxes: number; subTotal: number } {
    const taxes = calcTaxes(rental);
    return {
      taxes,
      subTotal: calcTotal([taxes, rental]),
    };
  }

  // Using 20% of total cost
  // This could change to something else
  private _calcTotal(
    rental: number,
    subTotal: number
  ): { securityDeposit: number; total: number } {
    const securityDeposit = calcFraction({ amount: rental, percentage: 20 });
    return {
      securityDeposit,
      total: calcTotal([subTotal, securityDeposit]),
    };
  }

  decline(): void {
    // Get Thread
    let thread: Threads;
    this._getThread(this.booking.threadId)
      .then((threads) => {
        if (threads.result) {
          thread = threads.result[0];
          if (thread.id) {
            // Update thread status
            return this._updateThreadStatus(thread.id, ThreadStatus.Declined);
          }
        }
        return;
      })
      .then((updateThreadRes) => {
        if (
          updateThreadRes &&
          updateThreadRes.result.isSuccess &&
          this.booking.id
        ) {
          // Update booking status
          return this._updateBookingStatus(
            this.booking.id,
            BookingStatus.Declined
          );
        }
        return;
      })
      .then((updateBookingRes) => {
        if (updateBookingRes && updateBookingRes.isSuccess && thread) {
          const messageText =
            'AUTOMATED MESSAGE - Your booking has been declined';
          return this._sendAutoMsg(thread, messageText);
        }
        return;
      })
      .then((messageRes) => {
        if (messageRes && messageRes.isSuccess) {
          this._notificationService.success(
            'The Booking invitation has been declined'
          );
          this._location.back();
        } else {
          this._notificationService.error('Something went wrong');
        }
      });
  }

  accept(): void {
    if (this.isOwnerReview) {
      this.onOwnerApproval();
    } else {
      this.hasInitiatedPayment = true;
    }
  }

  onOwnerApproval() {
    let thread: Threads;
    this._getThread(this.booking.threadId)
      .then((threads) => {
        if (threads.result) {
          thread = threads.result[0];
          if (thread.id) {
            // Update thread status to Accepted
            return this._updateThreadStatus(thread.id, ThreadStatus.Accepted);
          }
        }
        return;
      })
      .then((updateThreadRes) => {
        if (
          updateThreadRes &&
          updateThreadRes.result.isSuccess &&
          this.booking.id
        ) {
          // Update booking status to Accepted
          return this._updateBookingStatus(
            this.booking.id,
            BookingStatus.Accepted
          );
        }
        return;
      })
      .then((updateBookingRes) => {
        if (
          updateBookingRes &&
          updateBookingRes.isSuccess &&
          this.booking.id &&
          thread
        ) {
          // Create EditBooking entry with request payment of the total amount
          return this._createEditBooking(this.booking.id, this.cost.total);
        }
        return;
      })
      .then((editBookingRes) => {
        if (
          editBookingRes &&
          editBookingRes.isSuccess &&
          this.booking.id &&
          thread
        ) {
          // Create Booking Account
          return this._createBookingAccount({
            bookingId: this.booking.id,
            customerId: thread.ownerId,
            ownerId: thread.coOwnerId,
            crewId: thread.crewId,
            listingId: thread.listingId,
            created: Date.now().toString(),
          });
        }
        return;
      })
      .then((accountRes) => {
        if (accountRes && accountRes.isSuccess && thread) {
          const messageText =
            'AUTOMATED MESSAGE - Your booking request has been accepted';
          return this._sendAutoMsg(thread, messageText);
        }
        return;
      })
      .then((messageRes) => {
        if (messageRes && messageRes.isSuccess) {
          this._location.back();
        } else {
          this._notificationService.error('Something went wrong');
        }
      });
  }

  onPaymentResult(result: { success: boolean; amount: number }): void {
    let thread: Threads;
    if (result.success) {
      this._getThread(this.booking.threadId)
        .then((threads) => {
          if (threads.result) {
            thread = threads.result[0];
            if (thread.id) {
              // Update thread status
              return this._updateThreadStatus(thread.id, ThreadStatus.Accepted);
            }
          }
          return;
        })
        .then((updateThreadRes) => {
          if (
            updateThreadRes &&
            updateThreadRes.result.isSuccess &&
            this.booking.id
          ) {
            // Update booking status
            return this._updateBookingStatus(
              this.booking.id,
              BookingStatus.Paid
            );
          }
          return;
        })
        .then((updateBookingRes) => {
          if (
            updateBookingRes &&
            updateBookingRes.isSuccess &&
            this.booking.id &&
            thread
          ) {
            // Create new booking account
            return this._createBookingAccount({
              bookingId: this.booking.id,
              customerId: thread.ownerId,
              ownerId: thread.coOwnerId,
              crewId: thread.crewId,
              listingId: thread.listingId,
              created: Date.now().toString(),
            });
          }
          return;
        })
        .then((accountRes) => {
          // Use newly created booking account ID to create new account entry
          if (accountRes && accountRes.isSuccess && accountRes.insertedId) {
            return this._createBookingAccountEntry({
              accountId: accountRes.insertedId,
              amount: this.cost.total,
              note: 'Made initial payment for booking',
              type: AccountEntryType.Payment,
              initiatorId: thread.ownerId,
              status: AccountEntryStatus.Complete,
              created: Date.now().toString(),
              lastModified: Date.now().toString(),
            });
          }
          return;
        })
        .then((accountEntryRes) => {
          if (accountEntryRes && accountEntryRes.isSuccess && thread) {
            const messageText =
              'AUTOMATED MESSAGE - Your booking request has been accepted and paid for';
            return this._sendAutoMsg(thread, messageText);
          }
          return;
        })
        .then((messageRes) => {
          if (messageRes && messageRes.isSuccess) {
            this.hasInitiatedPayment = false;
            this._location.back();
          } else {
            this._notificationService.error('Something went wrong');
          }
        });
    } else {
      this._notificationService.error('Something went wrong');
    }
  }

  private _getThread(id: string): Promise<{ result: Threads[] }> {
    return this._threadService.getThread(id).toPromise();
  }

  private _updateThreadStatus(
    id: string,
    status: ThreadStatus
  ): Promise<{ result: GenericResponse }> {
    return this._threadService.update(id, { status }).toPromise();
  }

  private _updateBookingStatus(
    id: string,
    status: BookingStatus
  ): Promise<GenericResponse> {
    return this._bookingService.update({ id, status }).toPromise();
  }

  private _createBookingAccount(
    data: BookingAccount
  ): Promise<GenericResponse> {
    return this._bookingAccountService.create(data).toPromise();
  }

  private _createBookingAccountEntry(
    data: BookingAccountEntry
  ): Promise<GenericResponse> {
    return this._bookingAccountService.createAccountEntry(data).toPromise();
  }

  private _createEditBooking(
    bookingId: string,
    amount: number
  ): Promise<GenericResponse | null> {
    const userId = this._accountService.currentUser?.name;
    if (!userId) {
      return new Promise((resolve) => resolve(null));
    }
    const data = {
      bookingId,
      cancel: false,
      requestPayment: amount,
      reason: 'AUTOMATED - Booking invite has been accepted',
      state: EditBookingState.Initiated,
      initiatorId: userId,
      created: Date.now().toString(),
      lastModified: Date.now().toString(),
    };
    return this._bookingService.createEditBooking(data).toPromise();
  }

  private _sendAutoMsg(
    thread: Threads,
    messageText: string
  ): Promise<GenericResponse | null> {
    const senderId = this._accountService.currentUser?.name;
    if (!senderId) {
      return new Promise((resolve) => resolve(null));
    }
    const receiverId =
      thread.ownerId === senderId ? thread.coOwnerId : thread.ownerId;
    const timestamp = Date.now().toString();
    const data = {
      senderId,
      receiverId,
      timestamp,
      messageText,
    };
    if (thread.id) {
      return this._threadService.createMessage(thread.id, data);
    }
    return new Promise((resolve) => resolve(null));
  }
}
