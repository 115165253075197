import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Crew, Favorite } from 'src/app/utils/types';
import { CrewService } from 'src/app/services/crew/crew.service';

@Component({
  selector: 'app-crew-wishlist',
  templateUrl: './crew-wishlist.component.html',
})
export class CrewWishlistComponent implements OnChanges {
  @Input() favorites!: Favorite[];
  crews: Crew[] = [];
  constructor(private _crewService: CrewService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      Array.isArray(changes.favorites.currentValue) &&
      changes.favorites.currentValue.length > 0
    ) {
      changes.favorites.currentValue.forEach((fav) => {
        const crewId = fav.crewId;
        this._crewService.getCrew(crewId).subscribe((res) => {
          this.crews.push(res.result);
        });
      });
    }
  }
}
