import { ThreadService } from 'src/app/services/thread/thread.service';
import { Location } from '@angular/common';
import { EditBookingState } from './../../../utils/enums';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component } from '@angular/core';
import { BookingService } from 'src/app/services/booking/booking.service';
import { Booking, GenericResponse } from 'src/app/utils/types';
import { AccountService } from 'src/app/services/account/account.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ListingsService } from 'src/app/services/listings/listings.service';
import { CrewService } from 'src/app/services/crew/crew.service';
import { getCancelPolicy } from 'src/app/utils/cancel-policies';

@Component({
  selector: 'app-edit-bookings',
  templateUrl: './edit-bookings.component.html',
  styleUrls: ['./edit-bookings.component.scss'],
})
export class EditBookingsComponent {
  cancel = false;
  cancelReasons = [
    { value: 'I found something else' },
    { value: 'Availability' },
    { value: 'I changed my mind' },
    { value: 'Issue with price' },
    { value: 'Booked by accident' },
    { value: 'Emergency' },
    { value: 'Cancelled production' },
    { value: 'Production rescheduled' },
    { value: 'Issues with equipment' },
    { value: 'Prefer not to say' },
  ];
  changeDate = false;
  range = new FormGroup({
    start: new FormControl(null, Validators.required),
    end: new FormControl(null, Validators.required),
  });
  reason = new FormControl();
  requestPayment = new FormControl();
  requestRefund = new FormControl();
  today: Date = new Date();
  notes = new FormControl();
  booking!: Booking;
  userId?: string;
  isCustomer = false;
  cancelPolicy?: string;

  constructor(
    private _bookingService: BookingService,
    private _accountService: AccountService,
    private _listingService: ListingsService,
    private _crewService: CrewService,
    private _route: ActivatedRoute,
    private _notificationService: NotificationService,
    private _location: Location,
    private _threadService: ThreadService
  ) {
    this._route.params.subscribe((params) => {
      const bookingId = params.bookingId;
      this._getBooking(bookingId);
    });
  }

  private _getBooking(id: string) {
    this._bookingService.getById(id).subscribe((res) => {
      this.booking = res[0];
      this.userId = this._accountService.currentUser?.name;
      if (this.userId) {
        this.isCustomer = this.booking.customerId === this.userId;
      }
      this._getCancelPolicy(this.booking);
    });
  }

  checkCancelValue(val: boolean) {
    if (val) {
      this.requestPayment.disable();
      this.requestRefund.disable();
      this.notes.disable();
    } else {
      this.requestPayment.enable();
      this.requestRefund.enable();
      this.notes.enable();
    }
  }

  private _getCancelPolicy(booking: Booking) {
    const listingId = booking.listingId;
    const crewId = booking.crewId;
    if (listingId) {
      this._listingService.getListing(listingId).subscribe((listingRes) => {
        const cancellationPolicy = listingRes.result?.cancellation;
        if (cancellationPolicy) {
          this.cancelPolicy = getCancelPolicy(cancellationPolicy)?.value;
        }
      });
    }
    if (crewId) {
      this._crewService.getCrew(crewId).subscribe((crewRes) => {
        const cancellationPolicy = crewRes.result?.cancellation;
        if (cancellationPolicy) {
          this.cancelPolicy = getCancelPolicy(cancellationPolicy)?.value;
        }
      });
    }
  }

  submit() {
    this.range.markAllAsTouched();
    this.requestPayment.markAsTouched();
    this.requestRefund.markAsTouched();

    if (
      this.userId &&
      this.booking.id &&
      ((this.cancel && this.reason.value) ||
        (this.changeDate && this.range.valid) ||
        this.requestPayment.value ||
        this.requestRefund.value)
    ) {
      const data = {
        bookingId: this.booking.id,
        cancel: this.cancel,
        requestPayment: Number(this.requestPayment.value),
        requestRefund: Number(this.requestRefund.value),
        reason: this.reason.value || this.notes.value,
        state: EditBookingState.Initiated,
        ...(this.changeDate &&
          this.range.valid && {
            startDate: this.range.value.start.format('ddd, MMM DD, YYYY'),
            endDate: this.range.value.end.format('ddd, MMM DD, YYYY'),
          }),
        initiatorId: this.userId,
        created: Date.now().toString(),
        lastModified: Date.now().toString(),
      };
      this._bookingService.createEditBooking(data).subscribe((res) => {
        if (res.isSuccess) {
          const messageText =
            'AUTOMATED MESSAGE - Some changes to the booking have been initiated';
          this._sendAutoMsg(this.booking, messageText).then((messageRes) => {
            if (messageRes && messageRes.isSuccess) {
              this._notificationService.success('Your edit has been initiated');
              this._location.back();
            } else {
              this._notificationService.error(
                'Your edit was not successfully initiated'
              );
            }
          });
        } else {
          this._notificationService.error(
            'Your edit was not successfully initiated'
          );
        }
      });
    }
  }

  private _sendAutoMsg(
    booking: Booking,
    messageText: string
  ): Promise<GenericResponse | null> {
    const senderId = this._accountService.currentUser?.name;
    if (!senderId) {
      return new Promise((resolve) => resolve(null));
    }
    const receiverId =
      booking.ownerId === senderId ? booking.customerId : booking.ownerId;
    const timestamp = Date.now().toString();
    const data = {
      senderId,
      receiverId,
      timestamp,
      messageText,
    };
    if (booking.threadId) {
      return this._threadService.createMessage(booking.threadId, data);
    }
    return new Promise((resolve) => resolve(null));
  }
}
