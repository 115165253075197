import { AccountService } from 'src/app/services/account/account.service';
import { Component, OnInit } from '@angular/core';
import { FavoritesService } from 'src/app/services/favorites/favorites.service';
import { Favorite } from 'src/app/utils/types';
import { UserType } from 'src/app/utils/enums';

@Component({
  selector: 'app-saved',
  templateUrl: './wishlist.component.html',
})
export class WishlistComponent implements OnInit {
  favoriteCrews!: Favorite[];
  favoriteListings!: Favorite[];
  constructor(
    private _favoritesService: FavoritesService,
    private _accountService: AccountService
  ) {}

  ngOnInit(): void {
    const userId = this._accountService.currentUser?.name;
    if (userId) {
      this._favoritesService.getFavorites(userId).subscribe((res) => {
        this.favoriteCrews = res.result.filter(
          (fav) => fav.favoritestype === UserType.Crew
        );
        this.favoriteListings = res.result.filter(
          (fav) => fav.favoritestype === UserType.Listing
        );
      });
    }
  }
}
