<div class="container app-pages comp-container">
  <div class="row">
    <div class="col-lg-3">
      <mdb-card class="lg-dash-controls">
        <app-dashboard-controls></app-dashboard-controls>
      </mdb-card>
      <mat-expansion-panel class="dash-controls" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span>Dashboard Controls</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-dashboard-controls></app-dashboard-controls>
      </mat-expansion-panel>
    </div>
    <div class="col-lg-9 dash-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>