<h2 mat-dialog-title class="text-center">Welcome back!</h2>
<mat-dialog-content class="mat-typography login-form">
  <div class="row">
    <button mdbBtn color="primary" class="waves-light" mdbWavesEffect>
      <mdb-icon fab icon="google" class="mr-1"></mdb-icon>Log in with Google
    </button>
    <button mdbBtn color="indigo" class="waves-light" mdbWavesEffect (click)="loginWithFB()">
      <mdb-icon fab icon="facebook" class="mr-1"></mdb-icon>Log in with Facebook
    </button>
  </div>
  <div class=" row">
    <p class="text-center subform-text"></p>
    <p class="divider-word">
      <span class="subform-text">or</span>
    </p>
  </div>
  <form (submit)="login()" [formGroup]="loginForm">
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Email or Username</mat-label>
        <input type="text" matInput formControlName="email" placeholder="Email or Username" autocomplete="email">
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input type="password" matInput formControlName="password" placeholder="Password"
          autocomplete="current-password">
      </mat-form-field>
    </div>
    <div class="row">
      <button type="submit" mdbBtn color="light-blue" class="m-0 waves-light light-blue darken-3" mdbWavesEffect>Log
        in</button>
      <a class="text-info mt-2 mx-auto" (click)="navigateToForgotPassword()">Forgot your password?</a>
    </div>
  </form>
</mat-dialog-content>