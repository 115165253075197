import { NotificationComponent } from '../../components/notification/notification.component';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private _notification: MatSnackBar) {}

  error(message: string, duration: number = 1) {
    return this._notify(message, 'error', duration);
  }

  success(message: string, duration: number = 1) {
    return this._notify(message, 'success', duration);
  }

  private _notify(
    message: string,
    type: 'error' | 'success',
    duration: number
  ) {
    return this._notification.openFromComponent(NotificationComponent, {
      data: {
        message,
        type,
      },
      duration: duration * 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }
}
