import { Event, Router, NavigationEnd } from '@angular/router';
import { AccountService } from './services/account/account.service';
import { LoadingService } from './services/loading/loading.service';
import { delay } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

const LANDING_URLS = ['/', '/login', '/signup', '/signup-success'];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loading = false;
  isLoggedIn = false;
  isLandingPage = false;

  constructor(
    private _loading: LoadingService,
    private _auth: AccountService,
    private _router: Router
  ) {
    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.isLoggedIn = this._auth.isAuthenticated();
        this.isLandingPage = LANDING_URLS.includes(this._router.url);
      }
    });
  }

  ngOnInit() {
    this.listenToLoading();
  }

  /**
   * Listen to the loadingSub property in the LoadingService class. This drives the
   * display of the loading spinner.
   */
  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }
}
