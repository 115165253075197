import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BlobDeleteResponse, BlobServiceClient } from '@azure/storage-blob';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';

const ACCOUNT_NAME = environment.azureFileUploadAccountName;
const CONTAINER_NAME = environment.azureFileUploadContainerName;
const nodeAPIUrl = environment.nodeApiUrl;

@Injectable({
  providedIn: 'root',
})
export class BlobStorageService {
  constructor(public http: HttpClient) {}

  public async uploadFile(content: File, blobName: string) {
    // Only allow uploads of files less than 5MB
    if (content.size > 5242880) {
      return {
        isSuccess: false,
        message: 'Too large! File has to be less than 1MB',
        imageUrl: null,
      };
    }
    const blobServiceClient = await this._createBlobService();
    const containerClient = blobServiceClient.getContainerClient(
      CONTAINER_NAME
    );
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const uploadBlobResponse = await blockBlobClient.upload(
      content,
      content.size
    );
    if (uploadBlobResponse._response.status !== 201) {
      return {
        isSuccess: false,
        message: 'Image upload failed',
        imageUrl: null,
      };
    }
    return {
      isSuccess: true,
      message: `Uploaded ${content.name} successfully`,
      response: uploadBlobResponse,
      imageUrl: `https://${ACCOUNT_NAME}.blob.core.windows.net/images/${blobName}?${performance.now()}`,
    };
  }

  public getFile(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob',
    });
  }

  public async deleteFile(
    blobName: string
  ): Promise<
    | {
        isSuccess: boolean;
        message: string;
        imageUrl: null;
      }
    | {
        isSuccess: boolean;
        message: string;
        response: BlobDeleteResponse;
      }
  > {
    const blobServiceClient = await this._createBlobService();
    const containerClient = blobServiceClient.getContainerClient(
      CONTAINER_NAME
    );
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const deleteBlobResponse = await blockBlobClient.delete();
    if (deleteBlobResponse._response.status !== 202) {
      return {
        isSuccess: false,
        message: 'Failed to delete image',
        imageUrl: null,
      };
    }
    return {
      isSuccess: true,
      message: `You have successfully deleted the image`,
      response: deleteBlobResponse,
    };
  }

  private _getSASToken(): Observable<{ key: string }> {
    return this.http.get<{ key: string }>(`${nodeAPIUrl}/azureKey`);
  }

  private async _createBlobService() {
    return this._getSASToken()
      .toPromise()
      .then(
        (token) =>
          new BlobServiceClient(
            `https://${ACCOUNT_NAME}.blob.core.windows.net?${token.key}`
          )
      );
  }
}
