import { filter, pairwise } from 'rxjs/operators';
import { Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { UserProfile } from 'src/app/utils/types';
import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account/account.service';

@Component({
  selector: 'app-verifications',
  templateUrl: './verifications.component.html',
})
export class VerificationsComponent implements OnInit {
  user: UserProfile | null = null;
  constructor(private accountService: AccountService, private _router: Router) {
    this._router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        const prevUrl = events[0].urlAfterRedirects;
        const currUrl = events[1].urlAfterRedirects;
        if (
          [
            '/dashboard/verification/phone-number',
            '/dashboard/verification/govt-id',
          ].includes(prevUrl) &&
          currUrl === '/dashboard/verification'
        ) {
          this._setUser(true);
        } else {
          this._setUser();
        }
      });
  }

  private _setUser(refetch = false): void {
    this.accountService.getProfile(refetch).subscribe((res) => {
      this.user = res.user;
    });
  }

  ngOnInit(): void {
    this._setUser();
    // this._router.events
    //   .pipe(filter((event) => event instanceof NavigationEnd))
    //   .subscribe(() => {
    //     this._setUser();
    //   });
  }
}
