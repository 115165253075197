<div class="container">
  <div class="my-3">
    <button type="button" class="close" aria-label="Close" mat-dialog-close>
      <span class="red-text" aria-hidden="true">×</span>
    </button>
    <div>
      <p class="h5">
        Review Request
      </p>
    </div>
  </div>
  <p class="h6 text-muted">Please review these changes, contact me if you have questions</p>
  <div *ngIf="editBooking && booking" class="d-flex flex-column">
    <div class="m-3">
      <ng-container *ngIf="editBooking.cancel">
        <p class="h6">There has been a request to cancel this booking.</p>
      </ng-container>
      <p class="h6">Reason</p>
      <p class="h6 text-muted">{{editBooking.reason}}</p>
      <ng-container *ngIf="!editBooking.cancel">
        <div class="d-flex flex-row justify-content-between">
          <div class="d-flex flex-column align-content-start" *ngIf="editBooking.startDate">
            <p class="h6">Suggested Start Date</p>
            <p class="h6 text-muted">{{editBooking.startDate}}</p>
          </div>
          <div class="d-flex flex-column align-content-start">
            <p class="h6">Current Start Date</p>
            <p class="h6 text-muted">{{booking.startDate}}</p>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-between">
          <div class="d-flex flex-column align-content-start" *ngIf="editBooking.endDate">
            <p class="h6">Suggested End Date</p>
            <p class="h6 text-muted">{{editBooking.endDate}}</p>
          </div>
          <div class="d-flex flex-column align-content-start">
            <p class="h6">Current End Date</p>
            <p class="h6 text-muted">{{booking.endDate}}</p>
          </div>
        </div>
      </ng-container>
    </div>
    <table *ngIf="!editBooking.cancel" class="w-100 my-3" mdbTable [small]="true">
      <tbody>
        <ng-container *ngIf="displayedPaymentRequested !== '0.00'">
          <tr mdbTableCol>
            <td>Payment Requested</td>
            <td>$ {{displayedPaymentRequested}}</td>
          </tr>
          <tr mdbTableCol>
            <td>Taxes</td>
            <td>$ {{taxes}}</td>
          </tr>
          <tr mdbTableCol>
            <td>Total</td>
            <td>$ {{displayedTotal}}</td>
          </tr>
        </ng-container>
        <tr mdbTableCol *ngIf="displayedRefundRequested !== '0.00'">
          <td>Refund Requested</td>
          <td>$ {{displayedRefundRequested}}</td>
        </tr>
      </tbody>
    </table>
    <ng-container *ngIf="!paymentNeeded">
      <div class="m-2 d-flex justify-content-between">
        <button class="light-blue darken-3" type="button" color="light-blue" mdbBtn mdbWavesEffect (click)="accept()">
          Accept
        </button>
        <button class="amber darken-3" type="button" color="amber" mdbBtn mdbWavesEffect (click)="decline()">
          Decline
        </button>
      </div>
      <div class="mt-3">
        <p>By accepting, you are agreeing to Camlyst's
          <a class="text-primary">Terms and conditions</a> and
          <a class="text-primary">user's policy</a>
        </p>
      </div>
    </ng-container>
    <app-payment-intent *ngIf="booking && booking.id && paymentNeeded && editBooking.requestPayment" [amount]='total'
      [booking]='booking' (paymentResult)="onPaymentResult($event)">
    </app-payment-intent>
  </div>
</div>