import { CanActivate, Router } from '@angular/router';
import { AccountService } from './../account/account.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(public accountService: AccountService, public router: Router) {}

  canActivate(): boolean {
    if (!this.accountService.isAuthenticated()) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
