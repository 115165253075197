import { GenericResponse, Verification } from './../../utils/types';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

const baseUrl = `${environment.apiUrl}/Verification`;

@Injectable({
  providedIn: 'root',
})
export class VerificationService {
  constructor(public http: HttpClient) {}

  public create(data: Verification) {
    return this.http.post<GenericResponse>(`${baseUrl}/Create`, data, {
      observe: 'response',
    });
  }
}
