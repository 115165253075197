import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import {
  BookingAccount,
  BookingAccountEntry,
  GenericResponse,
} from 'src/app/utils/types';

const baseAccountUrl = `${environment.apiUrl}/Account`;
const baseAccountEntryUrl = `${environment.apiUrl}/AccountEntries`;

@Injectable({
  providedIn: 'root',
})
export class BookingAccountService {
  constructor(public http: HttpClient) {}

  public getAccount(bookingId: string): Observable<BookingAccount[]> {
    let params = new HttpParams();
    params = params.append('bookingId', bookingId);
    return this.http.get<BookingAccount[]>(baseAccountUrl, {
      params,
    });
  }

  public create(data: BookingAccount) {
    return this.http.post<GenericResponse>(baseAccountUrl, data);
  }

  public getAccountEntry(accountId: string): Observable<BookingAccountEntry[]> {
    let params = new HttpParams();
    params = params.append('accountId', accountId);
    return this.http.get<BookingAccountEntry[]>(baseAccountEntryUrl, {
      params,
    });
  }

  public createAccountEntry(data: BookingAccountEntry) {
    return this.http.post<GenericResponse>(baseAccountEntryUrl, data);
  }
}
