import { Router } from '@angular/router';
import { NotificationService } from './../../services/notification/notification.service';
import { AccountService } from '../../services/account/account.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Login } from 'src/app/utils/types';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,
    private accountService: AccountService,
    private notificationService: NotificationService,
    private router: Router
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });
  }

  login(): void {
    this.loginForm.markAllAsTouched();
    if (this.loginForm.valid) {
      const data: Login = {
        authenticateDto: this.loginForm.value,
      };
      this.accountService.login(data).subscribe((res) => {
        if (res) {
          this.notificationService.success(res.message);
          this.accountService.getProfile(true).subscribe((profile) => {
            if (profile.user.profilePicture) {
              this.accountService.setProfilePic(profile.user.profilePicture);
            } else {
              this.accountService.removeProfilePic();
            }
          });
          this.close();
        }
      });
    }
  }

  loginWithFB(): void {
    this.accountService.loginFacebook().subscribe((res) => {
      if (res) {
        // this.router.navigate([`dashboard/${res.id}`]);
        this.close();
      }
    });
  }

  navigateToForgotPassword(): void {
    this.close();
    this.router.navigate(['forgot-password']);
  }

  close() {
    this.dialogRef.close();
  }
}
