import { AccountService } from 'src/app/services/account/account.service';
import { Component, Input, OnInit } from '@angular/core';
import { ThreadMessage } from 'src/app/utils/types';
import * as moment from 'moment';

@Component({
  selector: 'app-message-block',
  templateUrl: './message-block.component.html',
  styleUrls: ['./message-block.component.scss'],
})
export class MessageBlockComponent implements OnInit {
  @Input() message!: ThreadMessage;
  isSender = false;
  messageText!: string;
  dateTime!: string;
  constructor(private _accountService: AccountService) {}

  ngOnInit(): void {
    const userId = this._accountService.currentUser?.name;
    this.isSender = userId === this.message.sender.id ? true : false;
    this.messageText = this.message.messageText;
    this.dateTime = moment(Number(this.message.timestamp)).format(
      'ddd, MMM D, YYYY h:mm A'
    );
  }
}
