import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  panelOpenState = false;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.router.navigate(['profile'], { relativeTo: this.route });
  }
}
