import { NotificationService } from 'src/app/services/notification/notification.service';
import { startWith, map } from 'rxjs/operators';
import { LocationService } from 'src/app/services/location/location.service';
import { AutocompleteValidators } from 'src/app/utils/autocomplete.validators';
import { PostalCodePattern } from 'src/app/utils/validator-patterns';
import { Payout, Place, UserProfile } from 'src/app/utils/types';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { BankAccountValidators } from 'src/app/utils/bank-account.validators';
import { AccountService } from 'src/app/services/account/account.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard-payout',
  templateUrl: './dashboard-payout.component.html',
  styleUrls: ['./dashboard-payout.component.scss'],
})
export class DashboardPayoutComponent implements OnInit {
  user!: UserProfile;
  bankAccountForm!: FormGroup;
  bankNameError!: string;
  cities!: Place[];
  filteredCities!: Observable<Place[]>;
  provinceOptions!: Place[];
  showCity = false;
  hasPayout = false;
  payoutDetails!: Payout;
  institutionName!: string;
  accountNumberSubstr!: string;
  isEdit = false;
  loading = true;

  constructor(
    private _accountService: AccountService,
    private _paymentService: PaymentService,
    private _locationService: LocationService,
    private _notificationService: NotificationService
  ) {
    this._accountService.getProfile().subscribe(async (res) => {
      this.user = res.user;
      await this._getPayoutDetails(this.user.id);
      this.loading = false;
    });
    this.bankAccountForm = new FormGroup(
      {
        bankName: new FormControl(
          {
            value: null,
            disabled: true,
          },
          Validators.required
        ),
        institutionNumber: new FormControl(null, Validators.required),
        transitNumber: new FormControl(null, Validators.required),
        bankAccountNumber: new FormControl(null, Validators.required),
        verifyAccountNumber: new FormControl(null, Validators.required),
        accountHolderName: new FormControl(null, Validators.required),
        address: new FormControl(null, Validators.required),
        postalCode: new FormControl(
          null,
          Validators.compose([
            Validators.required,
            Validators.pattern(PostalCodePattern),
          ])
        ),
        city: new FormControl(
          null,
          Validators.compose([
            Validators.required,
            AutocompleteValidators.valueSelected(
              this._cityValidatorFn.bind(this)
            ),
          ])
        ),
        province: new FormControl(null, Validators.required),
      },
      {
        validators: [BankAccountValidators.shouldMatch],
      }
    );
  }

  ngOnInit(): void {
    this._locationService.provinces().subscribe((res) => {
      this.provinceOptions = res.result;
    });

    this.filteredCities = this.bankAccountForm.controls.city.valueChanges.pipe(
      startWith(''),
      map((value: string) =>
        value.length >= 1 ? this._filterCities(value) : []
      )
    );
  }

  private async _getPayoutDetails(userId: string) {
    const payoutDetails = await this._paymentService
      .getPayout(userId)
      .toPromise();
    if (payoutDetails && payoutDetails.length > 0) {
      const payoutDetail = payoutDetails[0];
      this.hasPayout = true;
      this.isEdit = false;
      this.payoutDetails = payoutDetail;
      const { bankName } = await this._paymentService
        .getBankName(payoutDetail.institutionNumber)
        .toPromise();
      this.institutionName = bankName;
      this.accountNumberSubstr = payoutDetail.bankAccountNumber.substr(
        payoutDetail.bankAccountNumber.length - 4
      );
    } else {
      this.hasPayout = false;
    }
  }

  private _filterCities(value: string): Place[] {
    const filterValue = value.toLowerCase();
    return this.cities.filter((option: Place) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _cityValidatorFn(): Place[] {
    return this.cities;
  }

  onProvinceChange(): void {
    this.bankAccountForm.controls.city.setValue('');
    const province = this.bankAccountForm.controls.province.value;
    this.showCity = province ? true : false;
    this._locationService.cities(province).subscribe((res) => {
      this.cities = res.result;
    });
  }

  async findBank(evt: Event) {
    const institutionNumber = this.bankAccountForm.get('institutionNumber')
      ?.value;
    if (institutionNumber && institutionNumber.length === 3) {
      try {
        const { bankName } = await this._paymentService
          .getBankName(institutionNumber)
          .toPromise();
        this.bankAccountForm.get('bankName')?.patchValue(bankName);
      } catch (err) {
        this.bankAccountForm.get('bankName')?.patchValue(null);
        this.bankNameError = err;
      }
    } else {
      this.bankAccountForm.get('bankName')?.patchValue(null);
    }
  }

  submit() {
    this.bankAccountForm.markAllAsTouched();
    if (this.bankAccountForm.valid) {
      const data: Payout = {
        userId: this.user.id,
        bankName: this.bankAccountForm.controls.bankName.value,
        ...this.bankAccountForm.value,
      };
      // New payout
      if (!this.hasPayout) {
        this._paymentService.createPayout(data).subscribe(async (res) => {
          if (res.id) {
            this.bankAccountForm.reset();
            this._notificationService.success('Bank details succesfully saved');
            await this._getPayoutDetails(this.user.id);
          } else {
            this._notificationService.error('Error saving bank details');
          }
        });
      }
      // Update existing payout
      if (this.hasPayout && this.payoutDetails.id) {
        this._paymentService
          .updatePayout({ id: this.payoutDetails.id, ...data })
          .subscribe(async (res) => {
            if (res.ok) {
              this.bankAccountForm.reset();
              this._notificationService.success(
                'Bank details succesfully updated'
              );
              await this._getPayoutDetails(this.user.id);
            } else {
              this._notificationService.error('Failed to update bank details');
            }
          });
      }
    }
  }

  update() {
    this.isEdit = true;
  }

  cancelEdit() {
    this.isEdit = false;
  }
}
