// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://kreactivecityapi.azurewebsites.net/api/v1',
  nodeApiUrl: 'https://camlyst-node.vercel.app/api/v1',
  facebookAppId: '1917973945046112',
  STRIPE_PUBLISHABLE_KEY:
    'pk_test_51KBnWgFsN9g7N1dLHynfDvml4IfE5lYv0PNvoK1eM743iiXX3DBYi7LcYSp4lxcPWsvMpvgpOGvEinkENSnektYg00Xs2Cww9x',
  azureFileUploadAccountName: 'camlystsa',
  azureFileUploadContainerName: 'images',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
