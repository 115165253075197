<div class="comp-container">
  <div class="view intro-2">
    <div class="full-bg-img">
      <div class="mask rgba-black-light flex-center">
        <div class="container text-center white-text wow fadeInUp">
          <p class="h2">Access thousands of film equipment, and crew for your next production</p>
          <app-search-form></app-search-form>
        </div>
      </div>
    </div>
  </div>
  <app-how></app-how>
  <div class="container rental-cat">
    <div class="title">Save cost by renting from verified filmmakers and producers on camlyst</div>
    <app-rental-category-grid></app-rental-category-grid>
  </div>
  <app-list-item-banner></app-list-item-banner>
  <app-why-kreative></app-why-kreative>
</div>
<router-outlet></router-outlet>