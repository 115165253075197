<div class="container">
  <form (submit)="search()" class="form-row search-form">
    <div class="col-lg-5">
      <input mdbInput type="text" class="form-control m-1 keyword" placeholder="What are you looking for?"
        [formControl]="keyword" [matAutocomplete]="what">
      <mat-autocomplete #what="matAutocomplete">
        <mat-option *ngFor="let option of filteredKeywords | async" [value]="option.name">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </div>
    <div class="col-lg-4">
      <input type="text" class="form-control m-1" placeholder="Where?" [formControl]="city" [matAutocomplete]="where">
      <mat-autocomplete #where="matAutocomplete">
        <mat-option *ngFor="let option of filteredCities | async" [value]="option.name">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </div>
    <div class="col-lg-3">
      <button mdbBtn type="submit" [block]="true" mdbWavesEffect class="m-1">Search</button>
    </div>
  </form>
</div>