<div class="m-4">
  <h2 class="m-3">View Booking</h2>
  <div class="m-4">
    <ng-container *ngIf="booking">
      <div class="row">
        <div class="col-lg-9 col-md-8">
          <mdb-badge class="mb-3" [pill]="true" [color]="status.color">
            <span>{{status.text}}</span>
          </mdb-badge>
          <div *ngIf="!isCanceled">
            <a [routerLink]="['/bookings/edit', booking.id]" mdbWavesEffect>
              <p class="h6">
                <mdb-icon class="mr-1" fas icon="pen"></mdb-icon>Edit Booking
              </p>
            </a>
          </div>
          <div>
            <p class="h6">Title</p>
            <p class="text-muted">{{booking.title}}</p>
          </div>
          <div>
            <p class="h6">Start Date</p>
            <p class="text-muted">{{booking.startDate}}</p>
          </div>
          <div>
            <p class="h6">End Date</p>
            <p class="text-muted">{{booking.endDate}}</p>
          </div>
          <div>
            <p class="h6">Notes</p>
            <p class="text-muted">{{booking.notes}}</p>
          </div>

          <table class="w-50" mdbTable [small]="true">
            <tbody>
              <tr mdbTableCol>
                <td>
                  <p class="h6">Initial Amount Agreed</p>
                </td>
                <td>${{amount}}</td>
              </tr>
              <ng-container *ngIf="isOwner">
                <tr mdbTableCol>
                  <td>Service fee</td>
                  <td>-${{serviceFee}}</td>
                </tr>
                <tr mdbTableCol>
                  <td>
                    <p class="h6">Payout</p>
                  </td>
                  <td>${{payout}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>

        </div>
        <div class="col-lg-3 col-md-4 order-lg-last order-md-last order-first order-sm-first">
          <div *ngIf="profile" class="py-2 d-flex flex-column align-items-center">
            <ng-container *ngIf="booking.type === 'crew'">
              <img class="img-fluid rounded-circle w-100" [src]="profile.picture" alt="user image" />
            </ng-container>
            <ng-container *ngIf="booking.type === 'listing'">
              <img *ngIf="booking.type === 'listing'" class="img-fluid w-100" [src]="profile.picture"
                alt="user image" />
            </ng-container>
            <div class="row">
              <ng-container *ngIf="booking.type === 'crew'">
                <a [routerLink]="['/crew', booking.crewId]">
                  <p class="m-2 h5">{{profile.name}}</p>
                </a>
              </ng-container>
              <ng-container *ngIf="booking.type === 'listing'">
                <a [routerLink]="['/listings', 'view', booking.listingId]">
                  <p class="m-2 h5">{{profile.name}}</p>
                </a>
              </ng-container>
              <a [routerLink]="['/inbox', booking.threadId]" mdbTooltip="Go to message" placement="bottom">
                <p class="m-2 h5">
                  <mdb-icon fas icon="envelope"></mdb-icon>
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="accountEntries && accountEntries.length > 0">
        <div class="my-2 py-2 border-top border-dark">
          <p class="h5">Transactions</p>
          <table class="w-100" mdbTable [responsive]="true">
            <thead>
              <tr>
                <th scope="col">Amount</th>
                <ng-container *ngIf="isOwner">
                  <th scope="col">Service fee</th>
                  <th scope="col">Payout / Refund amount</th>
                </ng-container>
                <th scope="col">Type</th>
                <th scope="col">Status</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr mdbTableCol *ngFor="let entry of accountEntries">
                <td>{{ entry.amount }}</td>
                <ng-container *ngIf="isOwner">
                  <td>{{entry.serviceFee}}</td>
                  <td>{{entry.payoutAmount}}</td>
                </ng-container>
                <td>{{ entry.type }}</td>
                <td>
                  <span [class.text-success]="entry.status === 'Complete'"
                    [class.text-info]="entry.status === 'Pending'">{{
                    entry.status }}</span>
                </td>
                <td>{{ entry.created }}</td>
              </tr>
              <ng-container *ngIf="isOwner">
                <tr>
                  <td>
                    <p class="h6">Final Payout Amount</p>
                  </td>
                  <td>{{finalPayout}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>