import { Location } from '@angular/common';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component } from '@angular/core';
import { BookingService } from 'src/app/services/booking/booking.service';
import { ThreadService } from 'src/app/services/thread/thread.service';
import { BookingStatus, ThreadStatus } from 'src/app/utils/enums';
import { ListingsService } from 'src/app/services/listings/listings.service';
import { AccountService } from 'src/app/services/account/account.service';
import { GenericResponse } from 'src/app/utils/types';

@Component({
  selector: 'app-booking-invite-form',
  templateUrl: './booking-invite-form.component.html',
})
export class BookingInviteFormComponent {
  type!: 'listing' | 'crew';
  listingId!: string;
  crewId!: string;
  ownerId!: string;
  customerId!: string;
  threadId!: string;

  range = new FormGroup({
    start: new FormControl(null, Validators.required),
    end: new FormControl(null, Validators.required),
  });
  totalAmountAgreed = new FormControl(null, Validators.required);
  notes = new FormControl();

  today: Date = new Date();
  agreeTerms = false;
  showCosting = false;
  rate?: number;
  days = 0;
  rentalAmount = 0;
  constructor(
    private _route: ActivatedRoute,
    private _bookingService: BookingService,
    private _threadService: ThreadService,
    private _notificationService: NotificationService,
    private _listingService: ListingsService,
    private _location: Location,
    private _accountService: AccountService
  ) {
    this._route.queryParams.subscribe((params) => {
      this.type = params.type;
      this.threadId = params.threadId;
      this.ownerId = params.ownerId;
      this.customerId = params.customerId;
      if (params.type === 'crew') {
        this.crewId = params.itemId;
      }
      if (params.type === 'listing') {
        this.listingId = params.itemId;
        this._getListingRate(this.listingId).then((amount) => {
          this.rate = amount;
        });
      }
    });
  }

  private async _getListingRate(listingId: string) {
    const res = await this._listingService.getListing(listingId).toPromise();
    return res.result?.dailyRentalPrice;
  }

  onDateChange() {
    if (this.type === 'crew') {
      return;
    }
    this.showCosting = true;
    this._calcBill();
  }

  private _calcBill() {
    this._calcRentalDays();
    this._calcRentalAmount();
  }

  private _calcRentalDays() {
    if (this.range.value.start && this.range.value.end) {
      const startDate = this.range.value.start;
      const endDate = this.range.value.end;
      const days = endDate.diff(startDate, 'days');
      this.days = days;
    }
  }
  private _calcRentalAmount() {
    if (this.rate) {
      this.rentalAmount = this._roundNumber(this.days * this.rate);
      this.totalAmountAgreed.setValue(this.rentalAmount);
    }
  }

  private _roundNumber(num: number): number {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  submit() {
    this.range.markAllAsTouched();
    this.totalAmountAgreed.markAsTouched();
    if (this.range.valid && this.totalAmountAgreed.valid) {
      const data = {
        startDate: this.range.value.start.format('ddd, MMM DD, YYYY'),
        endDate: this.range.value.end.format('ddd, MMM DD, YYYY'),
        totalAmountAgreed: Number(this.totalAmountAgreed.value),
        notes: this.notes.value as string,
        type: this.type,
        ...(this.type === 'crew'
          ? { crewId: this.crewId }
          : { listingId: this.listingId }),
        customerId: this.customerId,
        ownerId: this.ownerId,
        threadId: this.threadId,
        status: BookingStatus.New,
        created: Date.now().toString(),
        lastModified: Date.now().toString(),
      };
      this._bookingService.create(data).subscribe((bookingRes) => {
        if (bookingRes.isSuccess) {
          this._threadService
            .update(this.threadId, { status: ThreadStatus.Pending })
            .subscribe((threadRes) => {
              if (threadRes.result.isSuccess) {
                this.range.reset();
                this.totalAmountAgreed.reset();
                this.notes.reset();
                this._sendAutoMsg(this.threadId).then((messageRes) => {
                  if (messageRes && messageRes.isSuccess) {
                    this._notificationService.success(bookingRes.message, 3);
                    this._location.back();
                  } else {
                    this._notificationService.error('Something went wrong');
                  }
                });
              }
            });
        }
      });
    }
  }

  private _sendAutoMsg(threadId: string): Promise<GenericResponse | null> {
    const messageText =
      'AUTOMATED MESSAGE - A booking invitation has been sent';
    const senderId = this._accountService.currentUser?.name;
    if (!senderId) {
      return new Promise((resolve) => resolve(null));
    }
    const receiverId =
      this.ownerId === senderId ? this.customerId : this.ownerId;
    const timestamp = Date.now().toString();
    const data = {
      senderId,
      receiverId,
      timestamp,
      messageText,
    };
    return this._threadService.createMessage(threadId, data);
  }
}
