<mdb-card>
  <mdb-card-header class="d-flex justify-content-between">
    <span class="text-left">Verify your ID</span>
    <a [routerLink]="['/dashboard/verification']" class="text-right">Close</a>
  </mdb-card-header>
  <mdb-card-body>
    <div class="row m-2">
      <ul class="list-group list-group-flush">
        <li class="list-group-item">To help keep Camlyst the most trusted peer rental community, we need you to
          verify your ID.</li>
        <li class="list-group-item">After submitting your verification request. Our security team will review and
          respond within 1 (one) hour.</li>
        <li class="list-group-item">Got a question, <a>learn more</a> on how ID verification works. Our support
          team are ready to help.</li>
        <li class="list-group-item">Only Canadian government issued documents will be accepted. School ID and Health
          Cards are not going to be accepted.</li>
      </ul>
      <div class="m-2">
        <p class="select-id-text">Select ID type</p>
        <div class="d-flex flex-wrap">
          <div class="custom-control custom-radio mx-3">
            <input type="radio" class="custom-control-input" [formControl]="idType" id="passport" value="passport"
              name="idType" mdbInput>
            <label class="custom-control-label" for="passport">
              <p>Passport</p>
            </label>
          </div>
          <div class="custom-control custom-radio mx-3">
            <input type="radio" class="custom-control-input" [formControl]="idType" id="pr-card" value="prcard"
              name="idType" mdbInput>
            <label class="custom-control-label" for="pr-card">
              <p>PR Card</p>
            </label>
          </div>
          <div class="custom-control custom-radio mx-3">
            <input type="radio" class="custom-control-input" [formControl]="idType" id="dl" value="dl" name="idType"
              mdbInput>
            <label class="custom-control-label" for="dl">
              <p>Driver's License</p>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-xs image-upload-form">
            <div class="d-flex flex-column align-items-center m-2">
              <p>Upload front side of your ID</p>
              <img [src]='idImage01' alt="thumbnail" class="img-fluid z-depth-1">
              <label class="m-2" for="file-01">Choose file</label>
              <input id="file-01" type="file" name="idImage01" (change)="onImageUpload($event)" />
            </div>
          </div>
          <div class="col-sm-6 col-xs image-upload-form">
            <div class="d-flex flex-column align-items-center m-2">
              <p>Upload back side of your ID</p>
              <img [src]='idImage02' alt="thumbnail" class="img-fluid z-depth-1">
              <label class="m-2" for="file-02">Choose file</label>
              <input id="file-02" type="file" name="idImage02" (change)="onImageUpload($event)" />
            </div>
          </div>
          <div class="col-sm-6 col-xs image-upload-form">
            <div class="d-flex flex-column align-items-center m-2">
              <p>Upload image of your face holding your ID (frontside)</p>
              <img [src]='idImage03' alt="thumbnail" class="img-fluid z-depth-1">
              <label class="m-2" for="file-03">Choose file</label>
              <input id="file-03" type="file" name="idImage03" (change)="onImageUpload($event)" />
            </div>
          </div>
        </div>
        <div class="d-flex flex-column align-items-center">
          <button type="button" mdbBtn (click)="submit()"
            [disabled]="!idImage01Uploaded || !idImage02Uploaded || !idImage03Uploaded">Submit</button>
        </div>
      </div>
    </div>
  </mdb-card-body>
</mdb-card>