import { AbstractControl, ValidationErrors } from '@angular/forms';

export class PasswordValidators {
  static shouldMatch(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');
    if (!password || !confirmPassword) {
      return null;
    }
    if (password.value !== confirmPassword.value) {
      return { shouldMatch: true };
    }
    return null;
  }

  static strong(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password');
    const regex = /^(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
    if (!password) {
      return null;
    }
    const valid = regex.test(password.value);
    if (!valid) {
      return { strong: true };
    }
    return null;
  }
}
