import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bookings-container',
  templateUrl: './bookings-container.component.html',
})
export class BookingsContainerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
