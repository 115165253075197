<mdb-card class="m-3">
  <div class="row align-items-center">
    <div class="col-lg-4 col-md-4">
      <img style="height: '200px'; float: left; object-fit: cover;" class="img-fluid w-100" [src]="displayImage"
        alt="Card image cap" />
    </div>
    <div class="col-lg-5 col-md-4 m-4 m-md-0">
      <p class="h3">{{listing.title}}</p>
      <p class="h5">{{listing.dailyRentalPrice}} / Day</p>
      <p class="h5">{{listing.bookings?.length || 0}} bookings</p>
    </div>
    <div class="col-lg-3 col-md-4 d-flex flex-column">
      <a mdbBtn [routerLink]="['delete', listing.id]" role="button" color="light" class="waves-light my-2 mx-4"
        mdbWavesEffect>Delete</a>
      <a mdbBtn [routerLink]="['preview', listing.id]" role="button" color="light" class="waves-light my-2 mx-4"
        mdbWavesEffect>Preview</a>
      <a mdbBtn [routerLink]="['edit', listing.id]" role="button" color="light-blue"
        class="waves-light light-blue darken-3 my-2 mx-4" mdbWavesEffect>Edit</a>
    </div>
  </div>
</mdb-card>