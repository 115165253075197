<h2 mat-dialog-title class="text-left">Delete Listing
  <button type="button" class="close" aria-label="Close" [mat-dialog-close]="dialogClose">
    <span aria-hidden="true">×</span>
  </button>
</h2>
<mat-dialog-content class="delete-listing-form">
  <form (submit)="submit()">
    <div class="row">
      <mat-form-field class="mx-3" appearance="outline">
        <mat-label>Reason</mat-label>
        <input type="text" matInput [formControl]="reason">
      </mat-form-field>
    </div>
    <button class="m-0" type="button" mdbBtn color="blue-grey" mdbWavesEffect (click)="closeFn()">Cancel</button>
    <button type="submit" mdbBtn color="danger" mdbWavesEffect>Delete</button>
  </form>
</mat-dialog-content>