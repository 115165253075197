import { ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account/account.service';
import { PageEvent } from '@angular/material/paginator';
import { startWith, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { CrewService } from 'src/app/services/crew/crew.service';
import { Crew, Place, Tag } from 'src/app/utils/types';
import { LocationService } from 'src/app/services/location/location.service';

@Component({
  selector: 'app-crews',
  templateUrl: './crews.component.html',
  styleUrls: ['./crews.component.scss'],
})
export class CrewsComponent implements OnInit {
  isLoggedIn = false;
  userRole?: string;
  tag: FormControl = new FormControl();
  city: FormControl = new FormControl();
  crews!: Crew[];
  tags!: Tag[];
  filteredTags!: Observable<Tag[]>;
  cities!: Place[];
  filteredCities!: Observable<Place[]>;
  totalCount!: number;
  pageSize!: number;
  currentPage!: number;
  totalPages!: number;
  constructor(
    private _crewService: CrewService,
    private _accountService: AccountService,
    private _locationService: LocationService,
    private _route: ActivatedRoute
  ) {
    this.isLoggedIn = this._accountService.isAuthenticated();
    if (this.isLoggedIn) {
      this._accountService.getProfile(true).subscribe((res) => {
        if (res.isSuccess) {
          this.userRole = res.user.role;
        }
      });
    }
    this._crewService
      .tags()
      .toPromise()
      .then((res) => {
        this.tags = res?.result?.data;
      })
      .then(() => {
        this._locationService
          .cities()
          .toPromise()
          .then((res) => {
            this.cities = res.result;
          })
          .then(() => {
            this._route.queryParams.subscribe((params) => {
              const { keyword, city } = params;
              if (keyword) {
                this.tag.patchValue(keyword);
              }
              if (city) {
                this.city.patchValue(city);
              }
              this.onSearch();
            });
          });
      });
  }

  ngOnInit(): void {
    this.filteredTags = this.tag.valueChanges.pipe(
      startWith(''),
      map((value: string) => (value.length >= 1 ? this._filterTags(value) : []))
    );
    this.filteredCities = this.city.valueChanges.pipe(
      startWith(''),
      map((value: string) =>
        value.length >= 1 ? this._filterCities(value) : []
      )
    );
  }

  onSearch(PageNumber?: number) {
    const tag = this.tag.value;
    const city = this.city.value;
    this._crewService.getCrews({ tag, city, PageNumber }).subscribe((res) => {
      this.crews = res.result.data;
      this.totalCount = res.result.totalCount;
      this.pageSize = res.result.pageSize;
      this.currentPage = res.result.currentPage;
      this.totalPages = res.result.totalPages;
    });
  }

  private _filterTags(value: string): Tag[] {
    const filterValue = value.toLowerCase();
    return this.tags.filter((option: Tag) =>
      option.description.toLowerCase().includes(filterValue)
    );
  }

  private _filterCities(value: string): Place[] {
    const filterValue = value.toLowerCase();
    return this.cities.filter((option: Place) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  onPageChange(evt: PageEvent) {
    const { pageIndex } = evt;
    this.onSearch(pageIndex + 1);
  }
}
