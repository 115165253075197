<!-- <div class="container">
  <mat-drawer-container [hasBackdrop]="true">
    <mat-drawer #drawer mode="over">
      <app-booking-form [crewData]="crewData" [drawer]="drawer"></app-booking-form>
    </mat-drawer>
    <mat-drawer-content>
      <button class="my-3 mx-0" type="button" [outline]="true" mdbBtn color="light-blue"
        (click)="drawer.open()">Book</button>
      <div class="my-1">
        <h3>In order for us to ensure protection please complete all bookings and transactions on this website.
          Otherwise
          Kreactive city will not be liable for any damages, loss or issues encountered.</h3>
        <p class="h6">Introduce yourself</p>
        <p class="h6">Provide information such as; type of production, location and length of booking. Include as much
          details as
          you can.</p>
        <div class="form-group">
          <textarea type="text" class="form-control md-textarea" rows="4" mdbInput
            [formControl]="messageText"></textarea>
        </div>
        <button class="my-3 mx-0 light-blue darken-3" type="button" mdbBtn color="light-blue"
          (click)="sendMessage()">Send
          Message</button>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div> -->

<div class="container">
  <div class="m-4">
    <h3>In order for us to ensure protection please complete all bookings and transactions on this website.
      Otherwise
      Kreactive city will not be liable for any damages, loss or issues encountered.</h3>
    <p class="h6">Introduce yourself</p>
    <p class="h6">Provide information such as; type of production, location and length of booking. Include as much
      details as
      you can.</p>
    <div class="form-group">
      <textarea type="text" class="form-control md-textarea" rows="4" mdbInput [formControl]="messageText"></textarea>
    </div>
    <button class="my-3 mx-0 light-blue darken-3" type="button" mdbBtn color="light-blue" (click)="sendMessage()">Send
      Message</button>
  </div>
</div>