<div class="container">
  <ng-container *ngIf="loading">
    <div class="my-3 d-flex justify-content-center">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </ng-container>

  <div *ngIf="elementsOptions?.clientSecret as clientSecret" [formGroup]="paymentElementForm">
    <div class="my-2">
      <label for="name">Full name</label>
      <input mdbInput type="text" class="form-control" id="name" formControlName="name" placeholder="Full Name">
      <mat-error
        *ngIf="(paymentElementForm.controls.name.dirty || paymentElementForm.controls.name.touched) && paymentElementForm.controls.name.errors?.required">
        * Field Required
      </mat-error>
    </div>

    <ng-container>
      <ngx-stripe-payment [clientSecret]="clientSecret">
      </ngx-stripe-payment>
    </ng-container>
    <ng-container *ngIf="!loading">
      <button class="mx-0 my-3 w-100 light-blue darken-3" type="submit" color="light-blue" mdbBtn mdbWavesEffect
        (click)="pay()">
        Pay CA${{amount}}
      </button>
    </ng-container>

  </div>
</div>